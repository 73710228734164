interface InfoTableProps {
  tableData: { key: string; icon: string; info: React.ReactNode; iconAlt: string }[]
}

const InfoTable = ({ tableData }: InfoTableProps) => {
  return (
    <div className="rounded-lg border border-[rgba(90,97,115,0.1)] bg-white">
      {tableData.map((row, index) => {
        const isFirst = index === 0
        const isLast = index === tableData.length - 1
        return (
          <div
            key={index}
            className="min-h-[74px] flex [&:not(:last-child)]:border-b border-[rgba(90,97,115,0.1)]"
          >
            <div
              className={`flex-[0_0_220px] p-5 flex items-center gap-2.5 bg-[rgba(90,97,115,0.03)] border-r border-[rgba(90,97,115,0.1)] 
                ${isFirst ? 'rounded-tl-lg' : ''} 
                ${isLast ? 'rounded-bl-lg' : ''}`}
            >
              <div className="w-[35px] h-[35px] flex justify-center items-center rounded-full border border-[rgba(90,97,115,0.1)] bg-white">
                <img src={row.icon} alt={row.iconAlt} />
              </div>
              <p className="flex-1 text-[#9399a3] text-[13px] font-semibold uppercase">{row.key}</p>
            </div>
            <div
              className={`flex-1 p-5 text-sm font-medium
                ${isFirst ? 'rounded-tr-lg' : ''}
                ${isLast ? 'rounded-br-lg' : ''}`}
            >
              {row.info}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default InfoTable
