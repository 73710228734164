import './App.css'
import GlobalStyle from '@src/Theme/globalStyles'
import ErrorBoundary from '@src/Components/ErrorBoundary'
import { Provider as RollbarProvider } from '@rollbar/react'
import { createTheme, MantineProvider } from '@mantine/core'
import '@mantine/core/styles.css'
import Router from '@src/Router'
import { useEffect, useMemo, useState } from 'react'
import { useAuthStore } from '@wove/api'
import { Provider } from 'urql'
import { getToken } from '@src/authStore'
import Spinner from '@src/Components/Spinner'
import { Toaster } from 'sonner'
import { createClient } from './urqlClient'

const theme = createTheme({
  /** Put your mantine theme override here */
})

const rollbarConfig = {
  accessToken: '9f86faa82b81420abbb56933c5abffc2',
  environment: process.env.NODE_ENV,
}

const App = () => {
  const [initialized, setInitialized] = useState(false)
  const authStore = useAuthStore()
  const { accessToken, apiUrl } = authStore

  const client = useMemo(() => createClient(accessToken || undefined, apiUrl || undefined), [accessToken, apiUrl])

  useEffect(() => {
    const token = getToken()
    if (token) {
      authStore.setAccessToken(token)
    }
    if (import.meta.env.VITE_API_BASE_URL) {
      authStore.setApiUrl(import.meta.env.VITE_API_BASE_URL)
    }
    setInitialized(true)
  }, [])

  if (!initialized) {
    return <Spinner />
  }

  return (
    <MantineProvider theme={theme}>
      <RollbarProvider config={rollbarConfig}>
        <ErrorBoundary>
          <Provider value={client}>
            <GlobalStyle />
            <Toaster />
            <Router />
          </Provider>
        </ErrorBoundary>
      </RollbarProvider>
    </MantineProvider>
  )
}

export default App
