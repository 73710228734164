import plusIcon from '@src/Pages/IntakeForms/IntakeFormPage/assets/plus.svg'
import LocationSelect from '@src/Pages/IntakeForms/IntakeFormPage/LocationSelect'
import trashRed from '@src/Pages/IntakeForms/IntakeFormPage/assets/trash-red.svg'
import { Location } from '@wove/api/types'
const PortSelect = ({
  fields,
  add,
  remove,
  onChange,
  title,
  error,
  allowedEmpty = false,
}: {
  fields: { port: Location | null }[]
  add: () => void
  remove: (index: number) => void
  onChange: (index: number, location: Location | null) => void
  title?: string
  error?: boolean
  allowedEmpty?: boolean
}) => {
  return (
    <div>
      {title && (
        <div className="mb-3 border-b border-[#e3e6e9] pb-3 flex items-center justify-between">
          <label className="font-semibold text-base leading-5 text-[#141414] mb-3.5 inline-block">{title}</label>
          <button className="gap-2.5 flex text-wove-blue items-center bg-[rgba(6,133,241,0.1)] border border-[rgba(6,133,241,0.1)] rounded-lg px-4 min-h-[42px]" onClick={add}>
            <img src={plusIcon} alt="blue plus" />
            Add Port
          </button>
        </div>
      )}
      {fields.length > 0 && (
        <div>
          {fields.map((field, index) => (
            <div key={index} className={`flex gap-2 ${index === fields.length - 1 ? 'mb-0' : 'mb-3'}`}>
              <div className="flex-1">
                <LocationSelect
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: !field.port && error ? '1px solid red' : undefined,
                    }),
                  }}
                  placeholder="Search for a port"
                  isPort
                  // selectRef={ref}
                  onChange={(location) => onChange(index, location as Location)}
                  value={field.port}
                  isClearable
                  // onBlur={onBlur}
                  // value={value}
                />
              </div>
              <button
                className="h-[50px] w-[50px] bg-[#eb5757] border border-[rgba(235,87,87,0.05)] flex justify-center items-center rounded-lg disabled:opacity-50"
                disabled={fields.length === 1 && !allowedEmpty}
                onClick={(e) => {
                  e.preventDefault()
                  remove(index)
                }}
              >
                <img src={trashRed} alt="red trash can" />
              </button>
            </div>
          ))}
        </div>
      )}
      {!title && (
        <div className="mt-3">
          <button className="gap-2.5 flex text-wove-blue items-center bg-[rgba(6,133,241,0.1)] border border-[rgba(6,133,241,0.1)] rounded-lg px-4 min-h-[42px]" onClick={add}>
            <img src={plusIcon} alt="blue plus" />
            Add Port
          </button>
        </div>
      )}
    </div>
  )
}

export default PortSelect
