import React from 'react'
import { ReactComponent as XIcon } from '@src/assets/icons/x-close-grey.svg'
import { Tag } from '@wove/api/types'

interface TagProps {
  tag: Tag
  onRemove?: () => void
  color?: string
  className?: string
}

const Tag = ({ tag, onRemove, color = 'rgba(20, 20, 20, 0.1)', className = '' }: TagProps) => {
  return (
    <div 
      className={`
        flex justify-center items-center gap-0.5 rounded-md
        ${onRemove ? 'px-2.5 py-1 pr-1' : 'p-1'}
        text-wove-black text-sm font-medium leading-5
        ${className}
      `}
      style={{ background: color }}
    >
      {tag.name}
      {onRemove && (
        <button
          type="button"
          className="bg-transparent border-0 cursor-pointer"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            onRemove()
          }}
        >
          <XIcon />
        </button>
      )}
    </div>
  )
}

export default Tag
