import { useState, useMemo } from 'react'
import { ShippingRate } from '@wove/api/types'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState,
} from '@tanstack/react-table'
import Price from '@src/Pages/IntakeForms/Components/Price'
import { Fragment } from 'react'
import RateBreakout from '@src/Pages/IntakeForms/RatesPage/RateBreakout'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'
import duplicateIcon from './assets/duplicate.svg'
import duplicateIconOrange from './assets/duplicate-orange.svg'
import DuplicateRates from '@src/Pages/IntakeForms/RatesPage/DuplicateRates'
import ButtonSecondary from '@src/Components/Buttons/ButtonSecondary'
import chevronDown from './assets/chevron-down.svg'
import chevronUp from './assets/chevron-up.svg'
import Tooltip from '@src/Components/Tooltip'
import Chevron from '@src/Components/Chevron'
import CarrierLogo from '@src/Pages/IntakeForms/RatesPage/CarrierLogo'
import sellRate from './assets/sell-rate.svg'

const columnHelper = createColumnHelper<ShippingRate>()

interface RateTableProps {
  rates: ShippingRate[]
  isDuplicate?: boolean
  onSellRate?: (index: number) => void
}

const RateTable = ({ rates, isDuplicate, onSellRate }: RateTableProps) => {
  const [expandedBreakouts, setExpandedBreakouts] = useState<{ [id: string]: boolean }>({})
  const [expandedDuplicates, setExpandedDuplicates] = useState<{ [id: string]: boolean }>({})
  const [sorting, setSorting] = useState<SortingState>([])
  const columns = useMemo(() => {
    return [
      columnHelper.accessor('allInRate', {
        cell: (info) => (
          <div className="flex justify-between">
            <Price price={info.getValue()} />

            <Tooltip overlay="Sell Rate" placement="top">
              <button
                className="w-[42px] h-[42px] rounded-lg border border-[rgba(90,97,115,0.1)] flex justify-center items-center hover:bg-[rgba(40,139,239,0.09)] hover:border-[rgba(40,139,239,0.09)]"
                onClick={() => onSellRate?.(info.row.index)}
              >
                <img src={sellRate} alt="bill sheet icon" />
              </button>
            </Tooltip>
          </div>
        ),
        header: () => <div>Buy Rate</div>,
        sortingFn: (a, b, columnId) => {
          const aValue: ShippingRate['allInRate'] = a.getValue(columnId)
          const bValue: ShippingRate['allInRate'] = b.getValue(columnId)
          return aValue.amount - bValue.amount
        },
      }),
      columnHelper.accessor((row) => row, {
        cell: (info) => {
          const rate = info.getValue()
          return (
            <div>
              {rate.origin.name} ({rate.origin.code})
              {rate.portOfLoading && (
                <div>
                  <div className="text-xs mt-1 text-[#717b8c]">Loading Port: </div>
                  <span className="rounded px-1 border border-[rgba(90,97,115,0.1)] bg-[rgba(90,97,115,0.03)] text-xs font-semibold text-[#141414]">
                    {rate.portOfLoading.name} ({rate.portOfLoading.code})
                  </span>
                </div>
              )}
            </div>
          )
        },
        sortingFn: (a, b, columnId) => {
          const aValue: ShippingRate = a.getValue(columnId)
          const bValue: ShippingRate = b.getValue(columnId)
          return `${aValue.origin.name} (${aValue.origin.code})`.localeCompare(
            `${bValue.origin.name} (${bValue.origin.code})`,
          )
        },
        header: () => <div>Origin</div>,
        id: 'origin',
      }),
      columnHelper.accessor((row) => row, {
        cell: (info) => {
          const rate = info.getValue()
          return (
            <div>
              {rate.destination.name} ({rate.destination.code})
              {rate.portOfDischarge && (
                <div>
                  <div className="text-xs mt-1 text-[#717b8c]">Discharge Port: </div>
                  <span className="rounded px-1 border border-[rgba(90,97,115,0.1)] bg-[rgba(90,97,115,0.03)] text-xs font-semibold text-[#141414]">
                    {rate.portOfDischarge.name} ({rate.portOfDischarge.code})
                  </span>
                </div>
              )}
            </div>
          )
        },
        sortingFn: (a, b, columnId) => {
          const aValue: ShippingRate = a.getValue(columnId)
          const bValue: ShippingRate = b.getValue(columnId)
          return `${aValue.destination.name} (${aValue.destination.code})`.localeCompare(
            `${bValue.destination.name} (${bValue.destination.code})`,
          )
        },
        header: () => <div>Destination</div>,
        id: 'destination',
      }),
      columnHelper.accessor('carrier', {
        cell: (info) => {
          const carrier = info.getValue()
          return (
            <div className="flex items-center gap-2.5">
              <CarrierLogo code={carrier.code} />
              {carrier.abbreviation} ({carrier.code})
            </div>
          )
        },
        sortingFn: (a, b, columnId) => {
          const aValue: ShippingRate['carrier'] = a.getValue(columnId)
          const bValue: ShippingRate['carrier'] = b.getValue(columnId)
          return `${aValue.abbreviation} (${aValue.code})`.localeCompare(
            `${bValue.abbreviation} (${bValue.code})`,
          )
        },
        header: () => <div>Carrier</div>,
        id: 'carrier',
      }),
      columnHelper.accessor((row) => row.commodityType, {
        cell: (info) => {
          const rate = info.getValue()
          return <div>{rate}</div>
        },
        header: () => <div>Commodity</div>,
        id: 'commodity',
      }),
      columnHelper.accessor((row) => row, {
        enableSorting: false,
        cell: (info) => {
          const rate = info.getValue()
          const dupeOpen = expandedDuplicates[info.row.id]
          const breakoutOpen = expandedBreakouts[info.row.id]
          const Button = breakoutOpen ? ButtonSecondary : ButtonPrimary
          return (
            <div className="flex gap-2.5 justify-end">
              {rate.duplicateRates?.length > 0 && (
                <ButtonPrimary
                  className={`${dupeOpen ? 'text-[#F2994A] bg-[rgba(242,153,74,0.1)]' : ''}`}
                  onClick={() =>
                    setExpandedDuplicates((prev) => ({
                      ...prev,
                      [info.row.id]: !prev[info.row.id],
                    }))
                  }
                >
                  {rate.duplicateRates.length}
                  <img
                    className="w-3.5 h-3.5"
                    src={dupeOpen ? duplicateIconOrange : duplicateIcon}
                    alt={dupeOpen ? 'duplicate icon orange' : 'duplicate icon white'}
                  />
                </ButtonPrimary>
              )}
              <Button
                className="whitespace-nowrap rate-breakout-print-hide"
                onClick={() =>
                  setExpandedBreakouts((prev) => ({
                    ...prev,
                    [info.row.id]: !prev[info.row.id],
                  }))
                }
              >
                Rate Breakout
                <img
                  src={breakoutOpen ? chevronUp : chevronDown}
                  alt={breakoutOpen ? 'blue chevron pointing up' : 'white chevron pointing down'}
                />
              </Button>
            </div>
          )
        },
        id: 'breakout',
        header: () => null,
      }),
    ]
  }, [expandedBreakouts])
  const table = useReactTable({
    data: rates,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <table
      className={`w-full table-fixed border-spacing-0 rounded-lg shadow-[0_0_0_1px_rgba(90,97,115,0.1)] font-semibold text-base
      [&_th:first-of-type]:rounded-tl-[10px] [&_th:last-of-type]:rounded-tr-[10px]
      [&_tr:last-of-type_td:first-of-type]:rounded-bl-[10px] [&_tr:last-of-type_td:last-of-type]:rounded-br-[10px]
      [&_th]:${
        isDuplicate ? 'bg-[rgba(0,0,0,0.10)]' : 'bg-[rgba(90,97,115,0.03)]'
      } [&_th]:text-[#717b8c] [&_th]:uppercase [&_th]:p-5 [&_th]:text-xs [&_th]:text-left
      [&_td]:${
        isDuplicate ? 'bg-[#FEF5ED]' : 'bg-white'
      } [&_td]:p-5 [&_td]:text-left [&_td:last-child]:w-[1%]
      [&_tr:not(:last-child)]:border-b-2 [&_tr:not(:last-child)]:border-[rgba(90,97,115,0.1)]`}
    >
      <colgroup>
        <col span={1} style={{ width: '15%' }} />
        <col span={1} style={{ width: '15%' }} />
        <col span={1} style={{ width: '15%' }} />
        <col span={1} style={{ width: '15%' }} />
        <col span={1} style={{ width: '15%' }} />
        <col span={1} style={{ width: '25%' }} />
      </colgroup>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder ? null : (
                  <div
                    className={`flex items-center gap-1.5 ${
                      header.column.getCanSort() ? 'cursor-pointer select-none' : ''
                    }`}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: <Chevron direction="up" color="rgb(147, 153, 163)" />,
                      desc: <Chevron direction="down" color="rgb(147, 153, 163)" />,
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          const breakoutOpen = expandedBreakouts[row.id]
          const duplicateOpen = expandedDuplicates[row.id]
          return (
            <Fragment key={row.id}>
              <tr className={breakoutOpen || duplicateOpen ? 'border-b-0' : ''}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
              {breakoutOpen && (
                <tr>
                  <RateBreakout rate={row.original} />
                </tr>
              )}
              {duplicateOpen && (
                <tr>
                  <DuplicateRates
                    rates={row.original.duplicateRates}
                    onClose={() => setExpandedDuplicates((prev) => ({ ...prev, [row.id]: false }))}
                  />
                </tr>
              )}
            </Fragment>
          )
        })}
      </tbody>
    </table>
  )
}

export default RateTable
