import { useState } from 'react'
import IconTitleSubtitle from '@src/Components/IconTitleSubtitle'
import { ReactComponent as UserBubble } from './assets/user-bubble.svg'
import HeaderControls from '@src/Pages/SettingsPage/ManageUsers/HeaderControls'
import { Space } from '@mantine/core'
import UsersTable from '@src/Pages/SettingsPage/ManageUsers/UsersTable'
import { useGetUsersQuery } from '@wove/api'

const ManageUsers = () => {
  const [usersData] = useGetUsersQuery()
  const [searchText, setSearchText] = useState('')
  return (
    <div className="p-10">
      <IconTitleSubtitle
        icon={<UserBubble />}
        title="Manage Workspace Users"
        subtitle="Review, Edit, Remove or Invite Members of the Workspace"
      />
      <Space h={30} />
      <HeaderControls searchText={searchText} setSearchText={setSearchText} />
      <Space h={12} />
      {usersData?.data && (
        <UsersTable
          users={usersData.data.getUsers.users.filter((user) => {
            const name = `${user.firstName} ${user.lastName}`
            return name.includes(searchText) || user.email.includes(searchText)
          })}
        />
      )}
    </div>
  )
}

export default ManageUsers
