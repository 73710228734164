import React, { useCallback } from 'react'
import { ReactComponent as Ghost } from './assets/ghost.svg'
import { ReactComponent as Home } from './assets/home.svg'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'
import { useNavigate } from 'react-router-dom'

const ErrorPage = () => {
  const navigate = useNavigate()
  const goHome = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      navigate('/')
    },
    [navigate],
  )
  return (
    <div className="h-full flex flex-col justify-center items-center">
      <h1 className="text-wove-blue font-bold text-6xl">404</h1>
      <div className="h-[43px]" />
      <Ghost />
      <div className="h-9" />
      <p className="text-center text-2xl font-semibold max-w-[300px]">Sorry, we were unable to find that page</p>
      <div className="h-9" />
      <ButtonPrimary onClick={goHome}>
        <Home />
        Go Home
      </ButtonPrimary>
    </div>
  )
}

export default ErrorPage
