import React, { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import useUserStore, { UserState } from '@src/state/user'
import { clearStorage, saveAuthData } from '@src/authStore'
import { Customer, User } from '@wove/api/types'
import { useAuthStore } from '@wove/api'
import { OnboardingStep } from '@wove/common/onboardingSteps'
import { useShallow } from 'zustand/react/shallow'

const AuthContext = createContext<{
  user: User | null
  login: (params: { user: User; accessToken: string; customer: Customer }) => Promise<void>
  logout: () => void
  setUser: (user: User | null) => void
  customer: Customer | null
  setCustomer: (customer: Customer | null) => void
  onboardingStep: OnboardingStep | null
  setOnboardingStep: (step: OnboardingStep | null) => void
  authed: boolean
}>({
  user: null,
  /* eslint-disable @typescript-eslint/no-empty-function */
  login: async () => {},
  logout: () => {},
  setUser: () => {},
  customer: null,
  setCustomer: () => {},
  onboardingStep: null,
  setOnboardingStep: () => {},
  /* eslint-enable @typescript-eslint/no-empty-function */
  authed: false,
})

const userSelector = (state: UserState) => ({
  user: state.user,
  setUser: state.setUser,
  setCustomer: state.setCustomer,
  setOnboardingStep: state.setOnboardingStep,
  setOnboarded: state.setOnboarded,
  customer: state.customer,
  onboardingStep: state.onboardingStep,
  authed: state.authed,
})

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const setAccessToken = useAuthStore((state) => state.setAccessToken)
  const {
    user,
    setUser,
    setCustomer,
    setOnboardingStep,
    customer,
    onboardingStep,
    setOnboarded,
    authed,
  } = useUserStore(useShallow(userSelector))
  const navigate = useNavigate()

  // call this function when you want to authenticate the user
  const login = async ({
    user,
    accessToken,
    customer,
  }: {
    user: User
    accessToken: string
    customer: Customer
  }) => {
    setUser(user)
    setCustomer(customer)
    setOnboarded(true)
    saveAuthData({ token: accessToken })
    setAccessToken(accessToken)
    navigate('/')
  }

  // call this function to sign out logged in user
  const logout = () => {
    clearStorage()
    setAccessToken(null)
    setOnboardingStep(null)
    setUser(null)
    setCustomer(null)
    setOnboarded(false)
    // navigate('/login', { replace: true })
  }
  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      setUser,
      customer,
      setCustomer,
      onboardingStep,
      setOnboardingStep,
      authed,
    }),
    [
      user,
      login,
      logout,
      setUser,
      customer,
      setCustomer,
      onboardingStep,
      setOnboardingStep,
      authed,
    ],
  )
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
