import { graphql } from "../gql";
import { useQuery } from "urql";

const searchLocationsQuery = graphql(/* GraphQL */ `
  query SearchLocations($input: SearchLocationsInput!) {
    searchLocations(input: $input) {
      code
      name
      countryCode
      region
    }
  }
`);
export const useSearchLocationsQuery = (variables: { input: { query: string; isPort: boolean} }) => 
useQuery({
  query: searchLocationsQuery,
  variables,
  requestPolicy: 'cache-first',
  context: {},
  pause: !variables.input.query,
})
