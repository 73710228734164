import { graphql } from "../gql";
import { useMutation } from "urql";

const setCompanyInfoMutation = graphql(`
  mutation SetCompanyInfo($companyInfo: SetCompanyInfoDto!) {
    setCompanyInfo(companyInfo: $companyInfo) {
      user {
        ...CurrentUser
      }
      customer {
        ...Customer
      }
    }
  }
`);
export const useSetCompanyInfoMutation = () => useMutation(setCompanyInfoMutation);

const createAccountMutation = graphql(`
  mutation Signup($userData: CreateUserDto!) {
    signup(userData: $userData) {
      id
      email
      firstName
      lastName
      isVerified
    }
  }
`);
export const useCreateAccountMutation = () => useMutation(createAccountMutation);

const setPasswordMutation = graphql(`
  mutation SetPassword($passwordInfo: SetPasswordDto!) {
    setPassword(passwordInfo: $passwordInfo) {
      user {
        ...CurrentUser
      }
      customer {
        ...Customer
      }
    }
  }
`);
export const useSetPasswordMutation = () => useMutation(setPasswordMutation);

const authUsingTokenMutation = graphql(`
  mutation AuthUsingToken($token: String!) {
    authUsingToken(token: $token) {
      tokens {
        accessToken
      }
      user {
        ...CurrentUser
      }
      customer {
        ...Customer
      }
    }
  }
`);
export const useAuthUsingTokenMutation = () => useMutation(authUsingTokenMutation);

const setPersonalInfoMutation = graphql(`
  mutation SetProfileInfo($profileInfo: SetProfileInfoDto!) {
    setProfileInfo(profileInfo: $profileInfo) {
      customer {
        ...Customer
      }
      user {
        ...CurrentUser
      }
    }
  }
`);
export const useSetPersonalInfoMutation = () => useMutation(setPersonalInfoMutation);

const inviteTeamMutation = graphql(`
  mutation InviteTeam($invitedUsers: InviteUsersDto!) {
    inviteUsers(invitedUsers: $invitedUsers) {
      users {
        id
        email
        firstName
        lastName
        isVerified
      }
    }
  }
`);
export const useInviteTeamMutation = () => useMutation(inviteTeamMutation);
