import React, { forwardRef, useCallback } from 'react'
import Checkbox from '@src/Components/Checkbox'
import RadioButton from '@src/Components/RadioButton'

interface RadioInBlockProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  itemTitle: React.ReactNode
  type?: 'checkbox' | 'radio'
  onChange: (checked: boolean) => void
}

const RadioInBlock = forwardRef<HTMLInputElement, RadioInBlockProps>(
  ({ itemTitle, onChange, checked, type = 'radio', ...props }, ref) => {
    const onChangeHandler = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked)
      },
      [onChange],
    )
    const onContainerClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        onChange(!checked)
      },
      [checked, onChange],
    )
    return (
      <div className="flex items-center min-h-[50px] relative bg-white border border-[rgba(90,97,115,0.1)] rounded-lg text-sm text-[#141414] gap-3 cursor-pointer px-3" onClick={onContainerClick}>
        {type === 'radio' && (
          <>
            <RadioButton
              onChange={onChangeHandler}
              onClick={(e) => e.stopPropagation()}
              checked={checked}
              type={type}
              {...props}
              ref={ref}
            />
          </>
        )}
        {type === 'checkbox' && (
          <>
            <Checkbox
              onChange={onChangeHandler}
              onClick={(e) => e.stopPropagation()}
              checked={checked}
              type={type}
              {...props}
              ref={ref}
            />
          </>
        )}
        <div>{itemTitle}</div>
      </div>
    )
  },
)

export default RadioInBlock
