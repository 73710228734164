import React, { useCallback, useState } from 'react'
import { Space } from '@mantine/core'
import BaseModal from '@src/Components/BaseModal'
import IconTitleSubtitle from '@src/Components/IconTitleSubtitle'
import { ReactComponent as AddUserBubble } from '@src/assets/iconTitleSubtitleIcons/add-user-blue-bubble.svg'
import InputPrimary from '@src/Components/InputPrimary'
import FieldLabel from '@src/Components/FieldLabel'
import Select from '@src/Components/Select'
import { ROLE_OPTIONS } from '@src/util/constants'
import { Role } from '@wove/common/roles'
import { SingleValue } from 'react-select'
import { useInviteUsersMutation } from '@wove/api'

interface InviteUsersModalProps {
  onClose: () => void
}
const InviteUserModal = ({ onClose }: InviteUsersModalProps) => {
  const [{ fetching: inviting, error }, inviteUsers] = useInviteUsersMutation()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [role, setRole] = useState<null | Role>(null)
  const onEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }, [])
  const onFirstNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value)
  }, [])
  const onRoleChange = useCallback((option: SingleValue<{ value: Role; label: string }>) => {
    if (option) {
      setRole(option.value)
    }
  }, [])
  const onCreate = useCallback(async () => {
    const result = await inviteUsers({
      invitedUsers: { users: [{ email, firstName, roleType: role as string }] },
    })
    if (result.data?.inviteUsers?.users?.length) {
      onClose()
    }
  }, [email, firstName, role, onClose, inviteUsers])

  return (
    <BaseModal
      fillButtons
      width="440px"
      onCloseButton={onClose}
      buttons={[
        {
          type: 'neutral',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          type: 'primary',
          title: 'Create',
          onClick: onCreate,
          disabled: !email || !firstName || !role,
          loading: inviting,
        },
      ]}
    >
      <IconTitleSubtitle
        icon={<AddUserBubble />}
        title="Create User"
        subtitle="Specify user information"
      />
      <Space h={32} />
      <InputPrimary autoFocus label="Email" type="email" value={email} onChange={onEmailChange} />
      <Space h={16} />
      <InputPrimary label="User First Name" value={firstName} onChange={onFirstNameChange} />
      <Space h={16} />
      <FieldLabel>Role</FieldLabel>
      <Select
        options={ROLE_OPTIONS}
        value={ROLE_OPTIONS.find((r) => r.value === role)}
        onChange={onRoleChange}
      />
      {error && (
        <>
          <Space h={8} />
          <FieldLabel className="wove-error-text">Error: {error.message}</FieldLabel>
        </>
      )}
      <Space h={32} />
    </BaseModal>
  )
}

export default InviteUserModal
