import { useState, useCallback } from 'react'
import InputPrimary from '@src/Components/InputPrimary'
import { Grid, Space } from '@mantine/core'
import BaseButton from '@src/Components/Buttons/BaseButton'
import ChangePasswordModal from '@src/Pages/SettingsPage/LoginAndSecurity/ChangePasswordModal'

const LoginAndSecurity = () => {
  const [changeVisible, setChangeVisible] = useState(false)
  const onChangeClose = useCallback(() => {
    setChangeVisible(false)
  }, [])
  const onChangeOpen = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setChangeVisible(true)
  }, [])
  return (
    <div className="py-5">
      <Grid>
        <Grid.Col span={5}>
          <InputPrimary label="Password" type="password" />
          <Space h={12} />
          <BaseButton
            className="text-[#4c535f] text-base font-medium leading-[18px] underline"
            onClick={onChangeOpen}
          >
            Change password
          </BaseButton>
        </Grid.Col>
      </Grid>
      {changeVisible && <ChangePasswordModal onClose={onChangeClose} />}
    </div>
  )
}

export default LoginAndSecurity
