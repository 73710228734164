declare let localStorage: any

export const deserializeState = (state: any): any => {
  if (state && typeof state === 'object' && state['__isDate'] === true) {
    return new Date(state.value)
  }

  if (typeof state === 'object') {
    if (!state) {
      return null
    }

    if (Array.isArray(state)) {
      return state.map((x) => deserializeState(x))
    }

    const a: any = {}

    for (const k of Object.keys(state)) {
      a[k] = deserializeState(state[k])
    }

    return a
  }

  return state
}
export const serializeState = (state: any): any => {
  if (state instanceof Date) {
    return {
      __isDate: true,
      value: state.valueOf(),
    }
  }

  if (typeof state === 'object') {
    if (!state) {
      return null
    }

    if (Array.isArray(state)) {
      return state.map((x) => serializeState(x))
    }

    const a: any = {}

    for (const k of Object.keys(state)) {
      a[k] = serializeState(state[k])
    }

    return a
  }

  return state
}

const localStorageWrapper = {
  setItem<T>(key: string, val: T): void {
    localStorage.setItem(key, JSON.stringify(val))
  },
  getItem<T>(key: string): T | undefined | null {
    try {
      return JSON.parse(localStorage.getItem(key))
    } catch {
      return null
    }
  },
  removeItem(key: string): void {
    localStorage.removeItem(key)
  },
  get size(): number {
    return localStorage.length
  },
}

export default localStorageWrapper
