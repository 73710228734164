import RateLineItem from '@src/Pages/IntakeForms/RatesPage/RateLineItem'
import { ShippingRate } from '@wove/api/types'

const RateBreakout = ({ rate }: { rate: ShippingRate }) => {
  return (
    <td className="mt-1.5 w-auto" colSpan={6}>
      <div className="ml-3.5 w-0 h-0 border-l-[14px] border-r-[14px] border-b-[14px] border-l-transparent border-r-transparent border-b-[rgba(90,97,115,0.1)] flex justify-center -mb-px">
        <div className="border-l-[12px] border-r-[12px] border-b-[12px] border-l-transparent border-r-transparent border-b-[#fafafb] w-0 h-0 mt-0.5" />
      </div>
      <div className="rounded-[10px] border border-[rgba(90,97,115,0.1)] bg-[rgba(90,97,115,0.03)] py-5">
        {rate.lineItems.map((lineItem, index) => {
          return <RateLineItem lineItem={lineItem} key={index} />
        })}
      </div>
    </td>
  )
}

export default RateBreakout
