import { Client, fetchExchange, mapExchange } from 'urql'
import { cacheExchange } from '@urql/exchange-graphcache'
import { getSourcesQuery, getUsersQuery } from '@wove/api'
import type { Cache } from '@urql/exchange-graphcache'
import type { GetSourcesQuery, UsersQuery } from '@wove/api/types'

const clientCache = cacheExchange({
  updates: {
    Mutation: {
      deleteSource: (_result, args: { id: string }, cache: Cache) => {
        cache.updateQuery<GetSourcesQuery>({ query: getSourcesQuery }, (data) => {
          if (!data?.getSources) {
            return data
          }
          const updatedSources = data.getSources.filter(
            (source) => source.id !== args.id
          )
          return { ...data, getSources: updatedSources }
        })
      },
      inviteUsers: (_result, _args, cache: Cache) => {
        cache.invalidate({
          __typename: 'Query',
          fieldName: 'getUsers',
        })
      },
      deactivateUser: (_result, args: { id: string }, cache: Cache) => {
        cache.updateQuery<UsersQuery>({ query: getUsersQuery }, (data) => {
          if (!data?.getUsers?.users) {
            return data
          }
          data.getUsers.users = data.getUsers.users.map((user) => {
            if (user.id === args.id) {
              return { ...user, deactivatedAt: new Date().toISOString() }
            }
            return user
          })
          return data
        })
      },
    },
  },
  keys: {
    CurrentSessionResponse: () => null,
    UsersResponse: () => null,
    Price: () => null,
    RateLineItem: () => null,
    ShippingRate: () => null,
    Location: () => null,
    ContainerRate: () => null,
    Carrier: () => null,
    SpreadsheetLocation: () => null,
  },
})

export function createClient(accessToken?: string, apiUrl?: string) {
  const headers: Record<string, string> = {
    'apollo-require-preflight': 'true',
  }
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`
  }

  return new Client({
    url: apiUrl ? `${apiUrl}/graphql` : 'http://localhost:4000/graphql',
    fetchOptions: {
      headers,
    },
    exchanges: [
      mapExchange({
        onError(error, operation) {
          console.log('Error:', error, operation)
        },
      }),
      clientCache,
      fetchExchange,
    ],
  })
}
