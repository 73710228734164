import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Space } from '@mantine/core'
import { PageTitle } from '@src/Pages/Splash/CommonComponents'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'
import FieldLabel from '@src/Components/FieldLabel'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Spinner from '@src/Components/Spinner'
import { getToken, saveAuthData } from '@src/authStore'
import { useSetPasswordMutation, useAuthStore } from '@wove/api'
import PasswordField from '@src/Components/PasswordField'
import Prompt from '@src/Components/Prompt'
import { getCombinedErrorMessage } from '@src/util/helpers'
import { useAuthUsingTokenMutation } from '@wove/api'
import useUserStore from '@src/state/user'

const SetPasswordScreen = () => {
  const navigate = useNavigate()
  const setSplashInfo = useUserStore((state) => state.setSplashInfo)
  const [params] = useSearchParams()
  const verified = useRef(false)
  const [tokenInvalid, setTokenInvalid] = useState(false)
  const [tokenVerified, setTokenVerified] = useState(true)
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [touchedConfirm, setTouchedConfirm] = useState(false)
  const showPasswordMatch = password.length >= 6 && touchedConfirm && passwordConfirm === password
  const [{ fetching: submittingPassword, error }, configurePassword] =
    useSetPasswordMutation()
  const [_authResult, authUsingToken] = useAuthUsingTokenMutation()
  const setAccessToken = useAuthStore((state) => state.setAccessToken)
  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const response = await configurePassword({
        passwordInfo: {
          password: password.trim(),
        },
      })
      if (response.data) {
        const { user, customer } = response.data.setPassword
        setSplashInfo({ user, customer })
        navigate('/personal-info')
      }
    },
    [configurePassword, navigate, password],
  )

  useEffect(() => {
    const verifyToken = async () => {
      setTokenVerified(false)
      const token = params.get('token')

      if (!token) {
        setTokenInvalid(true)
        return
      }

      try {
        const result = await authUsingToken({ token })
        if (result.error || !result.data) {
          setTokenInvalid(true)
          return
        }

        const { tokens, customer, user } = result.data.authUsingToken
        const accessToken = tokens.accessToken
        saveAuthData({ token: accessToken })
        setSplashInfo({ user, customer })
        setAccessToken(accessToken)
        setTokenVerified(true)
      } catch (error) {
        setTokenInvalid(true)
      }
    }

    const accessToken = getToken()
    if (!accessToken && !verified.current) {
      // Should not matter in prod, prevents strict mode from calling twice
      verified.current = true
      verifyToken()
    }
  }, [params, authUsingToken, setAccessToken])

  return (
    <>
      {tokenInvalid ? (
        'Token was invalid'
      ) : tokenVerified ? (
        <form onSubmit={onSubmit}>
          <PageTitle>Set password</PageTitle>
          <Space h={30} />
          <PasswordField
            autoFocus
            autoComplete="new-password"
            label="Password"
            error={passwordError}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
              setPasswordError(e.target.value.length >= 6 ? '' : 'Password is too short')
            }}
          />
          <Space h={20} />
          <div className={showPasswordMatch ? 'border-2 border-[#21a366]' : ''}>
            <PasswordField
              label="Confirm Password"
              autoComplete="new-password"
              error={touchedConfirm && passwordConfirm !== password ? 'Passwords must match' : ''}
              customPrompt={
                showPasswordMatch ? (
                  <FieldLabel className="text-[#21a366]">Passwords match</FieldLabel>
                ) : (
                  ''
                )
              }
              value={passwordConfirm}
              onChange={(e) => {
                setPasswordConfirm(e.target.value)
                setTouchedConfirm(true)
              }}
            />
          </div>
          <Space h={20} />
          {error && (
            <>
              <Prompt message={getCombinedErrorMessage(error)} />
              <Space h={20} />
            </>
          )}
          <ButtonPrimary
            type="submit"
            disabled={
              submittingPassword ||
              password.trim().length === 0 ||
              passwordConfirm.trim().length === 0
            }
            $matchWidth
          >
            {submittingPassword && <Spinner />}
            Continue
          </ButtonPrimary>
          <Space h={25} />
        </form>
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default SetPasswordScreen
