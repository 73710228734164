import { SwitchButton } from '@src/Pages/Splash/CommonComponents'

const SwitchView = ({
  keyText,
  actionText,
  onAction,
}: {
  keyText: string
  actionText: string
  onAction: () => void
}) => {
  return (
    <div className="flex items-center gap-[10px] text-black text-xs font-semibold">
      {keyText}
      <SwitchButton onClick={onAction}>{actionText}</SwitchButton>
    </div>
  )
}

export default SwitchView
