import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    .react-datepicker {
      &__triangle {
        display: none;
      }
      &__navigation {
        &--previous {
          top: 20px;
        }
        &--next {
          top: 20px;
        }
      }
      &__header {
        background-color: #fff;
        border-bottom: 1px solid rgba(90, 97, 115, 0.10);
        padding: 0;
      }
      &__day-names {
        border-top: 1px solid rgba(90, 97, 115, 0.10);
      }
      &__current-month {
        font-size: 1rem;
        padding: 16px 0;
        font-weight: 600;
      }
      &__day-name {
        margin: 6px;
        width: 32px;
        height: 32px;
        font-weight: 700;
        font-size: 0.625rem;
      }
      &__day {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 6px;
        width: 32px;
        height: 32px;
        border-radius: 999px;
        &:hover {
          border-radius: 999px;
        }
        &--selected {
          border-radius: 30px;
          background: #0685F1;
        }
      }
    } 
    .wove-container {
      max-width: 800px;
    }
    .wove-form-error {
      border: 1px solid red;
    }
    .wove-error-text {
      color: red;
    }
    
    .rc-tooltip {
      border-radius: 8px;
      background: #FFF;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
      &-inner {
        border: none;
      }
      &-arrow {
        border-color: #fff !important;
      }
    }
    
    @media print {
      .rate-breakout-print-hide {
        display: none;
      }
    }
`

export default GlobalStyle
