import { graphql } from "../gql";
import { useMutation, useQuery } from "urql";

const logoutMutation = graphql(`
  mutation Logout {
    logout {
      id
      email
      firstName
      lastName
      isVerified
    }
  }
`);
export const useLogoutMutation = () => useMutation(logoutMutation);

const loginMutation = graphql(`
  mutation Login($login: LoginUserDto!) {
    login(login: $login) {
      tokens {
        accessToken
      }
      user {
        ...CurrentUser
      }
      customer {
        ...Customer
      }
    }
  }
`);
export const useLoginMutation = () => useMutation(loginMutation);

export const getCurrentSessionQuery = graphql(`
  query CurrentSession {
    currentSession {
      onboardingStep
      user {
        ...CurrentUser
      }
      customer {
        ...Customer
      }
    }
  }
`);
export const useGetCurrentSessionQuery = () => useQuery({ query: getCurrentSessionQuery });

const resetPasswordMutation = graphql(`
  mutation ResetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token)
  }
`)

export const useResetPasswordMutation = () => useMutation(resetPasswordMutation)

const requestResetPasswordMutation = graphql(`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`)

export const useRequestResetPasswordMutation = () => useMutation(requestResetPasswordMutation)
