import React, { useState, useCallback, Fragment } from 'react'
import { Space } from '@mantine/core'
import { PageTitle } from '@src/Pages/Splash/CommonComponents'
import InputPrimary from '@src/Components/InputPrimary'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'
import addPlus from './assets/add-plus.svg'
import { useNavigate } from 'react-router-dom'
import Spinner from '@src/Components/Spinner'
import { Role } from '@wove/common/roles'
import Select from '@src/Components/Select'
import FieldLabel from '@src/Components/FieldLabel'
import { ROLE_OPTIONS } from '@src/util/constants'
import Prompt from '@src/Components/Prompt'
import { getCombinedErrorMessage } from '@src/util/helpers'
import { useInviteTeamMutation } from '@wove/api'
import useUserStore from '@src/state/user'

interface Teammate {
  email: string
  firstName: string
  role: null | Role
}

const EMPTY_TEAMMATE: Teammate = {
  email: '',
  firstName: '',
  role: null,
}

const PersonalInfoScreen = () => {
  const setOnboarded = useUserStore((state) => state.setOnboarded)
  const navigate = useNavigate()
  const [{ fetching: submitting, error }, inviteTeammates] = useInviteTeamMutation()
  const [teammates, setTeammates] = useState([EMPTY_TEAMMATE])

  const onAdd = useCallback(() => {
    setTeammates((prev) => [...prev, EMPTY_TEAMMATE])
  }, [])
  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const invitedUsers = teammates.reduce((acc, user) => {
        if (user.email.trim() && user.firstName.trim() && user.role) {
          acc.push({
            email: user.email.trim(),
            firstName: user.firstName.trim(),
            roleType: user.role,
          })
        }
        return acc
      }, [] as { email: string; firstName: string; roleType: Role }[])
      const response = await inviteTeammates({
        invitedUsers: {
          users: invitedUsers,
        },
      })
      setOnboarded(true)
      if (!response.error) {
        navigate('/signup-success')
      }
    },
    [setOnboarded, teammates, inviteTeammates, navigate],
  )
  const onSkip = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      setOnboarded(true)
      navigate('/signup-success')
    },
    [setOnboarded, navigate],
  )
  return (
    <form onSubmit={onSubmit}>
      <PageTitle>Who else do you work with?</PageTitle>
      <Space h={30} />
      {teammates.map((teammate, index) => {
        return (
          <Fragment key={index}>
            <InputPrimary
              autoFocus={index === 0}
              placeholder="i.e. Johnny"
              label="First Name"
              value={teammate.firstName}
              onChange={(e) => {
                const text = e.target.value
                setTeammates((prev) => {
                  const newTeammates = [...prev]
                  newTeammates[index] = {
                    ...prev[index],
                    firstName: text,
                  }
                  return newTeammates
                })
              }}
            />
            <Space h={10} />
            <InputPrimary
              type="email"
              placeholder="name@example.com"
              label="Work Email"
              value={teammate.email}
              onChange={(e) => {
                const text = e.target.value
                setTeammates((prev) => {
                  const newTeammates = [...prev]
                  newTeammates[index] = {
                    ...prev[index],
                    email: text,
                  }
                  return newTeammates
                })
              }}
            />
            <Space h={10} />
            <FieldLabel>Role</FieldLabel>
            <Select
              options={ROLE_OPTIONS}
              value={ROLE_OPTIONS.find((r) => r.value === teammate.role)}
              onChange={(option) => {
                setTeammates((prev) => {
                  const newTeammates = [...prev]
                  newTeammates[index] = {
                    ...prev[index],
                    role: option ? (option.value as Role) : null,
                  }
                  return newTeammates
                })
              }}
            />
            <Space h={10} />
            {!!teammates[index + 1] && <Space h={20} />}
          </Fragment>
        )
      })}
      <Space h={6} />
      <div className="flex justify-end">
        <button
          className="bg-transparent border-none gap-2.5 text-xs font-semibold text-black flex"
          onClick={onAdd}
        >
          <img src={addPlus} alt="black plus in circle" />
          Add another
        </button>
      </div>
      <Space h={35} />
      {error && (
        <>
          <Prompt message={getCombinedErrorMessage(error)} />
          <Space h={20} />
        </>
      )}
      <ButtonPrimary $matchWidth type="submit" disabled={submitting}>
        {submitting && <Spinner />}
        Add Teammates
      </ButtonPrimary>
      <Space h={24} />
      <button
        className="mx-auto block text-wove-blue border-none bg-transparent text-xs font-semibold"
        onClick={onSkip}
      >
        Skip for now
      </button>
    </form>
  )
}

export default PersonalInfoScreen
