import React from 'react'
import BaseButton from '@src/Components/Buttons/BaseButton'
import leftChevron from './assets/blue-chevron-left.svg'
import rightChevron from './assets/blue-chevron-right.svg'

export const PageTitle = ({
  children,
  className = '',
}: {
  children: React.ReactNode
  className?: string
}) => <h2 className={`text-xl font-bold text-black leading-[18px] ${className}`}>{children}</h2>

export const Instructions = ({
  children,
  className = '',
}: {
  children: React.ReactNode
  className?: string
}) => <p className={`text-black text-sm font-medium leading-[18px] ${className}`}>{children}</p>

export const SwitchButton = ({
  back,
  children,
  className = '',
  ...props
}: { back?: boolean; className?: string } & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <BaseButton
      className={`flex gap-2 text-xs items-center bg-[rgba(23,144,249,0.15)] px-[10px] min-h-[30px] font-semibold rounded-md text-[#0685F1] ${className}`}
      {...props}
    >
      {back && <img src={leftChevron} alt="blue chevron left" />}
      {children}
      {!back && <img src={rightChevron} alt="blue chevron right" />}
    </BaseButton>
  )
}

export const TransparentButton = ({
  children,
  className = '',
  ...props
}: {
  children: React.ReactNode
  className?: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <BaseButton
    className={`text-[#0685F1] border-none bg-none text-xs font-semibold ${className}`}
    {...props}
  >
    {children}
  </BaseButton>
)
