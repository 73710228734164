import React from 'react'
import BaseButton from '@src/Components/Buttons/BaseButton'

interface CloseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
}

const CloseButton = ({ className = '', ...props }: CloseButtonProps) => {
  return (
    <BaseButton
      className={`
        h-7 w-7 rounded-lg flex justify-center items-center
        bg-black/10
        ${className}
      `}
      {...props}
    >
      <div className="w-4 h-4 relative rotate-45">
        <div className="absolute inset-x-0 top-1/2 -translate-y-1/2 h-0.5 bg-black rounded-[1px]" />
        <div className="absolute inset-y-0 left-1/2 -translate-x-1/2 w-0.5 bg-black rounded-[1px]" />
      </div>
    </BaseButton>
  )
}

export default CloseButton
