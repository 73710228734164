import { memo, useState } from 'react'
import BaseModal from '@src/Components/BaseModal'
import RadioButton from '@src/Components/RadioButton'
import InputPrimary from '@src/Components/InputPrimary'
import { ShippingRate } from '@wove/api/types'

interface SellRateModalProps {
  onClose: () => void
  rate: ShippingRate
  onSellRate: ({
    rate,
    type,
    margin,
  }: {
    rate: ShippingRate
    type: 'percentage' | 'dollar'
    margin: number
  }) => void
}

const toNumber = (value: string | number) => {
  if (typeof value === 'number') return value
  return parseInt(value.replace(/[^\d]+/g, ''))
}

const SellRateModal = ({ onClose, onSellRate, rate }: SellRateModalProps) => {
  const [percentageSelected, setPercentageSelected] = useState(true)
  const [margin, setMargin] = useState(0)
  const [previewTotal, setPreviewTotal] = useState(false)
  return (
    <BaseModal
      alignItems="center"
      contentColor="#fff"
      title="Create Sell Rate Sheet"
      buttons={[
        {
          type: 'neutral',
          title: previewTotal ? 'Back' : 'Cancel',
          onClick: () => {
            if (previewTotal) {
              setPreviewTotal(false)
            } else {
              onClose()
            }
          },
        },
        {
          type: 'primary',
          title: previewTotal ? 'Create' : 'Calculate',
          onClick: () => {
            if (previewTotal) {
              onSellRate({ rate, margin, type: percentageSelected ? 'percentage' : 'dollar' })
            } else {
              setPreviewTotal(true)
            }
          },
        },
      ]}
      width="453px"
      onCloseButton={onClose}
      fillButtons
    >
      <div className="w-full pb-7">
        {previewTotal ? (
          <div className="flex justify-between p-3 max-w-[306px] rounded-lg mx-auto border border-[rgba(90,97,115,0.1)] text-sm text-[#141414] font-medium text-left [&_.sell-rate]:font-bold">
            <p>
              Buy Rate
              <br />
              <br />${rate.allInRate.amount}
            </p>
            x
            <p>
              {percentageSelected ? '%' : '$'} Margin
              <br />
              <br />
              {!percentageSelected && '$'}
              {margin}
              {percentageSelected && '%'}
            </p>
            =
            <p className="sell-rate">
              Sell Rate
              <br />
              <br />$
              {percentageSelected
                ? rate.allInRate.amount * (1 + margin / 100)
                : rate.allInRate.amount + margin}
            </p>
          </div>
        ) : (
          <>
            <div className="flex rounded-lg border border-[rgba(90,97,115,0.1)] bg-white items-stretch relative mb-2.5">
              <div className="flex items-center p-3 gap-3 flex-[1_1_0px] relative">
                <RadioButton
                  id="percentage"
                  checked={percentageSelected}
                  onChange={() => setPercentageSelected(true)}
                />
                <label htmlFor="percentage">Percentage</label>
              </div>
              <div className="h-full w-px bg-[rgba(90,97,115,0.1)]" />
              <div className="flex items-center p-3 gap-3 flex-[1_1_0px] relative">
                <RadioButton
                  id="dollar"
                  checked={!percentageSelected}
                  onChange={() => setPercentageSelected(false)}
                />
                <label htmlFor="dollar">US Dollars</label>
              </div>
            </div>
            <InputPrimary
              placeholder={percentageSelected ? 'Enter percentage number' : 'Enter dollar amount'}
              type="text"
              value={
                margin
                  ? `${!percentageSelected ? '$' : ''}${margin}${percentageSelected ? '%' : ''}`
                  : ''
              }
              onChange={(e) => {
                let value = e.target.value
                if (value[0] === '$') {
                  value = value.slice(1)
                }
                if (value[value.length - 1] === '%') {
                  e.target.selectionEnd = value.length - 2
                  value = value.slice(0, -1)
                }
                setMargin(toNumber(value))
              }}
            />
          </>
        )}
      </div>
    </BaseModal>
  )
}

export default memo(SellRateModal)
