import { useLocation, useNavigate, useOutlet } from 'react-router-dom'
import { AuthProvider } from '@src/hooks/useAuth'
import { useEffect } from 'react'
import useUserStore from '@src/state/user'

export const ONBOARDING_ROUTES = [
  '/signup',
  '/personal-info',
  '/company-info',
  '/set-password',
  '/invite-teammates',
  '/login',
  '/reset-password',
  '/signup-success',
]

const RootLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const outlet = useOutlet()
  const onboarded = useUserStore((state) => state.onboarded)

  useEffect(() => {
    if (
      location.pathname !== '/signup-success' &&
      ONBOARDING_ROUTES.includes(location.pathname) &&
      onboarded
    ) {
      navigate('/', { replace: true })
    }
  }, [location.pathname, onboarded, navigate])

  return <AuthProvider>{outlet}</AuthProvider>

  // return (
  //   <Suspense fallback={<Spinner />}>
  //     <Await
  //       resolve={loader}
  //       children={() => {
  //         return <AuthProvider>{outlet}</AuthProvider>
  //       }}
  //     />
  //   </Suspense>
  // )
}

export default RootLayout
