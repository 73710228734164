import Header from '@src/Pages/IntakeForms/Components/Header'
import { useLocation } from 'react-router-dom'
import ButtonSecondary from '@src/Components/Buttons/ButtonSecondary'
import emailIcon from '@src/assets/email.svg'
import printIcon from '@src/assets/print.svg'
import downloadIcon from '@src/assets/download.svg'
import RateSheet from '@src/Pages/SellRatePage/RateSheet'
import { ShippingRate } from '@wove/api/types'
import { DisplayInfo } from '@src/Pages/IntakeForms/RatesPage'

const SellRatePage = () => {
  const location = useLocation()
  const { displayInfo, rate, sellRate } = location.state as {
    displayInfo: DisplayInfo
    rate: ShippingRate
    sellRate: number
  }
  return (
    <div className="bg-background-light">
      <Header title="Sell Rate Sheet">
        <div className="flex justify-center gap-3.5 pt-1">
          <ButtonSecondary className="min-w-[168px]">
            <img src={emailIcon} alt="blue envelope" />
            Email
          </ButtonSecondary>
          <ButtonSecondary className="min-w-[168px]">
            <img src={printIcon} alt="blue printer" />
            Print
          </ButtonSecondary>
          <ButtonSecondary className="min-w-[168px]">
            <img src={downloadIcon} alt="blue download icon" />
            Download
          </ButtonSecondary>
        </div>
      </Header>
      <div className="py-[50px] pb-[100px]">
        <div className="container mx-auto">
          <div className="max-w-[778px] mx-auto">
            <RateSheet displayInfo={displayInfo} rate={rate} sellRate={sellRate} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SellRatePage
