import woveLogoBlack from './assets/wove-logo-black.svg'
import woveBackground from './assets/wove-background.svg'
import rightArrow from './assets/arrow-right.svg'
import { format } from 'date-fns'
import { DisplayInfo } from '@src/Pages/IntakeForms/RatesPage'
import { CONTAINER_TITLES } from '@src/util/constants'
import SellRateTable from '@src/Pages/SellRatePage/SellRateTable'
import { ShippingRate } from '@wove/api/types'

interface RateSheetProps {
  displayInfo: DisplayInfo
  rate: ShippingRate
  sellRate: number
}

const RateSheet = ({ displayInfo, rate, sellRate }: RateSheetProps) => {
  const {
    shippingFrom,
    shippingTo,
    departureDateFrom,
    departureDateTo,
    loadType,
    // commodity,
    containers,
  } = displayInfo
  return (
    <div
      className="w-full max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-sm"
      style={{
        backgroundImage: `url(${woveBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
      }}
    >
      <div className="flex items-center gap-4 mb-6 text-2xl font-medium">
        <img src={woveLogoBlack} alt="wove logo black" />
        Rate Sheet
      </div>
      <div className="text-xl font-medium mb-8">Acme Forwarding, Inc</div>
      <div className="mb-8">
        <div className="text-lg font-medium mb-4">Information</div>
        <div className="border border-gray-200 rounded-lg overflow-hidden">
          <div className="flex items-center gap-6 p-6 border-b border-gray-200">
            <div className="flex-1">
              <div className="text-sm text-gray-500 mb-2">Ship From</div>
              <div className="text-base">
                Shipment origin:{' '}
                <span className="font-medium">
                  {shippingFrom.name} {shippingFrom.code}
                </span>
              </div>
            </div>
            <div className="relative">
              <div className="w-px h-12 bg-gray-200">
                <div className="absolute top-1/2 -translate-y-1/2 -translate-x-1/2 w-6 h-6 rounded-full bg-white border border-gray-200 flex items-center justify-center">
                  <img src={rightArrow} alt="right arrow" className="w-3 h-3" />
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="text-sm text-gray-500 mb-2">Ship To</div>
              <div className="text-base">
                Final Destination:{' '}
                <span className="font-medium">
                  {shippingTo.name} {shippingTo.code}
                </span>
              </div>
            </div>
          </div>
          <div className="p-6 border-b border-gray-200">
            <div className="text-sm text-gray-500 mb-2">Departure Date</div>
            <div className="text-base">
              From: {format(departureDateFrom, 'MM/dd/yy')}
              <br />
              To: {format(departureDateTo, 'MM/dd/yy')}
            </div>
          </div>
          <div className="p-6 border-b border-gray-200">
            <div className="text-sm text-gray-500 mb-2">Load Information</div>
            <div className="text-base">
              Load type: {loadType}
              <br />
              Containers:{' '}
              {containers.map((container, index) => (
                <p key={index}>
                  {container.numberRequired}
                  {' x '}
                  {CONTAINER_TITLES[container.type]} containers
                </p>
              ))}
            </div>
          </div>
          <div className="p-6">
            <div className="text-sm text-gray-500 mb-2">Commodity</div>
            <div className="text-base">FAK</div>
          </div>
        </div>
      </div>
      <div className="mb-8">
        <div className="text-lg font-medium mb-4">Sell Rate</div>
        <SellRateTable rate={rate} sellRate={sellRate} />
      </div>
      <div className="text-sm text-gray-500">
        Terms & Conditions
        <a
          href="https://wove.com/terms"
          target="_blank"
          className="ml-2 text-wove-blue hover:text-wove-blue-dark"
        >
          https://wove.com/terms
        </a>
      </div>
    </div>
  )
}

export default RateSheet
