import { useCallback, useState, useRef } from 'react'
import InfoTable from './InfoTable'
import locationIcon from './assets/location.svg'
import commodityIcon from './assets/commodity.svg'
import loadInfoIcon from './assets/load-info.svg'
import calendarIcon from './assets/calendar.svg'
import { useLocation } from 'react-router-dom'
import { format } from 'date-fns'
import RateTable from '@src/Pages/IntakeForms/RatesPage/RateTable'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'
import { useNavigate } from 'react-router-dom'
import ButtonSecondary from '@src/Components/Buttons/ButtonSecondary'
import { CONTAINER_TITLES } from '@src/util/constants'
import NoRates from '@src/Pages/IntakeForms/RatesPage/NoRates'
import searchBlue from './assets/search.svg'
import searchWhite from './assets/search-white.svg'
import ActionButtons from '@src/Pages/IntakeForms/RatesPage/ActionButtons'
import SellRateModal from '@src/Pages/IntakeForms/RatesPage/SellRateModal'
import { IntakeFormValues } from '@src/Pages/IntakeForms/IntakeFormPage'
import { useReactToPrint } from 'react-to-print'
import { ShippingRate, Location, ContainerType } from '@wove/api/types'

export interface RatesPageParams {
  rates: ShippingRate[]
  values: IntakeFormValues
  reeferSelected: boolean
}

export interface DisplayInfo {
  shippingFrom: Location
  shippingTo: Location
  departureDateFrom: Date
  departureDateTo: Date
  loadType: string
  commodity: string
  containers: { reefer: boolean; type: ContainerType; numberRequired: number }[]
}

const RatesPage = () => {
  const location = useLocation()
  const toPrintRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const state = location.state as RatesPageParams
  const { rates, values, reeferSelected } = state
  const displayInfo: DisplayInfo = {
    shippingFrom: values.shippingFrom[0].port!,
    shippingTo: values.shippingTo[0].port!,
    departureDateFrom: values.departureDateFrom!,
    departureDateTo: values.departureDateTo!,
    loadType: 'FCL',
    commodity: values.commodity,
    containers: values.containers
      .filter((container) => container.count && container.enabled)
      .map((container) => ({
        reefer: reeferSelected,
        type: container.key,
        numberRequired: container.count!,
      })),
  }
  const [rateToSellIndex, setRateToSellIndex] = useState<null | number>(null)
  const onModifySearch = useCallback(() => {
    navigate('/', {
      state: { preloadValues: true },
    })
  }, [navigate])
  const onNewSearch = useCallback(() => {
    navigate('/')
  }, [navigate])
  const onCloseSellRate = useCallback(() => {
    setRateToSellIndex(null)
  }, [])
  const onSellRate = useCallback(
    ({
      margin,
      type,
      rate,
    }: {
      margin: number
      type: 'percentage' | 'dollar'
      rate: ShippingRate
    }) => {
      if (rate) {
        const originalRate = rate.allInRate.amount
        const sellRate =
          type === 'percentage' ? originalRate * (1 + margin / 100) : originalRate + margin
        navigate('/sell-rate', {
          state: {
            sellRate,
            displayInfo,
            rate,
          },
        })
      }
    },
    [rates],
  )
  const handlePrint = useReactToPrint({
    content: () => toPrintRef.current,
  })
  if (!state || !state.rates || !state.values) {
    return (
      <div className="bg-[#f4f6f8]">
        <div className="container text-center py-8 mx-auto">Invalid search, please try again</div>
        <footer className="px-4 pb-9 pt-4">
          <div className="container mx-auto flex justify-end gap-2.5">
            <ButtonSecondary onClick={onNewSearch}>New Search</ButtonSecondary>
            <ButtonPrimary onClick={onModifySearch}>Modify Last Search</ButtonPrimary>
          </div>
        </footer>
      </div>
    )
  }

  const {
    shippingFrom,
    shippingTo,
    departureDateFrom,
    departureDateTo,
    loadType,
    commodity,
    containers,
  } = displayInfo

  const tableData = [
    {
      key: 'Ship From',
      icon: locationIcon,
      iconAlt: 'blue location pin',
      info: (
        <>
          <span className="text-[#717b8c]">Shipment origin: </span>
          {shippingFrom.name} ({shippingFrom.code})
        </>
      ),
    },
    {
      key: 'Ship To',
      icon: locationIcon,
      iconAlt: 'blue location pin',
      info: (
        <>
          <span className="text-[#717b8c]">Final Destination: </span>
          {shippingTo.name} ({shippingTo.code})
        </>
      ),
    },
    {
      key: 'Departure Date',
      icon: calendarIcon,
      iconAlt: 'blue calendar',
      info: (
        <>
          <span className="text-[#717b8c]">From: </span>
          {format(departureDateFrom, 'MM/dd/yy')}
          <br />
          <span className="text-[#717b8c]">To: </span>
          {format(departureDateTo, 'MM/dd/yy')}
        </>
      ),
    },
    {
      key: 'Load Information',
      icon: loadInfoIcon,
      iconAlt: 'blue shipping container',
      info: (
        <>
          <span className="text-[#717b8c]">Load type: </span>
          {loadType}
          <br />
          <span className="text-[#717b8c]">Containers:</span>
          <br />
          {containers.map((container, index) => (
            <p key={index}>
              {container.numberRequired}
              {' x '}
              {CONTAINER_TITLES[container.type]} containers
            </p>
          ))}
        </>
      ),
    },
    {
      key: 'Commodity',
      icon: commodityIcon,
      iconAlt: 'blue commodity box',
      info: commodity,
    },
  ]

  return (
    <div className="bg-[#f4f6f8]">
      <div className="flex bg-white py-6">
        <div className="w-[168px] px-3.5 flex flex-col items-stretch gap-2.5" />
        <div className="flex-1 text-center flex flex-col justify-center">
          <h3 className="text-2xl font-semibold">Rates</h3>
          <div className="pt-4 flex justify-center gap-2.5">
            <ButtonSecondary onClick={onNewSearch}>
              <img src={searchBlue} alt="blue magnifying glass" />
              New Search
            </ButtonSecondary>
            <ButtonPrimary onClick={onModifySearch}>
              <img src={searchWhite} alt="blue magnifying glass" />
              Modify Last Search
            </ButtonPrimary>
          </div>
        </div>
        <ActionButtons onPrint={handlePrint} />
      </div>
      <div className="container mx-auto pt-[45px]" ref={toPrintRef}>
        <InfoTable tableData={tableData} />
        {rates.length > 0 ? (
          <div className="mt-5">
            <RateTable rates={rates} onSellRate={setRateToSellIndex} />
          </div>
        ) : (
          <NoRates />
        )}
      </div>
      <footer className="px-4 pb-9 pt-4">
        <div className="container mx-auto flex justify-end gap-2.5">
          <ButtonSecondary onClick={onNewSearch}>New Search</ButtonSecondary>
          <ButtonPrimary onClick={onModifySearch}>Modify Last Search</ButtonPrimary>
        </div>
      </footer>
      {rateToSellIndex !== null && (
        <SellRateModal
          onClose={onCloseSellRate}
          onSellRate={onSellRate}
          rate={rates[rateToSellIndex]}
        />
      )}
    </div>
  )
}

export default RatesPage
