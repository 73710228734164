import { graphql } from "../gql";
import { useMutation, useQuery } from "urql";

export const getUsersQuery = graphql(`
  query Users {
    getUsers {
      users {
        id
        email
        firstName
        lastName
        isVerified
        role
        deactivatedAt
      }
    }
  }
`);

const inviteUsersMutation = graphql(`
  mutation InviteUsers($invitedUsers: InviteUsersDto!) {
    inviteUsers(invitedUsers: $invitedUsers) {
      users {
        id
        email
        firstName
        lastName
        isVerified
      }
    }
  }
`);

export const useGetUsersQuery = () => useQuery({ query: getUsersQuery });
export const useInviteUsersMutation = () => useMutation(inviteUsersMutation);
