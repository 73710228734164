import { NavLink } from 'react-router-dom'

const HEADER_LINKS = [
  {
    label: 'Account Settings',
    url: '/settings/account-settings',
    key: 'accountSettings',
  },
  {
    label: 'Login & Security',
    url: '/settings/login-security',
    key: 'loginAndSecurity',
  },
  {
    label: 'Manage Users',
    url: '/settings/manage-users',
    key: 'manageUsers',
  },
  {
    label: 'Notifications',
    url: '/settings/notifications',
    key: 'notifications',
  },
]

const HeaderNavLinks = () => {
  return (
    <div className="flex gap-3">
      {HEADER_LINKS.map((link) => {
        return (
          <NavLink
            key={link.key}
            to={link.url}
            className={({ isActive }) =>
              `px-3 text-[#717b8c] text-xl font-medium border-b-2 border-[#e0e4ec] ${isActive ? 'text-[#288bef] border-[#288bef] font-bold' : ''}`
            }
          >
            {link.label}
          </NavLink>
        )
      })}    </div>
  )
}

export default HeaderNavLinks
