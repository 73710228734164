import React, { useCallback } from 'react'
import { Space } from '@mantine/core'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'
import { useNavigate } from 'react-router-dom'

const SuccessScreen = () => {
  const navigate = useNavigate()
  const onEnter = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      navigate('/')
    },
    [navigate],
  )
  return (
    <div className="flex flex-col items-center w-full">
      <h2 className="text-xl font-medium">Boom! All Set!</h2>
      <Space h={42} />
      <ButtonPrimary className="w-full max-w-[332px]" onClick={onEnter}>
        Enter Workspace
      </ButtonPrimary>
    </div>
  )
}

export default SuccessScreen
