import { Outlet } from 'react-router-dom'
import HeaderNavLinks from '@src/Pages/SettingsPage/HeaderNavLinks'

const SettingsPage = () => {
  return (
    <div className="py-12 px-6">
      <HeaderNavLinks />
      <Outlet />
    </div>
  )
}

export default SettingsPage
