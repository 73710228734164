// import PageHeader from '@src/Components/PageHeader'
import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '@src/hooks/useAuth'
import SideNavigation from '@src/Components/SideNavigation'

const AppPage = ({ showSidebar }: { showSidebar?: boolean }) => {
  const { user, onboardingStep, authed } = useAuth()
  if (authed && (!user || onboardingStep)) {
    // user is not authenticated
    return <Navigate to="/login" />
  }

  return (
    <div className="flex h-full">
      {showSidebar && <SideNavigation />}
      <div className="flex-[1_1_0px] overflow-auto">
        {/*<PageHeader />*/}
        <Outlet />
      </div>
    </div>
  )
}

export default AppPage
