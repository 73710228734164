import { useState, memo } from 'react'
import BaseModal from '@src/Components/BaseModal'
import { useAuth } from '@src/hooks/useAuth'
import { ReactComponent as Pencil } from './assets/pencil.svg'
import BaseButton from '@src/Components/Buttons/BaseButton'
import InputPrimary from '@src/Components/InputPrimary'
import Prompt from '@src/Components/Prompt'
import { useMutation } from 'urql'
import { CurrentUserFragment } from '@src/util/fragments'

const setPersonalInfoMutation = `
    ${CurrentUserFragment}
    mutation SetProfileInfo($profileInfo: SetProfileInfoDto!) {
      setProfileInfo(profileInfo: $profileInfo) {
        user {
          ...CurrentUser
        }
      }
    }
`

interface ProfileModalProps {
  onClose: () => void
}

const ProfileModal = ({ onClose }: ProfileModalProps) => {
  const { user, setUser } = useAuth()
  const [success, setSuccess] = useState(false)
  const [firstName, setFirstName] = useState(user?.firstName || '')
  const [lastName, setLastName] = useState(user?.lastName || '')
  const [{ fetching: editing }, setPersonalInfo] = useMutation(setPersonalInfoMutation)

  const onEdit = async () => {
    setSuccess(false)
    const response = await setPersonalInfo({
      profileInfo: {
        jobTitle: user!.jobTitle,
        firstName,
        lastName,
      },
    })
    if (response.data) {
      setUser(response.data.setProfileInfo.user)
      setSuccess(true)
    }
  }

  if (!user) {
    return null
  }

  return (
    <BaseModal
      onCloseButton={onClose}
      width="460px"
      fillButtons
      buttons={[
        { title: 'Close', type: 'neutral', onClick: onClose },
        {
          title: 'Edit',
          type: 'primary',
          onClick: onEdit,
          loading: editing,
          disabled: !firstName.trim() || !lastName.trim(),
        },
      ]}
    >
      <div className="flex flex-col items-center">
        <div className="relative">
          <img
            src=""
            alt="Profile"
            className="h-26 w-26 rounded-full border border-wove-blue"
          />
          <BaseButton
            className="
              absolute right-0 bottom-0
              w-8 h-8 rounded-full
              bg-wove-blue border-2 border-white
              flex justify-center items-center
            "
          >
            <Pencil />
          </BaseButton>
        </div>
        <div className="h-4" />
        <h3 className="text-xl font-semibold text-black">
          {user.firstName} {user.lastName}
        </h3>
      </div>
      <div className="h-8" />
      <InputPrimary
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <div className="h-4" />
      <InputPrimary
        label="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <div className="h-4" />
      <InputPrimary label="Email" type="email" value={user?.email || ''} readOnly />
      {success && (
        <>
          <div className="h-4" />
          <Prompt message="Profile updated successfully!" type="success" />
        </>
      )}
      <div className="h-8" />
    </BaseModal>
  )
}

export default memo(ProfileModal)
