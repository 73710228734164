import React from 'react'

const FieldLabel = ({ className = '', ...props }: React.LabelHTMLAttributes<HTMLLabelElement>) => (
  <label
    className={`text-xs font-semibold leading-4 mb-2.5 inline-block ${className}`}
    {...props}
  />
)

export default FieldLabel
