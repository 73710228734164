import React, { useCallback, useState, useRef, useEffect } from 'react'
import RadioInBlock from '@src/Pages/IntakeForms/Components/RadioInBlock'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'
import InputPrimary from '@src/Components/InputPrimary'
import Header from '@src/Pages/IntakeForms/Components/Header'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import cn from 'classnames'
import DatePicker from '@src/Pages/IntakeForms/Components/DatePicker'
import { useLocation } from 'react-router-dom'
import { CONTAINER_TITLES, LOCAL_STORAGE_KEYS } from '@src/util/constants'
import { ContainerType, ShippingRate } from '@wove/api/types'
import { useNavigate } from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import { RatesPageParams } from '@src/Pages/IntakeForms/RatesPage'
import { zodResolver } from '@hookform/resolvers/zod'
import LocationSelect from '@src/Pages/IntakeForms/IntakeFormPage/LocationSelect'

import PortSelect from '@src/Pages/IntakeForms/IntakeFormPage/PortSelect'
import localStorage, { deserializeState, serializeState } from '@src/util/localStorage'
import { useSearchRatesQuery } from '@wove/api'
import { Location } from '@wove/api/types'
import { z } from 'zod'

const portSchema = z.custom<Location>(() => true)

const intakeFormSchema = z.object({
  departureDateFrom: z.date().nullable(),
  shippingFrom: z.array(z.object({ port: portSchema.nullable() })),
  shippingTo: z.array(z.object({ port: portSchema.nullable() })),
  departureDateTo: z.date().nullable(),
  commodity: z.string(),
  preferredOceanCarrier: z.array(z.any()),
  containers: z.array(
    z.object({
      key: z.union([
        z.literal(ContainerType.C_20ft),
        z.literal(ContainerType.C_40ft),
        z.literal(ContainerType.C_40ftHc),
        z.literal(ContainerType.C_45ft),
        z.literal(ContainerType.C_20ftReefer),
        z.literal(ContainerType.C_40ftReefer),
        z.literal(ContainerType.C_40ftHcReefer),
        z.literal(ContainerType.C_45ftReefer),
      ]),
      enabled: z.boolean(),
      count: z.number().nullable(),
    }),
  ),
  portsOfLoading: z.array(z.object({ port: portSchema.nullable() })),
  portsOfDischarge: z.array(z.object({ port: portSchema.nullable() })),
})

const validationSchema = intakeFormSchema.extend({
  departureDateTo: z.date(),
  departureDateFrom: z.date(),
})

type ValidatedIntakeFormValues = z.infer<typeof validationSchema>

export type IntakeFormValues = z.infer<typeof intakeFormSchema>

function formValuesToRatesRequest({
  formValues,
  shippingFromPort,
  shippingToPort,
}: {
  formValues: ValidatedIntakeFormValues
  shippingFromPort: boolean
  shippingToPort: boolean
}) {
  const origins = formValues.shippingFrom.reduce((acc, port) => {
    if (port.port) {
      acc.push(port.port.code)
    }
    return acc
  }, [] as string[])
  const destinations = formValues.shippingTo.reduce((acc, port) => {
    if (port.port) {
      acc.push(port.port.code)
    }
    return acc
  }, [] as string[])
  const portsOfLoading = shippingFromPort
    ? []
    : formValues.portsOfLoading.reduce((acc, port) => {
        if (port.port) {
          acc.push(port.port.code)
        }
        return acc
      }, [] as string[])
  const portsOfDischarge = shippingToPort
    ? []
    : formValues.portsOfDischarge.reduce((acc, port) => {
        if (port.port) {
          acc.push(port.port.code)
        }
        return acc
      }, [] as string[])
  const containers = formValues.containers.reduce((acc, container) => {
    if (container.count && container.enabled) {
      acc.push({
        type: container.key,
        numberRequired: container.count,
      })
    }
    return acc
  }, [] as { type: ContainerType; numberRequired: number }[])
  return {
    commodityType: formValues.commodity,
    containers,
    dateFrom: formValues.departureDateFrom?.toISOString(),
    dateTo: formValues.departureDateTo?.toISOString(),
    destinations,
    origins,
    portsOfLoading,
    portsOfDischarge,
  }
}
const IntakeFormPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state
  const dateToRef = useRef<ReactDatePicker>()

  const shippingSelects = React.useRef<{ [key: string]: HTMLInputElement }>({})
  const defaultValues = {
    shippingFrom: [{ port: null }],
    departureDateFrom: null,
    shippingTo: [{ port: null }],
    departureDateTo: null,
    commodity: '',
    preferredOceanCarrier: [],
    containers: [
      { key: ContainerType.C_20ft, enabled: false, count: null },
      { key: ContainerType.C_40ft, enabled: false, count: null },
      { key: ContainerType.C_40ftHc, enabled: false, count: null },
      { key: ContainerType.C_45ft, enabled: false, count: null },
    ],
    portsOfLoading: [{ port: null }],
    portsOfDischarge: [{ port: null }],
  }
  const localStorageState = localStorage.getItem(LOCAL_STORAGE_KEYS.INTAKE_FORM)
  const { register, handleSubmit, watch, control, setValue, formState, reset } =
    useForm<IntakeFormValues>({
      defaultValues,
      resolver: zodResolver(validationSchema),
    })
  const { errors } = formState
  const watchDepartureDateFrom = watch('departureDateFrom')
  const watchDepartureDateTo = watch('departureDateTo')
  const [reeferSelected, setReeferSelected] = useState(false)
  const [shippingFromPort, setShippingFromPort] = useState(true)
  const [shippingToPort, setShippingToPort] = useState(true)
  // const [containerLoadType, setContainerLoadType] = useState<'fcl' | 'lcl'>('fcl').
  const [searchInput, setSearchInput] = React.useState<{
    input: ReturnType<typeof formValuesToRatesRequest>
  } | null>(null)
  const [{ data: searchData, fetching }] = useSearchRatesQuery(
    searchInput || { input: { commodityType: '', containers: [], origins: [], destinations: [] } }
  )
  // const { fields: carrierFields, append: appendCarrier } = useFieldArray({
  //   control,
  //   name: 'preferredOceanCarrier',
  //   rules: {
  //     validate: (value) => {
  //       return value.filter((container) => container.enabled && container.count).length > 0
  //     },
  //   },
  // })
  const { update: updateContainer } = useFieldArray({
    control,
    name: 'containers',
    rules: {
      validate: (value) => {
        return value.filter((container) => container.enabled && container.count).length > 0
      },
    },
  })
  const watchContainerFields = watch('containers')
  const {
    append: appendPortOfLoading,
    update: updatePortOfLoading,
    remove: removePortOfLoading,
  } = useFieldArray({
    control,
    name: 'portsOfLoading',
  })
  const watchPortsOfLoadingFields = watch('portsOfLoading')
  const {
    append: appendPortOfDischarge,
    update: updatePortOfDischarge,
    remove: removePortOfDischarge,
  } = useFieldArray({
    control,
    name: 'portsOfDischarge',
  })
  const watchPortsOfDischargeFields = watch('portsOfDischarge')
  const {
    append: appendShippingFrom,
    update: updateShippingFrom,
    remove: removeShippingFrom,
  } = useFieldArray({
    control,
    name: 'shippingFrom',
    rules: {
      validate: (value) => {
        return shippingToPort ? value.filter(({ port }) => port).length > 0 : !!value[0]
      },
    },
  })
  const watchShippingFromFields = watch('shippingFrom')
  const {
    append: appendShippingTo,
    update: updateShippingTo,
    remove: removeShippingTo,
  } = useFieldArray({
    control,
    name: 'shippingTo',
    rules: {
      validate: (value) => {
        return shippingToPort ? value.filter(({ port }) => port).length > 0 : !!value[0]
      },
    },
  })
  const watchShippingToFields = watch('shippingTo')

  useEffect(() => {
    window.scrollTo(0, 0)
    if (state?.preloadValues) {
      if (localStorageState) {
        const { reeferSelected, shippingFromPort, shippingToPort, ...values } =
          deserializeState(localStorageState)
        reset(values)
        setReeferSelected(typeof reeferSelected !== 'undefined' ? reeferSelected : false)
        setShippingFromPort(typeof shippingFromPort !== 'undefined' ? shippingFromPort : true)
        setShippingToPort(typeof shippingToPort !== 'undefined' ? shippingToPort : true)
      }
      window.history.replaceState({}, document.title)
    }
  }, [])

  const toggleReeferSelected = (reeferSelected: boolean) => {
    setReeferSelected(reeferSelected)
    if (reeferSelected) {
      setValue('containers', [
        {
          key: ContainerType.C_20ftReefer,
          enabled: watchContainerFields[0].enabled,
          count: watchContainerFields[0].count,
        },
        {
          key: ContainerType.C_40ftReefer,
          enabled: watchContainerFields[1].enabled,
          count: watchContainerFields[1].count,
        },
        {
          key: ContainerType.C_40ftHcReefer,
          enabled: watchContainerFields[2].enabled,
          count: watchContainerFields[2].count,
        },
        {
          key: ContainerType.C_45ftReefer,
          enabled: watchContainerFields[3].enabled,
          count: watchContainerFields[3].count,
        },
      ])
    } else {
      setValue('containers', [
        {
          key: ContainerType.C_20ft,
          enabled: watchContainerFields[0].enabled,
          count: watchContainerFields[0].count,
        },
        {
          key: ContainerType.C_40ft,
          enabled: watchContainerFields[1].enabled,
          count: watchContainerFields[1].count,
        },
        {
          key: ContainerType.C_40ftHc,
          enabled: watchContainerFields[2].enabled,
          count: watchContainerFields[2].count,
        },
        {
          key: ContainerType.C_45ft,
          enabled: watchContainerFields[3].enabled,
          count: watchContainerFields[3].count,
        },
      ])
    }
  }

  const onSubmit = useCallback(
    async (values: IntakeFormValues) => {
      try {
        const input = formValuesToRatesRequest({
          formValues: values as ValidatedIntakeFormValues,
          shippingFromPort,
          shippingToPort,
        })
        setSearchInput({ input })
        if (searchData) {
          const params: RatesPageParams = {
            rates: searchData.searchRates as ShippingRate[],
            values,
            reeferSelected,
            // displayInfo: {
            //   shippingFrom: values.shippingFrom[0].port!,
            //   shippingTo: values.shippingTo[0].port!,
            //   departureDateFrom: values.departureDateFrom!,
            //   departureDateTo: values.departureDateTo!,
            //   loadType: 'FCL',
            //   commodity: values.commodity,
            //   containers: values.containers
            //     .filter((container) => container.count && container.enabled)
            //     .map((container) => ({
            //       reefer: reeferSelected,
            //       type: container.key,
            //       numberRequired: container.count,
            //     })),
            // },
          }
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.INTAKE_FORM,
            serializeState({
              ...values,
              reeferSelected,
              shippingFromPort,
              shippingToPort,
            }),
          )
          navigate('/rates', { state: params })
        }
      } catch (error) {
        console.log('Error:', error)
      }
    },
    [navigate, searchData, shippingFromPort, shippingToPort, reeferSelected],
  )

  return (
    <div className="flex flex-col items-center w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full bg-white rounded-lg shadow-sm">
          <Header title="Rate Query" />
          {/*<RecentSearches />*/}
          <div className="container wove-container mx-auto py-10">
            <div className="pb-8">
              <div className="text-lg font-medium mb-4">Where are you shipping from?</div>
              <div className="grid grid-cols-2 gap-3">
                <RadioInBlock
                  itemTitle="Port"
                  checked={shippingFromPort}
                  onChange={() => setShippingFromPort(true)}
                />
                <RadioInBlock
                  itemTitle="Inland Location"
                  checked={!shippingFromPort}
                  onChange={() => setShippingFromPort(false)}
                />
              </div>
            </div>
            <div className="pb-8">
              {shippingFromPort ? (
                <PortSelect
                  error={!!errors.shippingFrom}
                  fields={watchShippingFromFields}
                  add={() => appendShippingFrom({ port: null })}
                  remove={removeShippingFrom}
                  onChange={(index, location) =>
                    location && updateShippingFrom(index, { port: location })
                  }
                />
              ) : (
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="shippingFrom.0.port"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <>
                      <LocationSelect
                        className={cn({ 'wove-form-error': errors.shippingFrom?.[0]?.port })}
                        placeholder="Search for an inland location"
                        isPort={false}
                        selectRef={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        isClearable
                      />
                    </>
                  )}
                />
              )}
            </div>
            {!shippingFromPort && (
              <div className="pb-8">
                <PortSelect
                  fields={watchPortsOfLoadingFields}
                  add={() => appendPortOfLoading({ port: null })}
                  remove={removePortOfLoading}
                  onChange={(index, location) =>
                    location && updatePortOfLoading(index, { port: location })
                  }
                  title="What ports can your containers be loaded at?"
                  allowedEmpty
                />
              </div>
            )}
            <div className="pb-8">
              <div className="text-lg font-medium mb-4">Where are you shipping to?</div>
              <div className="grid grid-cols-2 gap-3">
                <RadioInBlock
                  itemTitle="Port"
                  checked={shippingToPort}
                  onChange={() => setShippingToPort(true)}
                />
                <RadioInBlock
                  itemTitle="Inland Location"
                  checked={!shippingToPort}
                  onChange={() => setShippingToPort(false)}
                />
              </div>
            </div>
            <div className="pb-8">
              {shippingToPort ? (
                <PortSelect
                  error={!!errors.shippingTo}
                  fields={watchShippingToFields}
                  add={() => appendShippingTo({ port: null })}
                  remove={removeShippingTo}
                  onChange={(index, location) =>
                    location && updateShippingTo(index, { port: location })
                  }
                />
              ) : (
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="shippingTo.0.port"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <>
                      <LocationSelect
                        className={cn({ 'wove-form-error': errors.shippingTo?.[0]?.port })}
                        placeholder="Search for an inland location"
                        isPort={shippingToPort}
                        selectRef={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        isClearable
                      />
                    </>
                  )}
                />
              )}
            </div>
            {!shippingToPort && (
              <div className="pb-8">
                <PortSelect
                  fields={watchPortsOfDischargeFields}
                  add={() => appendPortOfDischarge({ port: null })}
                  remove={removePortOfDischarge}
                  onChange={(index, location) => updatePortOfDischarge(index, { port: location })}
                  title="What ports can your containers be discharged at?"
                  allowedEmpty
                />
              </div>
            )}
            <div className="pb-8">
              <div className="text-lg font-medium mb-4">What is the shipment departure date?</div>
              <div className="grid grid-cols-2 gap-3">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="departureDateFrom"
                  render={({ field: { onChange, onBlur, value, ref: controllerRef } }) => (
                    <DatePicker
                      placeholderText="Date From"
                      className={cn({ 'wove-form-error': errors.departureDateFrom })}
                      onChange={(value) => {
                        onChange(value)
                        if (!watchDepartureDateTo) {
                          setValue('departureDateTo', value)
                        }
                        dateToRef.current?.setFocus()
                      }}
                      onBlur={onBlur}
                      selected={value}
                      ref={(ref) => {
                        controllerRef({
                          focus: ref?.setFocus,
                        })
                      }}
                    />
                  )}
                />
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="departureDateTo"
                  render={({ field: { onChange, onBlur, value, ref: controllerRef } }) => (
                    <DatePicker
                      placeholderText="Date To"
                      className={cn({ 'wove-form-error': errors.departureDateTo })}
                      onChange={onChange}
                      minDate={watchDepartureDateFrom || null}
                      onBlur={onBlur}
                      selected={value}
                      ref={(ref) => {
                        if (ref) {
                          dateToRef.current = ref
                        }
                        controllerRef({
                          focus: ref?.setFocus,
                        })
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="pb-8">
              <div className="text-lg font-medium mb-4">
                What types of containers are you shipping and how many?
              </div>
              <div className="grid grid-cols-2 gap-3 pb-3">
                {/*<RadioInBlock*/}
                {/*  itemTitle={*/}
                {/*    <>*/}
                {/*      LCL <SubText>(Less than Container Load)</SubText>*/}
                {/*    </>*/}
                {/*  }*/}
                {/*  name="lcl"*/}
                {/*  ref={ref}*/}
                {/*  onChange={() => onChange('lcl')}*/}
                {/*  onBlur={onBlur}*/}
                {/*  checked={value === 'lcl'}*/}
                {/*/>*/}
                {/*<RadioInBlock*/}
                {/*    itemTitle={*/}
                {/*      <>*/}
                {/*        FCL <SubText>(Full Container Load)</SubText>*/}
                {/*      </>*/}
                {/*    }*/}
                {/*    name="fcl"*/}
                {/*    onChange={() => setContainerLoadType('fcl')}*/}
                {/*    checked={containerLoadType === 'fcl'}*/}
                {/*/>*/}
                <RadioInBlock
                  itemTitle={<>Dry Containers</>}
                  onChange={() => toggleReeferSelected(false)}
                  checked={!reeferSelected}
                />
                <RadioInBlock
                  itemTitle={<>Reefer Containers</>}
                  onChange={() => toggleReeferSelected(true)}
                  checked={reeferSelected}
                />
              </div>
              {watchContainerFields.map((field, index) => {
                return (
                  <div key={field.key} className="grid grid-cols-2 gap-3 pb-3">
                    <RadioInBlock
                      itemTitle={CONTAINER_TITLES[field.key]}
                      type="checkbox"
                      checked={field.enabled}
                      onChange={(checked) => {
                        const newState = {
                          ...field,
                          enabled: checked,
                        }
                        if (checked) {
                          if (!newState.count) {
                            newState.count = 1
                          }
                        } else {
                          newState.count = null
                        }

                        updateContainer(index, newState)
                        setTimeout(() => {
                          if (shippingSelects.current[index]) {
                            shippingSelects.current[index].focus()
                            shippingSelects.current[index].select()
                          }
                        }, 0)
                      }}
                    />
                    <input
                      className="min-h-[50px] relative bg-white border border-[rgba(90,97,115,0.1)] rounded-lg text-sm text-[#141414] px-3 disabled:opacity-50"
                      placeholder="Number of container types"
                      disabled={!field.enabled}
                      ref={(select: HTMLInputElement) => {
                        shippingSelects.current[index] = select
                      }}
                      type="number"
                      min={0}
                      value={field.count || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateContainer(index, {
                          ...field,
                          count: Number(e.target.value),
                        })
                      }
                    />
                  </div>
                )
              })}
              {errors.containers && (
                <div className="text-lg font-medium mb-4 text-red-500">
                  Please select at least one container
                </div>
              )}
            </div>
            <div className="pb-8">
              <div className="text-lg font-medium mb-4">What commodity are you shipping?</div>
              <div>
                <InputPrimary
                  placeholder="Type a commodity"
                  {...register('commodity')}
                  className={cn({ 'wove-form-error': errors.commodity })}
                />
              </div>
            </div>
            {/*<div>*/}
            {/*  <div className="flex items-center justify-between">*/}
            {/*    <FormLabel>Preferred Ocean Carrier</FormLabel>*/}
            {/*    <AddCarrierButton onClick={onAddCarrier}>*/}
            {/*      <img src={plusIcon} alt="blue plus" />*/}
            {/*      Add Carrier*/}
            {/*    </AddCarrierButton>*/}
            {/*  </div>*/}
            {/*  {carrierFields.length > 0 && (*/}
            {/*    <Carriers>*/}
            {/*      {carrierFields.map((field, index.ts) => (*/}
            {/*        <OceanCarrier key={index.ts}>*/}
            {/*          <div className="flex-1">*/}
            {/*            <Select />*/}
            {/*          </div>*/}
            {/*          <RemoveRowButton>*/}
            {/*            <img src={trashRed} alt="red trash can" />*/}
            {/*          </RemoveRowButton>*/}
            {/*        </OceanCarrier>*/}
            {/*      ))}*/}
            {/*    </Carriers>*/}
            {/*  )}*/}
            {/*</div>*/}
          </div>
        </div>
        <div className="w-full bg-white shadow-sm py-4 mt-4">
          <div className="container wove-container mx-auto flex justify-end">
            <ButtonPrimary type="submit" disabled={fetching}>
              Check Rates
            </ButtonPrimary>
          </div>
        </div>
      </form>
    </div>
  )
}

export default IntakeFormPage
