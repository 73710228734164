/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type Carrier = {
  __typename?: 'Carrier';
  abbreviation: Scalars['String']['output'];
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ContainerRate = {
  __typename?: 'ContainerRate';
  basePrice: Price;
  pricePerContainer: Price;
  quantity: Scalars['Float']['output'];
  totalPrice: Price;
  type: ContainerType;
};

/** Container Type */
export enum ContainerType {
  C_20ft = 'c_20ft',
  C_20ftReefer = 'c_20ft_reefer',
  C_40ft = 'c_40ft',
  C_40ftHc = 'c_40ft_hc',
  C_40ftHcReefer = 'c_40ft_hc_reefer',
  C_40ftReefer = 'c_40ft_reefer',
  C_45ft = 'c_45ft',
  C_45ftReefer = 'c_45ft_reefer'
}

export type CreateUserDto = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
};

export type CurrentSessionResponse = {
  __typename?: 'CurrentSessionResponse';
  customer?: Maybe<Customer>;
  onboardingStep?: Maybe<Scalars['String']['output']>;
  user: User;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['String']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type DeactivateUserResponse = {
  __typename?: 'DeactivateUserResponse';
  user: UserWithRole;
};

export type GetPresignedUrlForSourceUploadResponse = {
  __typename?: 'GetPresignedUrlForSourceUploadResponse';
  expireInSeconds: Scalars['Int']['output'];
  expiryTimestamp: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type InviteUserDto = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  roleType: Scalars['String']['input'];
};

export type InviteUsersDto = {
  users: Array<InviteUserDto>;
};

export type InviteUsersResponse = {
  __typename?: 'InviteUsersResponse';
  users: Array<User>;
};

export type Location = {
  __typename?: 'Location';
  code: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  customer?: Maybe<Customer>;
  tokens: TokenResponse;
  user: User;
};

export type LoginUserDto = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Auth via token */
  authUsingToken: LoginResponse;
  /** Create a new tag */
  createTag: Tag;
  /** Deactivate user */
  deactivateUser: DeactivateUserResponse;
  /** Delete source */
  deleteSource: ResponseSuccess;
  /** Delete a tag */
  deleteTag: ResponseSuccess;
  /** Get presigned URL for source upload */
  getPresignedUrlForSourceUpload: GetPresignedUrlForSourceUploadResponse;
  /** Invite users */
  inviteUsers: InviteUsersResponse;
  /** User login */
  login: LoginResponse;
  /** User logout */
  logout: User;
  /** Set company info */
  setCompanyInfo: SetCompanyInfoResponse;
  /** Set user password */
  setPassword: SetPasswordResponse;
  /** Set user profile info */
  setProfileInfo: SetProfileInfoResponse;
  /** User signup */
  signup: User;
  /** Start processing source */
  startProcessingSource: StartProcessingSourceResponse;
};


export type MutationAuthUsingTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationCreateTagArgs = {
  name: Scalars['String']['input'];
};


export type MutationDeactivateUserArgs = {
  userId: Scalars['String']['input'];
};


export type MutationDeleteSourceArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['String']['input'];
};


export type MutationGetPresignedUrlForSourceUploadArgs = {
  name: Scalars['String']['input'];
  tagIds: Array<Scalars['String']['input']>;
};


export type MutationInviteUsersArgs = {
  invitedUsers: InviteUsersDto;
};


export type MutationLoginArgs = {
  login: LoginUserDto;
};


export type MutationSetCompanyInfoArgs = {
  companyInfo: SetCompanyInfoDto;
};


export type MutationSetPasswordArgs = {
  passwordInfo: SetPasswordDto;
};


export type MutationSetProfileInfoArgs = {
  profileInfo: SetProfileInfoDto;
};


export type MutationSignupArgs = {
  userData: CreateUserDto;
};


export type MutationStartProcessingSourceArgs = {
  id: Scalars['String']['input'];
};

export type Price = {
  __typename?: 'Price';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** Get current session */
  currentSession: CurrentSessionResponse;
  /** Get source upload progress */
  getSourceUploadProgress: SourceUploadProgress;
  /** Get all sources */
  getSources: Array<Source>;
  /** Get all tags */
  getTags: Array<Tag>;
  /** User find list */
  getUsers: UsersResponse;
  /** Search locations */
  searchLocations: Array<Location>;
  /** Search Rates */
  searchRates: Array<ShippingRate>;
  /** Search tags */
  searchTags: Array<Tag>;
};


export type QueryGetSourceUploadProgressArgs = {
  id: Scalars['String']['input'];
};


export type QuerySearchLocationsArgs = {
  input: SearchLocationsInput;
};


export type QuerySearchRatesArgs = {
  input: SearchRatesInput;
};


export type QuerySearchTagsArgs = {
  query: Scalars['String']['input'];
};

export type RateLineItem = {
  __typename?: 'RateLineItem';
  commodityType: Scalars['String']['output'];
  containers: Array<ContainerRate>;
  description: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  price: Price;
  source?: Maybe<RateSource>;
};

export type RateSource = {
  __typename?: 'RateSource';
  contract: Scalars['String']['output'];
  id: Scalars['String']['output'];
  location: SpreadsheetLocation;
};

export type ResponseSuccess = {
  __typename?: 'ResponseSuccess';
  success: Scalars['Boolean']['output'];
};

export type SearchLocationsInput = {
  isPort: Scalars['Boolean']['input'];
  query: Scalars['String']['input'];
};

export type SearchRatesContainerInput = {
  numberRequired: Scalars['Float']['input'];
  type: ContainerType;
};

export type SearchRatesInput = {
  commodityType: Scalars['String']['input'];
  containers: Array<SearchRatesContainerInput>;
  dateFrom: Scalars['String']['input'];
  dateTo: Scalars['String']['input'];
  destination?: InputMaybe<Scalars['String']['input']>;
  destinations?: InputMaybe<Array<Scalars['String']['input']>>;
  origin?: InputMaybe<Scalars['String']['input']>;
  origins?: InputMaybe<Array<Scalars['String']['input']>>;
  portsOfDischarge?: InputMaybe<Array<Scalars['String']['input']>>;
  portsOfLoading?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SetCompanyInfoDto = {
  logo?: InputMaybe<Scalars['Upload']['input']>;
  name: Scalars['String']['input'];
};

export type SetCompanyInfoResponse = {
  __typename?: 'SetCompanyInfoResponse';
  customer: Customer;
  user: User;
};

export type SetPasswordDto = {
  oldPassword?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type SetPasswordResponse = {
  __typename?: 'SetPasswordResponse';
  customer?: Maybe<Customer>;
  user: User;
};

export type SetProfileInfoDto = {
  firstName: Scalars['String']['input'];
  jobTitle: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type SetProfileInfoResponse = {
  __typename?: 'SetProfileInfoResponse';
  customer?: Maybe<Customer>;
  user: User;
};

export type ShippingRate = {
  __typename?: 'ShippingRate';
  allInRate: Price;
  carrier: Carrier;
  commodityType: Scalars['String']['output'];
  destination: Location;
  duplicateRates: Array<ShippingRate>;
  lineItems: Array<RateLineItem>;
  origin: Location;
  portOfDischarge?: Maybe<Location>;
  portOfLoading?: Maybe<Location>;
};

export type Source = {
  __typename?: 'Source';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  ref?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  status: SourceStatus;
  tags: Array<Tag>;
  type: Scalars['String']['output'];
};

/** Source Status */
export enum SourceStatus {
  AwaitingUpload = 'awaiting_upload',
  Complete = 'complete',
  Failed = 'failed',
  Pending = 'pending',
  Processing = 'processing'
}

export type SourceUploadProgress = {
  __typename?: 'SourceUploadProgress';
  id: Scalars['String']['output'];
  progressText?: Maybe<Scalars['String']['output']>;
  status: SourceStatus;
};

export type SpreadsheetLocation = {
  __typename?: 'SpreadsheetLocation';
  preview: TableRowPreview;
  row: Scalars['Float']['output'];
  tab: Scalars['String']['output'];
};

export type StartProcessingSourceResponse = {
  __typename?: 'StartProcessingSourceResponse';
  id: Scalars['String']['output'];
  status: SourceStatus;
};

export type TableRowPreview = {
  __typename?: 'TableRowPreview';
  data?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>>;
  header?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>>;
};

export type Tag = {
  __typename?: 'Tag';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TokenResponse = {
  __typename?: 'TokenResponse';
  accessToken: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isVerified: Scalars['Boolean']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type UserWithRole = {
  __typename?: 'UserWithRole';
  deactivatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isVerified: Scalars['Boolean']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  users: Array<UserWithRole>;
};

export type CurrentUserFragment = { __typename?: 'User', id: string, email: string, firstName: string, lastName?: string | null, isVerified: boolean, jobTitle?: string | null } & { ' $fragmentName'?: 'CurrentUserFragment' };

export const CurrentUserFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CurrentUser"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"isVerified"}},{"kind":"Field","name":{"kind":"Name","value":"jobTitle"}}]}}]} as unknown as DocumentNode<CurrentUserFragment, unknown>;