import { ReactNode } from 'react'
import { useAuth } from '@src/hooks/useAuth'
import { Navigate } from 'react-router-dom'

const AdminRoute = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth()

  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />
  }

  // //Check if admin
  // if (false) {
  //   return <Navigate to="/" />
  // }

  return children
}

export default AdminRoute
