import { ShippingRate } from '@wove/api/types'
import RateTable from '@src/Pages/IntakeForms/RatesPage/RateTable'
import BaseButton from '@src/Components/Buttons/BaseButton'

const DuplicateRates = ({ rates, onClose }: { rates: ShippingRate[]; onClose: () => void }) => {
  return (
    <td className="mt-1.5 w-auto pt-0 pb-0 mb-5" colSpan={6}>
      <div className="flex justify-between py-4">
        <h5 className="text-[#F2994A] text-base m-0 font-semibold">
          {rates.length} Duplicate Rate{rates.length === 1 ? '' : 's'}
        </h5>
        <BaseButton className="m-0 bg-transparent border-0" onClick={onClose}>
          Close
        </BaseButton>
      </div>
      <RateTable rates={rates} isDuplicate />
    </td>
  )
}

export default DuplicateRates
