import React from 'react'

interface IconTitleSubtitleProps {
  icon: string | React.ReactNode
  title: string
  subtitle: string
  iconAlt?: string
  className?: string
}

const IconTitleSubtitle = ({ icon, title, subtitle, iconAlt, className = '' }: IconTitleSubtitleProps) => {
  return (
    <div className={`flex ${className}`}>
      {typeof icon === 'string' ? <img src={icon} alt={iconAlt} /> : icon}
      <div className="flex-1 pl-5">
        <p className="text-xl text-black font-medium">{title}</p>
        <div className="h-1" />
        <p className="text-sm text-black/50 font-medium leading-[18px]">{subtitle}</p>
      </div>
    </div>
  )
}

export default IconTitleSubtitle
