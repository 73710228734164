import { graphql } from '@src/gql'

export const CurrentUserFragment = graphql(`
  fragment CurrentUser on User {
    id
    email
    firstName
    lastName
    isVerified
    jobTitle
  }
`)
