import React, { ReactNode, useEffect, PropsWithChildren } from 'react'
import ButtonNeutral from '@src/Components/Buttons/ButtonNeutral'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'
import ButtonSecondary from '@src/Components/Buttons/ButtonSecondary'
import CloseButton from '@src/Components/Buttons/CloseButton'
import Spinner from '@src/Components/Spinner'

interface BaseModalProps {
  headerIconComponent?: ReactNode
  headerIcon?: string
  headerIconAlt?: string
  title?: string
  body?: ReactNode
  buttons?: {
    title: string
    type: 'neutral' | 'primary' | 'secondary'
    onClick: () => void
    disabled?: boolean
    loading?: boolean
  }[]
  fillButtons?: boolean
  type?: 'dialog'
  onCloseButton?: () => void
  width?: string
  contentColor?: string
  alignItems?: string
}

const BaseModal = ({
  headerIconComponent,
  headerIcon,
  headerIconAlt,
  title,
  body,
  buttons,
  fillButtons,
  type,
  onCloseButton,
  width = type === 'dialog' ? '324px' : 'auto',
  children,
  contentColor = '#f4f6f8',
  alignItems,
}: PropsWithChildren<BaseModalProps>) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  const content = (
    <>
      {onCloseButton && (
        <CloseButton
          onClick={onCloseButton}
          className="absolute top-[18px] right-[18px]"
        />
      )}
      {headerIconComponent && (
        <div className="mb-8">{headerIconComponent}</div>
      )}
      {headerIcon && <img src={headerIcon} alt={headerIconAlt} className="mb-8" />}
      {title && (
        <h3 className="text-wove-black text-xl font-medium m-0 mb-3 text-center">
          {title}
        </h3>
      )}
      {body && (
        <p className="text-sm font-medium leading-[18px] m-0 mb-8">
          {body}
        </p>
      )}
      {children}
      {buttons && (
        <div 
          className={`
            flex gap-[10px] self-stretch text-center
            ${fillButtons ? 'justify-between' : 'justify-end'}
          `}
        >
          {buttons.map((btn, index) => {
            const Component =
              btn.type === 'neutral'
                ? ButtonNeutral
                : btn.type === 'primary'
                ? ButtonPrimary
                : ButtonSecondary
            return (
              <div 
                key={index} 
                className={fillButtons ? 'flex-1' : ''}
              >
                <Component
                  disabled={btn.disabled || btn.loading}
                  className="w-full"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault()
                    btn.onClick()
                  }}
                >
                  {btn.loading && <Spinner />}
                  {btn.title}
                </Component>
              </div>
            )
          })}
        </div>
      )}
    </>
  )

  return (
    <div className="fixed inset-0 z-[10000] flex justify-center items-center bg-black/60">
      <div
        className={`
          rounded-[14px] shadow-[0px_0px_12px_0px_rgba(0,0,0,0.3)] p-8 max-w-full
          flex flex-col relative
          ${alignItems ? `items-${alignItems}` : ''}
        `}
        style={{
          width,
          background: contentColor
        }}
      >
        {content}
      </div>
    </div>
  )
}

export default BaseModal
