import { graphql } from "../gql";
import { useMutation, useQuery } from "urql";

const createTagMutation = graphql(/* GraphQL */ `
  mutation CreateTag($name: String!) {
    createTag(name: $name) {
      id
      name
      createdAt
    }
  }
`);
export const useCreateTagMutation = () => useMutation(createTagMutation);

const getTagsQuery = graphql(`
  query GetTags {
    getTags {
      id
      name
      createdAt
    }
  }
`);
export const useGetTagsQuery = () => useQuery({ query: getTagsQuery });
