import React from 'react'
import BaseButton from '@src/Components/Buttons/BaseButton'
import Spinner from '../Spinner'

interface ButtonPrimaryProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  $backgroundColor?: string
  $matchWidth?: boolean
  loading?: boolean
}

const ButtonPrimary = React.forwardRef<HTMLButtonElement, ButtonPrimaryProps>(
  ({ $backgroundColor, $matchWidth, className = '', children,  loading, disabled, ...props }, ref) => {
    return (
      <BaseButton
        ref={ref}
        className={`
          h-12 px-8
          ${$backgroundColor ? `bg-[${$backgroundColor}]` : 'bg-wove-blue'}
          rounded-lg text-white
          disabled:bg-[#b9dcfb]
          flex items-center justify-center gap-2.5
          text-sm font-semibold
          ${$matchWidth ? 'w-full' : ''}
          ${className}
        `}
        disabled={disabled || loading}
        {...props}>
          {loading && <Spinner className='mr-2' />}
          {children}
        </BaseButton>
    )
  },
)

export default ButtonPrimary
