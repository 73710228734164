import { useCallback } from 'react'
import ButtonSecondary from '@src/Components/Buttons/ButtonSecondary'
import emailIcon from './assets/email.svg'
import printIcon from './assets/print.svg'
import downloadIcon from './assets/download.svg'

const ActionButtons = ({ onPrint }: { onPrint: () => void }) => {
  const onEmail = useCallback(() => {
    //TODO: Email
  }, [])
  const onDownload = useCallback(() => {
    //   TODO: Download
  }, [])

  return (
    <div className="w-[168px] px-3.5 flex flex-col items-stretch gap-2.5">
      <ButtonSecondary onClick={onEmail}>
        <img src={emailIcon} alt="blue envelope" />
        Email
      </ButtonSecondary>
      <ButtonSecondary onClick={onPrint}>
        <img src={printIcon} alt="blue printer" />
        Print
      </ButtonSecondary>
      <ButtonSecondary onClick={onDownload}>
        <img src={downloadIcon} alt="blue download icon" />
        Download
      </ButtonSecondary>
    </div>
  )
}

export default ActionButtons
