import React from 'react'
import { ReactComponent as ErrorCircle } from './assets/error-circle.svg'

type PromptType = 'error' | 'success'

interface PromptProps {
  type?: PromptType
  icon?: React.ReactNode | string
  message: string
  className?: string
}

const Prompt = ({ type = 'error', icon, message, className = '' }: PromptProps) => {
  let iconShown = icon
  if (!iconShown) {
    if (type === 'error') {
      iconShown = <ErrorCircle />
    }
  }

  const typeClasses = type === 'success'
    ? 'bg-emerald-500/15 text-emerald-500'
    : 'bg-red-500/15 text-red-500'

  return (
    <div>
      <div className={`
        rounded-lg px-2.5 py-2
        inline-flex items-center gap-2.5
        text-xs font-medium leading-[15px]
        ${typeClasses}
        ${className}
      `}>
        {typeof iconShown === 'string' ? <img src={iconShown} alt="" /> : iconShown}
        {message}
      </div>
    </div>
  )
}

export default Prompt
