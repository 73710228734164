import React, { useCallback, useState } from 'react'
import { Space } from '@mantine/core'
import { Instructions, PageTitle } from '@src/Pages/Splash/CommonComponents'
import InputPrimary from '@src/Components/InputPrimary'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'
import { useNavigate } from 'react-router-dom'
import Spinner from '@src/Components/Spinner'
import Prompt from '@src/Components/Prompt'
import { getCombinedErrorMessage } from '@src/util/helpers'
import { useSetPersonalInfoMutation } from '@wove/api'
import useUserStore from '@src/state/user'

const PersonalInfoScreen = () => {
  const setSplashInfo = useUserStore((state) => state.setSplashInfo)
  const user = useUserStore((state) => state.user)
  const [firstName, setFirstName] = useState(user?.firstName || '')
  const [lastName, setLastName] = useState('')
  const [title, setTitle] = useState('')
  const navigate = useNavigate()
  const [{ fetching: submitting, error }, setPersonalInfo] = useSetPersonalInfoMutation()
  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const response = await setPersonalInfo({
        profileInfo: {
          firstName,
          lastName,
          jobTitle: title.trim(),
        },
      })
      if (response.data) {
        const { user, customer } = response.data.setProfileInfo
        if (customer) {
          //Someone else has already set up the company, skip the company setup flow
          setSplashInfo({ user, customer, onboarded: true })
          navigate('/signup-success')
        } else {
          setSplashInfo({ user, customer })
          navigate('/company-info')
        }
      }
    },
    [navigate, setPersonalInfo, setSplashInfo, firstName, lastName, title],
  )

  return (
    <form onSubmit={onSubmit}>
      <PageTitle>Tell us about you</PageTitle>
      <Space h={30} />
      <Instructions>We just need some basic information about you</Instructions>
      <Space h={30} />
      <InputPrimary
        placeholder="i.e. Johnny"
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <Space h={30} />
      <InputPrimary
        autoFocus
        placeholder="i.e. Smith"
        label="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <Space h={20} />
      <InputPrimary
        placeholder="What do you do?"
        label="Your Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <Space h={20} />
      {error && (
        <>
          <Prompt message={getCombinedErrorMessage(error)} />
          <Space h={20} />
        </>
      )}
      <ButtonPrimary
        disabled={
          submitting ||
          firstName.trim().length === 0 ||
          lastName.trim().length === 0 ||
          title.trim().length === 0
        }
        $matchWidth
        type="submit"
      >
        {submitting && <Spinner />}
        Continue
      </ButtonPrimary>
      <Space h={25} />
    </form>
  )
}

export default PersonalInfoScreen
