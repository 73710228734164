import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'

const NoRates = () => {
  return (
    <div className="text-center flex flex-col items-center text-[#666666] mt-5 rounded-[10px] border border-[rgba(90,97,115,0.1)] bg-[rgba(90,97,115,0.1)] p-[60px]">
      <h3 className="text-2xl leading-[30px] mb-5 m-0">Sorry, we searched far and wide but no results for your search were found.</h3>
      <p className="text-sm leading-[18px] mb-5 m-0">To request this rate, click on the button below</p>
      <ButtonPrimary>Request Rate</ButtonPrimary>
    </div>
  )
}

export default NoRates
