import { useMemo, useCallback } from 'react'
import { Source, SourceStatus } from '@wove/api/types'
import { format } from 'date-fns'
import doneIcon from './assets/status/completed.svg'
import errorIcon from './assets/status/error.svg'
// import pausedIcon from './assets/status/paused.svg'
import progressIcon from './assets/status/progress.svg'
import threeDots from './assets/three-dots.svg'
import { useMutation } from 'urql'
import { MenuItem, SubMenu } from '@szhsin/react-menu'
import Menu from '@src/Components/Menu'
// import { Menu } from '@szhsin/react-menu'
import { useRestApi } from '@wove/api'
import Tag from '@src/Components/Tag'
import { useAuth } from '@src/hooks/useAuth'

const deleteSourceMutation = `
  mutation DeleteSource($deleteSourceId: String!) {
      deleteSource(id: $deleteSourceId) {
        __typename # "Source"
        success
      }
    }
`

interface ExportOption {
  label: string
  format: 'original' | 'gpm' | 'freightos' | 'freightos-gpm' | 'explorate' | 'winmore-gpm'
}

interface SourceRowProps {
  source: Source
}

function extractDisposition(responseHeaders: any): string {
  try {
    const filenamePattern = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenamePattern.exec(responseHeaders['content-disposition'])

    if (matches && matches[1]) {
      // Extracted filename
      const filename = matches[1].replace(/['"]/g, '') // Remove any surrounding quotes
      return filename
    } else {
      return 'data.xlsx'
    }
  } catch (error) {
    return 'data.xlsx'
  }
}

const SourceRow = ({ source }: SourceRowProps) => {
  const [_result, executeDelete] = useMutation(deleteSourceMutation)
  const sourceSizeText = useMemo(() => {
    if (typeof source.size !== 'number') {
      return ''
    }

    if (source.size > 1000 * 1000) {
      return `${(source.size / 1000 / 1000).toFixed(2)} Mb`
    } else if (source.size > 1000) {
      return `${(source.size / 1000).toFixed(2)} Kb`
    }
    return `${source.size} b`
  }, [source.size])
  const restApi = useRestApi()
  const { user, customer } = useAuth()

  const exportData = useCallback(
    async (
      format: 'original' | 'gpm' | 'freightos' | 'freightos-gpm' | 'explorate' | 'winmore-gpm',
    ) => {
      try {
        const response = await restApi.get(`/api/v1/sources/${source.id}/export/${format}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
          withCredentials: true,
        })
        const url = window.URL.createObjectURL(response.data) // Create a Blob URL for the response data
        const link = document.createElement('a') // Create a link element
        link.href = url // Set the href attribute to the Blob URL
        link.setAttribute('download', extractDisposition(response.headers)) // Set the download attribute to specify the filename
        document.body.appendChild(link) // Append the link to the document body
        link.click() // Trigger the click event on the link to start the download
        window.URL.revokeObjectURL(url) // Release the Blob URL after the download is complete
      } catch (error) {
        //TODO: Show an error
      }
    },
    [restApi, source.id],
  )

  if (!user || !customer) {
    return null
  }

  const exportOptions: ExportOption[] = [{ label: 'Export original', format: 'original' }]

  const allExportOptions: ExportOption[] = [
    { label: 'Export data to GPM', format: 'gpm' },
    { label: 'Export data to Winmore (GPM)', format: 'winmore-gpm' },
    { label: 'Export data to Explorate', format: 'explorate' },
  ]

  const exportFormats = customer?.rateIngestion?.exportFormats || []
  const isAllExport = exportFormats.length === 1 && exportFormats[0] === 'all'

  if (isAllExport) {
    exportOptions.push(...allExportOptions)
  } else {
    exportOptions.push(
      ...allExportOptions.filter((option) => exportFormats.includes(option.format)),
    )
  }

  return (
    <div className="flex mb-2.5 py-3.5 items-center rounded-lg border border-[rgba(70,70,70,0.1)]">
      <div className="w-[66px] flex-shrink-0 flex justify-center">
        <img
          src={
            source.status === SourceStatus.Complete
              ? doneIcon
              : source.status === SourceStatus.Failed
              ? errorIcon
              : progressIcon
          }
        />
      </div>
      <div className="flex-[2_1_0px] text-sm font-semibold leading-[18px] text-[#141414]">
        {source.name}
      </div>
      <div className="flex-[1_1_0px] text-xs font-semibold leading-[18px] text-[#9399a3]">N/A</div>
      <div className="flex-[1_1_0px] text-xs font-semibold leading-[18px] text-[#9399a3]">
        {source.type}
      </div>
      <div className="flex-[1_1_0px] flex gap-2 flex-wrap text-xs font-semibold leading-[18px] text-[#9399a3]">
        {source.tags.map((tag) => (
          <Tag tag={tag} key={tag.id} color="#fff" />
        ))}
      </div>
      <div className="flex-[1_1_0px] text-xs font-semibold leading-[18px] text-[#9399a3]">
        {sourceSizeText}
      </div>
      <div className="flex-[1_1_0px] text-xs font-semibold leading-[18px] text-[#9399a3]">
        {format(new Date(source.createdAt), 'MMM do, yyyy')}
      </div>
      {/*<Cell $flex={1} $black>*/}
      {/*  John Smith*/}
      {/*</Cell>*/}
      <div className="w-12 flex-shrink-0">
        <Menu
          menuButton={
            <button className="bg-transparent border-none p-2 cursor-pointer hover:bg-gray-50 rounded">
              <img src={threeDots} alt="three grey dots" />
            </button>
          }
        >
          <SubMenu label="Export Data">
            {exportOptions.map((option, index) => (
              <MenuItem onClick={() => exportData(option.format)} key={index}>
                {option.label}
              </MenuItem>
            ))}
          </SubMenu>
          <MenuItem
            className="text-[#ED1C24]"
            onClick={() => {
              executeDelete({ deleteSourceId: source.id })
            }}
          >
            Delete Source
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export default SourceRow
