import React from 'react'
import BaseButton from '@src/Components/Buttons/BaseButton'

const ButtonSecondary = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className = '', ...props }, ref) => {
  return (
    <BaseButton
      ref={ref}
      className={`
        h-12 px-8.5
        bg-white text-wove-blue
        border border-neutral-600/30
        rounded-lg
        disabled:bg-wove-grey
        flex items-center justify-center gap-2.5
        text-sm font-semibold
        ${className}
      `}
      {...props}
    />
  )
})

export default ButtonSecondary
