import { Fragment } from 'react'
import BaseModal from '@src/Components/BaseModal'
import { SourceStatus, SourceUploadProgress } from '@wove/api/types'
import uploading from './assets/modalStatus/uploading.svg'
import done from './assets/modalStatus/done.svg'
import errorIcon from './assets/modalStatus/error.svg'
import Spinner from '@src/Components/Spinner'

interface UploadStatusModalProps {
  isOpen: boolean
  status: SourceUploadProgress | null
  onClose: () => void
  onViewFile: () => void
  onMinimize: () => void
}

const UploadStatusModal = ({
  isOpen,
  status: uploadProgress,
  onClose,
  onViewFile,
  onMinimize,
}: UploadStatusModalProps) => {
  if (!isOpen || !uploadProgress) {
    return null
  }
  const { status, progressText } = uploadProgress
  const isComplete = status === SourceStatus.Complete
  const isError = status === SourceStatus.Failed
  const headerIconProps = () => {
    if (!status) {
      return {
        headerIcon: uploading,
      }
    }
    if (isComplete) {
      return {
        headerIcon: done,
      }
    }
    if (isError) {
      return {
        headerIcon: errorIcon,
      }
    }
    return {
      headerIconComponent: (
        <div className="w-16 h-16 rounded-full bg-wove-orange flex justify-center items-center">
          <Spinner />
        </div>
      ),
    }
  }

  return (
    <BaseModal
      alignItems="center"
      type="dialog"
      {...headerIconProps()}
      body={
        <>
          {progressText
            ? progressText.split('\n').map((text, index) => {
                return (
                  <Fragment key={index}>
                    {text}
                    <br />
                  </Fragment>
                )
              })
            : ''}
        </>
      }
      title={
        !status
          ? 'Uploading'
          : isComplete
          ? 'Done!'
          : isError
          ? 'Error'
          : status === SourceStatus.Pending
          ? 'Queued'
          : 'In progress'
      }
      fillButtons
      buttons={[
        {
          title: isComplete ? 'View file' : 'Cancel',
          type: 'primary',
          onClick: isComplete ? onViewFile : onClose,
        },
        ...(isComplete
          ? []
          : [
              {
                title: 'Minimize',
                type: 'neutral' as const,
                onClick: onMinimize,
              },
            ]),
      ]}
    />
  )
}

export default UploadStatusModal
