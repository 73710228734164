import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'rc-tooltip/assets/bootstrap_white.css'
import setupLogRocketReact from 'logrocket-react'
import LogRocket from 'logrocket'

if (import.meta.env.PROD) {
  LogRocket.init('wove/wove-production')
  setupLogRocketReact(LogRocket)
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
