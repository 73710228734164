import React, { useCallback, useState } from 'react'
import InputPrimary from '@src/Components/InputPrimary'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'
import { ReactComponent as SearchIcon } from './assets/search.svg'
import InviteUserModal from '@src/Components/InviteUserModal'

const HeaderControls = ({
  searchText,
  setSearchText,
}: {
  searchText: string
  setSearchText: (text: string) => void
}) => {
  const [inviteUserVisible, setInviteUsersVisible] = useState(false)
  const onInviteClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setInviteUsersVisible(true)
  }, [])
  const onInviteClose = useCallback(() => {
    setInviteUsersVisible(false)
  }, [])

  return (
    <div className="flex gap-5">
      <div className="flex-[1_1_0]">
        <InputPrimary
          placeholder="Search..."
          iconLeft={<SearchIcon />}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <ButtonPrimary onClick={onInviteClick}>Invite Users</ButtonPrimary>
      {inviteUserVisible && <InviteUserModal onClose={onInviteClose} />}
    </div>
  )
}

export default HeaderControls
