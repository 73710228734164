import { graphql } from "../gql";
import { useQuery, useMutation } from "urql";

export const getSourcesQuery = graphql(/* GraphQL */ `
  query GetSources {
    getSources {
      id
      name
      ref
      size
      status
      type
      isEnabled
      createdAt
      tags {
        id
        name
        createdAt
      }
    }
  }
`);
export const useGetSourcesQuery = () => useQuery({ query: getSourcesQuery });

export const getSourceUploadProgressQuery = graphql(/* GraphQL */ `
  query GetSourceUploadProgress($getSourceUploadProgressId: String!) {
    getSourceUploadProgress(id: $getSourceUploadProgressId) {
      id
      progressText
      status
    }
  }
`);
export const useGetSourceUploadProgressQuery = (variables: { getSourceUploadProgressId: string }) => 
  useQuery({ query: getSourceUploadProgressQuery, variables });

const getPresignedUrlMutation = graphql(`
  mutation GetPresignedUrlForSourceUpload($name: String!, $tagIds: [String!]!) {
    getPresignedUrlForSourceUpload(name: $name, tagIds: $tagIds) {
      id
      url
      expiryTimestamp
      expireInSeconds
    }
  }
`);
export const useGetPresignedUrlMutation = () => useMutation(getPresignedUrlMutation);

const startProcessingSourceMutation = graphql(`
  mutation StartProcessingSource($options: UploadOptionsDto!, $startProcessingSourceId: String!) {
    startProcessingSource(options: $options, id: $startProcessingSourceId) {
      id
      status
      
    }
  }
`);
export const useStartProcessingSourceMutation = () => useMutation(startProcessingSourceMutation);

const requestRateIngestionMutation = graphql(`
  mutation RequestRateIngestion($types: [String!]!) {
    requestRateIngestion(types: $types) {
      success
    }
  }
`);
export const useRequestRateIngestionMutation = () => useMutation(requestRateIngestionMutation);
