import { useDropzone } from 'react-dropzone'
import { useCallback, useRef, useState } from 'react'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'
import fileIcon from './assets/file.svg'
import axios from 'axios'
import { Mutation, SourceStatus, SourceUploadProgress } from '@wove/api/types'
import Header from '@src/Pages/IntakeForms/Components/Header'
import SourceTable from '@src/Pages/UploadPage/SourceTable'
import {
  useGetPresignedUrlMutation,
  useGetSourcesQuery,
  useStartProcessingSourceMutation,
} from '@wove/api'
import UploadStatusModal from '@src/Pages/UploadPage/UploadStatusModal'
import useUploadPolling from '@src/Pages/UploadPage/useUploadPolling'
import UploadStatusOverlay from '@src/Pages/UploadPage/UploadStatusOverlay'
import UpdateFillInfoModal from '@src/Pages/UploadPage/UploadFillInfoModal'
import { getSourceUploadProgressQuery } from '@wove/api'
import { useClient } from 'urql'
import { useAuth } from '@src/hooks/useAuth'
import RequestAccessModal from '@src/Pages/UploadPage/RequestAccessModal'

const UploadPage = () => {
  const { customer } = useAuth()
  const [requestAccessOpen, setRequestAccessOpen] = useState(false)
  const [{ fetching: executingUploadMutation }, executeMutation] = useGetPresignedUrlMutation()
  const [{ fetching: executingProcessingMutation }, executeProcessingMutation] =
    useStartProcessingSourceMutation()
  const [queryData, refetchSources] = useGetSourcesQuery()
  const [uploadModalProgress, setUploadModalProgress] = useState<SourceUploadProgress | null>(null)
  const [selectedFile, setSelectedFile] = useState<null | File>(null)
  const [uploadModalId, setUploadModalId] = useState<string | null>(null)
  const [appendingFile, setAppendingFile] = useState(false)
  const {
    sourceStatuses,
    pollTimeouts,
    removeSourceStatus,
    addPollingTimeout,
    removePollingTimeout,
    setSourceStatus,
    addCompletedSource,
    completedSources,
  } = useUploadPolling()
  const shouldBeginPolling = useRef(false)

  const pollSourceStatus = async (status: SourceStatus, id: string, fileName: string) => {
    const pollTimeout = pollTimeouts.current[id]
    if (status === SourceStatus.Complete) {
      //Complete
      if (pollTimeout) {
        removePollingTimeout(id)
        addCompletedSource(id)
      }
      if (status === SourceStatus.Complete) {
        refetchSources({ requestPolicy: 'network-only' })
      }
    } else {
      addPollingTimeout(
        id,
        setTimeout(async () => {
          const response = await client
            .query(
              getSourceUploadProgressQuery,
              {
                getSourceUploadProgressId: id,
              },
              {
                requestPolicy: 'network-only',
              },
            )
            .toPromise()
          if (response.data) {
            const { getSourceUploadProgress } = response.data
            setSourceStatus(id, {
              progress: getSourceUploadProgress,
              fileName,
            })
            // setSourceStatus(getSourceUploadProgress.status)
            // setSourceStatusText(getSourceUploadProgress.progressText || '')
            pollSourceStatus(getSourceUploadProgress.status, id, fileName)
          }
        }, 500),
      )
    }
  }

  const onOpenSource = useCallback((sourceId: string) => {
    setUploadModalId(sourceId)
  }, [])

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    if (file) {
      setSelectedFile(file)
    }
  }

  const onBeginProcessing = useCallback(
    async ({
      file,
      tagIds,
      effectiveDate,
      expiryDate,
      carrier,
    }: {
      file: File
      description: string
      tagIds: string[]
      effectiveDate: Date | null
      expiryDate: Date | null
      carrier: string | null
    }) => {
      try {
        shouldBeginPolling.current = true
        const result = await executeMutation({ name: file.name, tagIds })
        if (result.error) {
          throw result.error
        } else {
          const res = result.data as Mutation
          const formData = new FormData()
          formData.append('file', file)
          setAppendingFile(true)
          await axios.put(res.getPresignedUrlForSourceUpload.url, file, {
            headers: {
              'Content-Type': file.type,
            },
          })
          setAppendingFile(false)
          const processingResult = await executeProcessingMutation({
            startProcessingSourceId: res.getPresignedUrlForSourceUpload.id,
            options: {
              carrierCode: carrier,
              effectiveDate: effectiveDate ? effectiveDate.toISOString() : null,
              expiryDate: expiryDate ? expiryDate.toISOString() : null,
            },
          })
          if (shouldBeginPolling.current && processingResult.data) {
            setSelectedFile(null)
            shouldBeginPolling.current = false
            setSourceStatus(res.getPresignedUrlForSourceUpload.id, {
              fileName: file.name,
              progress: processingResult.data.startProcessingSource as SourceUploadProgress,
            })
            setUploadModalId(res.getPresignedUrlForSourceUpload.id)
            pollSourceStatus(
              processingResult.data.startProcessingSource.status,
              res.getPresignedUrlForSourceUpload.id,
              file.name,
            )
          }
        }
      } catch (error) {
        setAppendingFile(false)
      }
    },
    [
      executeMutation,
      executeProcessingMutation,
      setSourceStatus,
      setUploadModalId,
      pollSourceStatus,
    ],
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'text/plain': ['.txt'],
      'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
      // pdf
      'application/pdf': ['.pdf'],
    },
  })
  const client = useClient()

  return (
    <div className="bg-background-light">
      <Header title="File Upload" />
      {customer?.rateIngestion?.enabled ? (
        <>
          <div className="container mx-auto py-32">
            <div
              {...getRootProps()}
              className="rounded-lg border-2 border-dashed border-[rgba(6,133,241,0.1)] bg-[rgba(6,133,241,0.05)] p-16 flex flex-col items-center"
            >
              <input {...getInputProps()} />
              <img src={fileIcon} alt="blue file in folder" className="mb-5" />
              <p className="text-base font-semibold leading-[18px] mb-3">Drop your file here, or</p>
              <ButtonPrimary>Browse...</ButtonPrimary>
              <p className="mt-3 text-sm font-medium leading-[18px] text-[rgba(20,20,20,0.5)]">
                Supports: CSV, XLSX, XLS or TXT
              </p>
            </div>
            {queryData.data?.getSources && <SourceTable sources={queryData.data.getSources} />}
          </div>
          <UploadStatusModal
            isOpen={uploadModalId !== null}
            status={
              (uploadModalId !== null && sourceStatuses[uploadModalId]?.progress) ||
              uploadModalProgress || {
                status: SourceStatus.Pending,
                progressText: '',
                id: '',
              }
            }
            onClose={() => {
              if (uploadModalId) {
                removePollingTimeout(uploadModalId)
                removeSourceStatus(uploadModalId)
              }
              shouldBeginPolling.current = false
              setUploadModalId(null)
              setUploadModalProgress(null)
            }}
            onViewFile={() => {
              refetchSources({ requestPolicy: 'network-only' })
              setUploadModalId(null)
              setUploadModalProgress(null)
            }}
            onMinimize={() => {
              setUploadModalId(null)
            }}
          />
          {selectedFile && (
            <UpdateFillInfoModal
              file={selectedFile}
              onClose={() => setSelectedFile(null)}
              onBeginUpload={({ tags, description, effectiveDate, expiryDate, carrier }) =>
                onBeginProcessing({
                  file: selectedFile,
                  tagIds: tags.map((tag) => tag.id),
                  description,
                  effectiveDate,
                  expiryDate,
                  carrier: carrier?.code || null,
                })
              }
              uploading={executingUploadMutation || executingProcessingMutation || appendingFile}
            />
          )}
          <UploadStatusOverlay
            sourceStatuses={sourceStatuses}
            completedSources={completedSources}
            onOpenSource={onOpenSource}
          />
        </>
      ) : (
        <>
          <div className="container mx-auto py-32">
            <div className="flex flex-col items-center">
              <p className="text-center mb-6 text-2xl font-semibold">
                Rate ingestion is not available for your company at the moment.
              </p>
              <ButtonPrimary onClick={() => setRequestAccessOpen(true)}>
                Request Access
              </ButtonPrimary>
            </div>
          </div>
          {requestAccessOpen && <RequestAccessModal onClose={() => setRequestAccessOpen(false)} />}
        </>
      )}
    </div>
  )
}

export default UploadPage
