import { useEffect } from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'
import woveLogo from '@src/assets/wove-logo-rgb.svg'
import backgroundLogo from './assets/wove-background-logo.png'
import { useAuth } from '@src/hooks/useAuth'
import { OnboardingStep } from '@wove/common/onboardingSteps'

const routes: Partial<Record<OnboardingStep, string>> = {
  [OnboardingStep.COMPANY_INFO]: '/company-info',
  [OnboardingStep.PERSONAL_INFO]: '/personal-info',
  [OnboardingStep.INVITE_TEAMMATES]: '/invite-teammates',
}

const SplashLayout = () => {
  const { onboardingStep, setOnboardingStep } = useAuth()
  const { pathname } = useLocation()
  useEffect(() => {
    if (onboardingStep) {
      setOnboardingStep(null)
    }
  }, [onboardingStep])

  if (onboardingStep) {
    if (routes[onboardingStep] && routes[onboardingStep] !== pathname) {
      return <Navigate to={routes[onboardingStep]!} />
    }
  }

  return (
    <div className="relative h-full">
      <div className="absolute top-0 bottom-0 w-[min(500px,50vw)] h-[min(400px,80vw)] bg-gradient-to-r from-[#0685f1] via-[#319dfa] to-[#63b5fb] blur-[120px] opacity-35" />
      <img src={woveLogo} alt="wove" className="absolute top-[72px] right-[52px]" />
      <div className="absolute bottom-[50px] right-0 max-h-[50%] w-auto max-w-[50%] before:content-[''] before:absolute before:inset-0 before:bg-gradient-to-r before:from-[#0685f1] before:via-[#319dfa] before:to-[#63b5fb] before:-mt-[50px] before:-ml-[50px] before:blur-[120px] before:opacity-35">
        <img src={backgroundLogo} alt="background logo" className="max-h-full w-auto" />
      </div>
      <div className="w-[457px] max-w-full h-full flex items-center relative">
        <div className="overflow-auto p-10 w-full">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default SplashLayout
