import React, { useState, useCallback } from 'react'
import hamburgerIcon from './assets/hamburger.svg'
import { ReactComponent as addContracts } from './assets/add-contracts.svg'
import { ReactComponent as Settings } from './assets/settings.svg'
import { ReactComponent as LogoutIcon } from './assets/logout.svg'
import BaseButton from '@src/Components/Buttons/BaseButton'
import { NavLink, useNavigate } from 'react-router-dom'
import useUserStore from '@src/state/user'
import woveLogo from '@src/assets/wove-logo-rgb.svg'
import { ReactComponent as LeftChevronBlue } from './assets/left-chevron-blue.svg'
import { ReactComponent as HamburgerSolid } from './assets/hamburger-solid.svg'
import { useAuth } from '@src/hooks/useAuth'
import ProfileModal from '@src/Components/ProfileModal'
import { useLogoutMutation } from '@wove/api'

const MENU_ITEMS = [
  { title: 'Add Contracts', icon: addContracts, link: '/', key: 'addContracts' },
]

const SideNavigation = () => {
  const navigate = useNavigate()
  const [profileModalVisible, setProfileModalVisible] = useState(false)
  const [, logout] = useLogoutMutation()
  const auth = useAuth()
  const user = useUserStore((state) => state.user)
  const customer = useUserStore((state) => state.customer)

  const [expanded, setExpanded] = useState(true)
  const onLogout = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      await logout({})
      auth.logout()
    },
    [auth, logout],
  )
  const onProfileClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setProfileModalVisible(true)
  }, [])
  const onProfileClose = useCallback(() => {
    setProfileModalVisible(false)
  }, [])
  const goHome = useCallback(() => {
    navigate('/')
  }, [navigate])

  return (
    <nav
      className={`
        w-[266px] h-screen border-r border-wove-grey flex flex-col justify-between
        transition-all duration-500 ease-in-out
        ${expanded ? 'max-w-[280px]' : 'max-w-[56px]'}
      `}
    >
      <div className="pb-5">
        {expanded ? (
          <div className="p-[5px_21px] flex items-center justify-between h-14">
            {customer && (
              <img
                onClick={goHome}
                src={customer?.logoUrl || woveLogo}
                alt="logo"
                className="max-h-[30px] w-auto cursor-pointer h-auto"
              />
            )}
            <BaseButton
              onClick={() => setExpanded(false)}
              className="flex items-center gap-2"
            >
              <LeftChevronBlue />
              <HamburgerSolid />
            </BaseButton>
          </div>
        ) : (
          <BaseButton
            onClick={() => setExpanded(true)}
            className="w-14 h-14 flex justify-center items-center"
          >
            <img src={hamburgerIcon} alt="hamburger menu blue" />
          </BaseButton>
        )}
        {MENU_ITEMS.map((item) => {
          return (
            <NavLink
              key={item.key}
              to={item.link}
              className={({ isActive }) =>
                `h-10 my-1 px-2 flex justify-start items-center gap-2 hover:bg-wove-menuItem ${isActive ? 'bg-wove-menuItem' : ''}`
              }
            >
              {({ isActive }) => (
                <>
                  <div className="w-10 flex-shrink-0 flex justify-center">
                    <item.icon stroke={isActive ? '#0685F1' : '#7D7D7D'} />
                  </div>
                  {expanded && (
                    <p className={`
                      m-0 text-sm leading-[14px] whitespace-nowrap
                      ${isActive ? 'text-wove-sky font-bold' : 'text-[#131313] font-normal'}
                    `}>
                      {item.title}
                    </p>
                  )}
                </>
              )}
            </NavLink>
          )
        })}
      </div>
      <div className="pb-6">
        <BaseButton
          onClick={onProfileClick}
          className="min-h-[76px] my-1 p-2 flex justify-start items-center gap-2 w-full bg-none border-none hover:bg-wove-menuItem"
        >
          <div className="w-10 flex-shrink-0 flex justify-center">
            <div className="text-xl">
              {user ? user.firstName[0] + (user.lastName?.[0] || '') : '??'}
            </div>
          </div>
          {user && expanded && (
            <div>
              <p className="text-[#131313] text-sm font-medium mb-1 whitespace-nowrap">
                {user.firstName} {user.lastName}
              </p>
              <div className="bg-wove-sky rounded-full px-4 min-h-8 w-fit flex items-center text-[10px] text-white leading-4">
                Admin
              </div>
            </div>
          )}
        </BaseButton>
        <NavLink
          to="/settings/account-settings"
          className={({ isActive }) =>
            `h-10 my-1 px-2 flex justify-start items-center gap-2 hover:bg-wove-menuItem ${isActive ? 'bg-wove-menuItem' : ''}`
          }
        >
          {({ isActive }) => (
            <>
              <div className="w-10 flex-shrink-0 flex justify-center">
                <Settings stroke={isActive ? '#0685F1' : '#7D7D7D'} />
              </div>
              {expanded && (
                <p className={`
                  m-0 text-sm leading-[14px] whitespace-nowrap
                  ${isActive ? 'text-wove-sky font-bold' : 'text-[#131313] font-normal'}
                `}>
                  Settings
                </p>
              )}
            </>
          )}
        </NavLink>
        <BaseButton
          onClick={onLogout}
          className="min-h-10 my-1 px-2 flex justify-start items-center gap-2 w-full bg-none border-none hover:bg-wove-menuItem"
        >
          <div className="w-10 flex-shrink-0 flex justify-center">
            <LogoutIcon />
          </div>
          {expanded && (
            <p className="m-0 text-sm leading-[14px] whitespace-nowrap text-[#b01212] font-normal">
              Logout
            </p>
          )}
        </BaseButton>
      </div>
      {profileModalVisible && <ProfileModal onClose={onProfileClose} />}
    </nav>
  )
}

export default SideNavigation
