import { ReactComponent as ChevronIcon } from './chevron-up.svg'

interface ChevronProps {
  direction?: 'up' | 'down' | 'left' | 'right'
  color?: string
  className?: string
}

const Chevron = ({ direction = 'up', color = '#fff', className = '' }: ChevronProps) => {
  const rotationClasses = {
    up: '',
    down: 'rotate-180',
    left: 'rotate-270',
    right: 'rotate-90'
  }

  return (
    <div className={`${rotationClasses[direction]} ${className}`}>
      <ChevronIcon stroke={color} />
    </div>
  )
}

export default Chevron
