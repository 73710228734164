import React, { useState, useCallback } from 'react'
import { Space } from '@mantine/core'
import { Instructions, PageTitle, SwitchButton } from '@src/Pages/Splash/CommonComponents'
import SwitchView from '@src/Pages/Splash/SwitchView'
import InputPrimary from '@src/Components/InputPrimary'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'
import { useNavigate } from 'react-router-dom'
import Spinner from '@src/Components/Spinner'
import Prompt from '@src/Components/Prompt'
import { getCombinedErrorMessage } from '@src/util/helpers'
import {  useCreateAccountMutation } from '@wove/api'

const SignupScreen = () => {
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const navigate = useNavigate()
  const [firstName, setFirstName] = useState('')
  const [{ fetching: creating, error }, createAccount] = useCreateAccountMutation()
  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const response = await createAccount({
        userData: {
          firstName: firstName.trim(),
          email: email.trim(),
        },
      })
      if (!response.error) {
        setSuccess(true)
      }
    },
    [createAccount, firstName, email],
  )
  const onResend = useCallback(async () => {
    //   TODO: Resend email
  }, [])
  const onBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <>
      {success ? (
        <>
          <SwitchButton back onClick={onBack}>
            Back
          </SwitchButton>
          <Space h={42} />
          <PageTitle>Check your email!</PageTitle>
          <Space h={12} />
          <Instructions>
            We just sent a confirmation email to <span className="bold">{email}</span>
          </Instructions>
          <Space h={42} />
          <div className="flex items-center gap-2.5 text-black text-xs font-semibold">
            Didn't receive an email? <SwitchButton onClick={onResend}>Resend</SwitchButton>
          </div>
        </>
      ) : (
        <form onSubmit={onSubmit}>
          <PageTitle>Create an Account</PageTitle>
          <Space h={30} />
          <SwitchView
            keyText="Already have an account?"
            actionText="Sign in"
            onAction={() => navigate('/login')}
          />
          <Space h={30} />
          <InputPrimary
            placeholder="i.e. Johnny"
            autoFocus
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Space h={30} />
          <InputPrimary
            placeholder="name@acme.company"
            label="Work Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Space h={25} />
          {error && (
            <>
              <Prompt message={getCombinedErrorMessage(error)} />
              <Space h={25} />
            </>
          )}
          <ButtonPrimary disabled={creating || email.trim().length === 0} $matchWidth type="submit">
            {creating && <Spinner />}
            Continue
          </ButtonPrimary>
          <Space h={25} />
          <p className="text-[rgba(0,0,0,0.7)] text-center text-[0.625rem] font-medium [&_.bold]:font-semibold">
            By signing up, you are agreeing to <span className="bold">Wove's Privacy Policy</span>.
          </p>
        </form>
      )}
    </>
  )
}

export default SignupScreen
