import React from 'react'
import BaseButton from '@src/Components/Buttons/BaseButton'

interface ButtonNeutralProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  $backgroundColor?: string
}

const ButtonNeutral = React.forwardRef<HTMLButtonElement, ButtonNeutralProps>(
  ({ $backgroundColor, className = '', ...props }, ref) => {
    return (
      <BaseButton
        ref={ref}
        className={`
          h-12 px-8.5 
          border border-neutral-900/10
          ${$backgroundColor ? `bg-[${$backgroundColor}]` : 'bg-neutral-900/5'} 
          rounded-lg text-neutral-900
          disabled:opacity-50
          flex items-center justify-center gap-2.5
          text-sm font-semibold
          ${className}
        `}
        {...props}
      />
    )
  }
)

export default ButtonNeutral
