import { useState } from 'react'
import { Space } from '@mantine/core'
import { PageTitle } from '@src/Pages/Splash/CommonComponents'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'
import InputPrimary from '@src/Components/InputPrimary'
import { useRequestResetPasswordMutation, useResetPasswordMutation } from '@wove/api'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PasswordField from '@src/Components/PasswordField'
import { getCombinedErrorMessage } from '@src/util/helpers'
import Prompt from '@src/Components/Prompt'

const ResetPasswordScreen = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [{ fetching: resettingPassword }, executeResetPassword] = useRequestResetPasswordMutation()
  const [{ fetching: resetting }, executeReset] = useResetPasswordMutation()
  const [params] = useSearchParams()
  const token = params?.get('token')
  const navigate = useNavigate()

  return (
    <form onSubmit={async (e) => {
      e.preventDefault()
      if (token) {
        const trimmedPassword = password.trim()
        if (trimmedPassword.length >= 6 && confirmPassword.trim() === password) {
          const response = await executeReset({ token, newPassword: trimmedPassword})
          console.log('Response:', response)
          if (response.error) {
            setPasswordError(getCombinedErrorMessage(response.error))
          } else {
            setSubmitted(true)
          }
        } else if (password.length < 6) {
          setPasswordError('Password is too short')
        } else {
          setPasswordError('Passwords do not match')
        }
      }  else {
        const trimmed = email.trim()
        if (trimmed.length >= 8) {
          const result = await executeResetPassword({ email: trimmed })
          if (!result.error) {
            setSubmitted(true)
          }
        }
      }
    }}> 
      <PageTitle className="mb-8">Reset password</PageTitle>
      {token ? (
        <>
          {submitted ? (
          <div className="text-center">
            <div className="text-green-600 font-medium mb-4">Password successfully changed!</div>
            <ButtonPrimary onClick={() => navigate('/login')} $matchWidth>Back to login</ButtonPrimary>
          </div>
          ) : (
            <>
            <PasswordField
            autoFocus
            containerClassName="mb-4"
            value={password}
            label="Password"
            onChange={(e) => {
              setPassword(e.target.value)
              setPasswordError(e.target.value.length >= 6 ? '' : 'Password is too short')
            }}
          />
          <PasswordField
            containerClassName="mb-4"
            value={confirmPassword}
            label="Confirm Password"
            onChange={(e) => {
              setConfirmPassword(e.target.value)
            }}
          />
          <ButtonPrimary type="submit" loading={resetting} $matchWidth>
            Submit
          </ButtonPrimary>
            </>
          )}
        </>
      ) : (
        <>
        {submitted ? (
          <div className="text-center">
            <div className="text-green-600 font-medium mb-4">Password reset email sent!</div>
            <div className="text-gray-600">Please check your email for instructions to reset your password.</div>
          </div>
        ) : (
          <>
            <InputPrimary
              autoFocus
              value={email}
              label="Email"
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
            <Space h={20} />
            <ButtonPrimary type="submit" disabled={email.trim().length < 8} loading={resettingPassword} $matchWidth>
              Send Reset Email
            </ButtonPrimary>
          </>
        )}</>
      )}
      {passwordError && 
          <Prompt message={passwordError}  className="mt-4"/>}
    </form>
  )
}

export default ResetPasswordScreen
