import { SourceUploadProgress } from '@wove/api/types'
interface UploadItemProps {
  item: { fileName: string; progress: SourceUploadProgress }
  onOpen: () => void
}

const UploadItem = ({ item, onOpen }: UploadItemProps) => {
  const { fileName } = item
  return (
    <div
      className="p-4 px-5 cursor-pointer"
      onClick={(e) => {
        e.preventDefault()
        onOpen()
      }}
    >
      <div className="flex items-center text-[#121212] text-xs font-semibold mb-2">{fileName}</div>
      <div className="h-[3px] w-full rounded-full bg-wove-sky/15 relative">
        <div 
          className="absolute left-0 top-0 bottom-0 rounded-full bg-wove-sky transition-all duration-300"
          style={{ width: '50%' }}
        />
      </div>
    </div>
  )
}

export default UploadItem
