import { memo,  useState } from 'react'
import Select, { SelectProps } from '@src/Components/Select'
import { Location } from '@wove/api/types'
import { useSearchLocationsQuery } from '@wove/api'
// import RecentSearchesList from '@src/Pages/IntakeForms/IntakeFormPage/LocationSelect/RecentSearchesList'

interface LocationSelectProps extends SelectProps<Location> {
  isPort: boolean
  recentSearches?: any[]
}
const LocationSelect = ({ isPort, onFocus, onBlur, ...props }: LocationSelectProps) => {
  const [text, setText] = useState('')
  const [queryData] = useSearchLocationsQuery({ input: { isPort, query: text } })

  return (
    <Select<Location>
      isSearchable
      getOptionLabel={(option) =>
        ['code', 'name', 'region', 'countryCode']
          .filter((key) => option[key as keyof typeof option])
          .map((key) => option[key as keyof typeof option])
          .join(', ')
      }
      onInputChange={(text) => setText(text)}
      getOptionValue={(option) => option.code}
      options={queryData?.data?.searchLocations || []}
      filterOption={() => true}
      noOptionsMessage={({ inputValue }) =>
        inputValue ? 'No results found' : 'Start typing to search'
      }
      components={
        {
          // Menu: RecentSearchesList,
        }
      }
      {...props}
    />
  )
}

export default memo(LocationSelect)
