import { useState, useMemo, Fragment } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState,
} from '@tanstack/react-table'
import { UserWithRole } from '@wove/api/types'
import Chevron from '@src/Components/Chevron'
import { RoleNames } from '@src/util/constants'
import { Role } from '@wove/common/roles'
import RightActionCell from '@src/Pages/SettingsPage/ManageUsers/RightActionCell'

const columnHelper = createColumnHelper<UserWithRole>()

interface UsersTableProps {
  users: UserWithRole[]
}

const UsersTable = ({ users }: UsersTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([])
  const columns = useMemo(() => {
    return [
      columnHelper.accessor((row) => row, {
        cell: (info) => {
          const user = info.getValue()
          return (
            <div>
              <p className="text-black text-sm font-semibold leading-4">
                {user.firstName} {user.lastName}
              </p>
              <p className="text-black/30 text-xs font-medium leading-4">{user.email}</p>
            </div>
          )
        },
        sortingFn: (a, b, columnId) => {
          const userA: UserWithRole = a.getValue(columnId)
          const userB: UserWithRole = b.getValue(columnId)
          return `${userA.firstName} ${userA.lastName}`.localeCompare(
            `${userB.firstName} ${userB.lastName}`,
          )
        },
        header: () => <div>Name</div>,
        id: 'fullName',
      }),
      columnHelper.accessor((user) => user, {
        cell: (info) => {
          const user = info.getValue() as UserWithRole
          return (
            <p className="text-black/50 text-xs font-semibold leading-4">
              {RoleNames[user.role as Role]}
            </p>
          )
        },
        header: () => <div>Account Type</div>,
        sortingFn: (a, b, columnId) => {
          const userA: UserWithRole = a.getValue(columnId)
          const userB: UserWithRole = b.getValue(columnId)
          return userA.role.localeCompare(userB.role)
        },
        id: 'accountType',
      }),
      columnHelper.accessor('deactivatedAt', {
        cell: (info) => (
          <p className="text-black/50 text-xs font-semibold leading-4">
            {info.getValue() ? 'Deactivated' : 'Active'}
          </p>
        ),
        header: () => <div>Status</div>,
        sortingFn: (a, _b, columnId) => {
          const userA: UserWithRole = a.getValue(columnId)
          return userA.deactivatedAt ? -1 : 1
        },
        id: 'status',
      }),
      columnHelper.accessor((user) => user, {
        cell: (info) => <RightActionCell user={info.getValue()} />,
        header: () => <div>Last Login</div>,
        id: 'lastLogin',
        sortingFn: () => {
          // const userA: UserWithRole = a.getValue(columnId)
          // const userB: UserWithRole = b.getValue(columnId)
          return 0
        },
      }),
    ]
  }, [])

  const table = useReactTable({
    data: users,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <table className="w-full table-fixed rounded-lg font-semibold text-base border-separate border-spacing-y-[10px]">
      <colgroup>
        <col span={1} style={{ width: '55%' }} />
        <col span={1} style={{ width: '15%' }} />
        <col span={1} style={{ width: '15%' }} />
        <col span={1} style={{ width: '15%' }} />
      </colgroup>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} className="text-[#717b8c] uppercase p-5 text-xs text-left">
                {header.isPlaceholder ? null : (
                  <div
                    className={`flex items-center gap-1.5 ${
                      header.column.getIsSorted() ? 'text-black' : ''
                    } ${header.column.getCanSort() ? 'cursor-pointer select-none' : ''}`}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: <Chevron direction="up" color="#000" />,
                      desc: <Chevron direction="down" color="#000" />,
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <Fragment key={row.id}>
              <tr className="bg-white/5">
                {row.getVisibleCells().map((cell, index) => (
                  <td
                    key={cell.id}
                    className={`p-5 text-left border-y border-[rgba(70,70,70,0.5)] 
                      ${index === 0 ? 'border-l rounded-l-lg' : ''} 
                      ${
                        index === row.getVisibleCells().length - 1
                          ? 'border-r rounded-r-lg w-[1%]'
                          : ''
                      }`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            </Fragment>
          )
        })}
      </tbody>
    </table>
  )
}

export default UsersTable
