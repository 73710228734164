import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import RatesPage from '@src/Pages/IntakeForms/RatesPage'
import AppPage from '@src/Pages/AppPage'
import UploadPage from '@src/Pages/UploadPage'
import SellRatePage from '@src/Pages/SellRatePage'
import SignupScreen from '@src/Pages/Splash/SignupScreen'
import PersonalInfoScreen from '@src/Pages/Splash/PersonalInfoScreen'
import CompanyInfoScreen from '@src/Pages/Splash/CompanyInfoScreen'
import SetPasswordScreen from '@src/Pages/Splash/SetPasswordScreen'
import InviteTeammatesScreen from '@src/Pages/Splash/InviteTeammatesScreen'
import LoginScreen from '@src/Pages/Splash/LoginScreen'
import ResetPasswordScreen from '@src/Pages/Splash/ResetPasswordScreen'
import RootLayout from '@src/Pages/RootLayout'
import { useGetCurrentSessionQuery } from '@wove/api'
import SuccessScreen from '@src/Pages/Splash/SuccessScreen'
import SplashLayout from '@src/Pages/Splash/SplashLayout'
import Spinner from '@src/Components/Spinner'
import SettingsPage from '@src/Pages/SettingsPage'
import AccountSettings from '@src/Pages/SettingsPage/AccountSettings'
import LoginAndSecurity from '@src/Pages/SettingsPage/LoginAndSecurity'
import Notifications from '@src/Pages/SettingsPage/Notifications'
import ErrorPage from '@src/Pages/ErrorPage'
import AdminRoute from '@src/Router/AdminRoute'
import ManageUsers from '@src/Pages/SettingsPage/ManageUsers'
import IntakeFormPage from '@src/Pages/IntakeForms/IntakeFormPage'
// import rootLoader from '@src/Router/rootLoader'
import useUserStore, { UserState } from '@src/state/user'
import { useEffect } from 'react'
import { clearStorage } from '@src/authStore'
import LogRocket from 'logrocket'
import { OnboardingStep } from '@wove/common/onboardingSteps'
import { useShallow } from 'zustand/react/shallow'

const userSelector = (state: UserState) => ({
  setUser: state.setUser,
  setCustomer: state.setCustomer,
  setOnboardingStep: state.setOnboardingStep,
  setAuthed: state.setAuthed,
  setOnboarded: state.setOnboarded,
})

const Router = () => {
  const { setUser, setCustomer, setOnboardingStep, setAuthed, setOnboarded } = useUserStore(
    useShallow(userSelector),
  )
  const [{ data, error }] = useGetCurrentSessionQuery()

  useEffect(() => {
    if (error) {
      if (error.graphQLErrors.find((e) => e.extensions?.code === 'AUTHENTICATION_FAILED')) {
        //Authentication failed, log the user out
        clearStorage()
        setUser(null)
        setCustomer(null)
        setAuthed(true)
        setOnboarded(false)
      }
      return
    }

    if (data?.currentSession?.user) {
      const { user, customer, onboardingStep } = data.currentSession
      setUser(user)
      LogRocket.identify(user.id)
      customer && setCustomer(customer)
      setOnboardingStep(onboardingStep as OnboardingStep)
      setOnboarded(!onboardingStep)
      setAuthed(true)
    }
  }, [data, error, setUser, setCustomer, setOnboardingStep, setAuthed, setOnboarded])
  const router = createBrowserRouter([
    {
      element: <RootLayout />,
      // shouldRevalidate: () => false,
      // loader: rootLoader(client),
      children: [
        {
          element: <SplashLayout />,
          children: [
            {
              path: '/signup',
              element: <SignupScreen />,
            },
            {
              path: '/personal-info',
              element: <PersonalInfoScreen />,
            },
            {
              path: '/company-info',
              element: <CompanyInfoScreen />,
            },
            {
              path: '/set-password',
              element: <SetPasswordScreen />,
            },
            {
              path: '/invite-teammates',
              element: <InviteTeammatesScreen />,
            },
            {
              path: '/login',
              element: <LoginScreen />,
            },
            {
              path: '/reset-password',
              element: <ResetPasswordScreen />,
            },
            {
              path: '/signup-success',
              element: <SuccessScreen />,
            },
          ],
        },
        {
          path: '/',
          element: <AppPage showSidebar />,
          children: [
            {
              path: 'intake',
              element: <IntakeFormPage />,
            },
            // //Temporary until we add these pages
            // {
            //   path: 'rate-query',
            //   element: <IntakeFormPage />,
            // },
            // {
            //   path: 'all-contracts',
            //   element: <UploadPage />,
            // },
            // {
            //   path: 'conversations',
            //   element: <IntakeFormPage />,
            // },
            // {
            //   path: 'quote-history',
            //   element: <IntakeFormPage />,
            // },
            // //End temporary until we add these pages
            {
              path: 'rates',
              element: <RatesPage />,
            },
            {
              path: '',
              element: <UploadPage />,
            },
            {
              path: 'sell-rate',
              element: <SellRatePage />,
            },
            {
              path: 'settings',
              element: <SettingsPage />,
              children: [
                {
                  path: 'account-settings',
                  element: <AccountSettings />,
                },
                {
                  path: 'login-security',
                  element: <LoginAndSecurity />,
                },
                {
                  path: 'notifications',
                  element: <Notifications />,
                },
                {
                  path: 'manage-users',
                  element: (
                    <AdminRoute>
                      <ManageUsers />
                    </AdminRoute>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: '/',
          element: <AppPage />,
          children: [
            {
              path: '*',
              element: <ErrorPage />,
            },
          ],
        },
      ],
    },
  ])
  return <RouterProvider fallbackElement={<Spinner />} router={router} />
}

export default Router
