import React, { useState, useCallback } from 'react'
import { Space } from '@mantine/core'
import { Instructions, PageTitle } from '@src/Pages/Splash/CommonComponents'
import SwitchView from '@src/Pages/Splash/SwitchView'
import InputPrimary from '@src/Components/InputPrimary'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@src/hooks/useAuth'
import Spinner from '@src/Components/Spinner'
import PasswordField from '@src/Components/PasswordField'
import { getCombinedErrorMessage } from '@src/util/helpers'
import Prompt from '@src/Components/Prompt'
import { useLoginMutation } from '@wove/api'
const LoginScreen = () => {
  const [{ fetching: loggingIn, error }, login] = useLoginMutation()
  const auth = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const response = await login({
        login: {
          email,
          password,
        },
      })
      if (response.error) {
        //Handle error
      } else if (response.data?.login.user && response.data.login.customer) {
        auth.login({
          user: response.data.login.user,
          accessToken: response.data.login.tokens.accessToken,
          customer: response.data.login.customer,
        })
      }
    },
    [email, password, auth, login],
  )

  return (
    <form onSubmit={onSubmit}>
      <PageTitle>Welcome Back</PageTitle>
      <Space h={30} />
      <Instructions>Enter your account information below</Instructions>
      <Space h={30} />
      <InputPrimary
        autoFocus
        placeholder="name@acme.company"
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Space h={30} />
      <PasswordField
        label="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        customPrompt={
          <button
            type="button"
            className="text-wove-blue border-none bg-transparent text-xs font-semibold mb-1.5"
            onClick={(e) => {
              e.preventDefault()
              navigate('/reset-password')
            }}
          >
            Forgot Password?
          </button>
        }
      />
      <Space h={25} />
      {error && (
        <>
          <Prompt message={getCombinedErrorMessage(error)} />
          <Space h={20} />
        </>
      )}
      <ButtonPrimary disabled={email.trim().length === 0 || loggingIn} $matchWidth type="submit">
        {loggingIn && <Spinner />}
        Log in
      </ButtonPrimary>
      <Space h={25} />
      <SwitchView
        keyText="Don't have an account yet?"
        actionText="Sign Up"
        onAction={() => navigate('/signup')}
      />
    </form>
  )
}

export default LoginScreen
