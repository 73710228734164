import React, { useState } from 'react'
import InputPrimary, { InputPrimaryProps } from '@src/Components/InputPrimary'
import passwordShow from './assets/password-show.svg'
import passwordHide from './assets/password-hide.svg'
import BaseButton from '@src/Components/Buttons/BaseButton'

const PasswordField = React.forwardRef<HTMLInputElement, InputPrimaryProps>(
  ({ label = 'Password', ...props }, ref) => {
    const [masked, setMasked] = useState(true)
    return (
      <InputPrimary
        type={masked ? 'password' : 'text'}
        label={label}
        {...props}
        ref={ref}
        iconRight={
          <BaseButton
            className="bg-none border-none"
            onClick={(e) => {
              e.preventDefault()
              setMasked((prev) => !prev)
            }}
          >
            <img
              src={masked ? passwordShow : passwordHide}
              alt={masked ? 'password eye' : 'password eye crossed out'}
            />
          </BaseButton>
        }
      />
    )
  },
)

export default PasswordField
