import React from 'react'

const BaseButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className = '', ...props }, ref) => {
  return (
    <button 
      type="button" 
      ref={ref} 
      className={`cursor-pointer disabled:cursor-not-allowed ${className}`}
      {...props} 
    />
  )
})

export default BaseButton
