import { create } from 'zustand'
import { Customer, User } from '@wove/api/types'
import { devtools } from 'zustand/middleware'
import { STORE_MAIN } from '@src/state/Constants'
import { OnboardingStep } from '@wove/common/onboardingSteps'

export type UserState = {
  user: User | null
  setUser: (user: User | null) => void
  customer: Customer | null
  setCustomer: (customer: Customer | null) => void
  authed: boolean
  setAuthed: (authed: boolean) => void
  onboardingStep: OnboardingStep | null
  setOnboardingStep: (step: OnboardingStep | null) => void
  onboarded: boolean
  setOnboarded: (onboarded: boolean) => void
  setSplashInfo: (data: {
    user?: User | null
    customer?: Customer | null
    onboardingStep?: OnboardingStep | null
    onboarded?: boolean
  }) => void
}

const userStore = devtools<UserState>(
  (set) => ({
    user: null,
    setUser: (user) => set((prev) => ({ ...prev, user })),
    customer: null,
    setCustomer: (customer: Customer | null) => set((prev) => ({ ...prev, customer })),
    onboardingStep: null,
    setOnboardingStep: (onboardingStep: OnboardingStep | null) =>
      set((prev) => ({ ...prev, onboardingStep })),
    onboarded: false,
    setOnboarded: (value) => set({ onboarded: value }),
    authed: false,
    setAuthed: (value: boolean) => set((prev) => ({ ...prev, authed: value })),
    setSplashInfo: (data) =>
      set(Object.fromEntries(Object.entries(data).filter(([key]) => key in data))),
  }),
  {
    name: STORE_MAIN,
    store: 'user',
  },
)

export const useUserStore = create(userStore)

export default useUserStore
