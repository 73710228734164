import BaseModal from '@src/Components/BaseModal'
import { useRequestRateIngestionMutation } from '@wove/api'
import { useState } from 'react'
import { toast } from 'sonner'

const RequestAccessModal = ({ onClose }: { onClose: () => void }) => {
  const [{ fetching: requesting }, requestIngestion] = useRequestRateIngestionMutation()
  const [selected, setSelected] = useState<Record<string, boolean>>({})
  const options = [
    { label: 'Ocean FCL', value: 'ocean-fcl' },
    { label: 'Ocean LCL', value: 'ocean-lcl' },
    { label: 'Air', value: 'air' },
    { label: 'Truck', value: 'truck' },
    { label: 'Rail', value: 'rail' },
  ]

  const toggleItem = (key: string) => {
    setSelected((prev) => ({
      ...prev,
      [key]: !prev[key],
    }))
  }

  return (
    <BaseModal
      onCloseButton={onClose}
      title="Request Rate Ingestion"
      fillButtons
      buttons={[
        {
          title: 'Request',
          type: 'primary',
          onClick: () => {
            requestIngestion({ types: Object.keys(selected).filter((key) => selected[key]) }).then(
              (res) => {
                if (res.data?.requestRateIngestion?.success) {
                  toast.success('Request sent!')
                  onClose()
                }
              },
            )
          },
          loading: requesting,
          disabled: requesting || !Object.values(selected).some((value) => value),
        },
      ]}
    >
      <div className="max-w-md mx-auto p-6">
        <h2 className="text-lg font-semibold mb-4 text-gray-800 text-center">
          What shipping methods will you use?
        </h2>
        <ul className="space-y-3">
          {options.map((item) => {
            const isSelected = selected[item.value]
            return (
              <li key={item.value} className="flex items-center">
                <label className="flex items-center cursor-pointer hover:bg-gray-50 rounded-md">
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => toggleItem(item.value)}
                    className="w-5 h-5 rounded border-gray-300 text-blue-500 focus:ring-blue-500 mr-3"
                  />
                  <span className={`text-gray-700 ${isSelected ? 'text-gray-500' : ''}`}>
                    {item.label}
                  </span>
                </label>
              </li>
            )
          })}
        </ul>
      </div>
    </BaseModal>
  )
}

export default RequestAccessModal
