import { graphql } from '../gql'
import { useQuery } from 'urql'

const getUploadOptions = graphql(`
  query GetUploadOptions($filename: String!) {
    getUploadOptions(filename: $filename) {
      carrierCode
      effectiveDate
      expiryDate
      carriers {
        code
        name
      }
    }
  }
`)

export const useGetUploadOptions = (filename: string) =>
  useQuery({ query: getUploadOptions, variables: { filename } })
