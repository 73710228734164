import React, { useState } from 'react'
import { Space } from '@mantine/core'
import { Instructions, PageTitle } from '@src/Pages/Splash/CommonComponents'
import InputPrimary from '@src/Components/InputPrimary'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'
import FieldLabel from '@src/Components/FieldLabel'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import uploadIcon from './assets/upload.svg'
import { useNavigate } from 'react-router-dom'
import Spinner from '@src/Components/Spinner'
import { useAuth } from '@src/hooks/useAuth'
import Prompt from '@src/Components/Prompt'
import { getCombinedErrorMessage } from '@src/util/helpers'
import LogRocket from 'logrocket'
import { useSetCompanyInfoMutation } from '@wove/api'
import useUserStore from '@src/state/user'

const CompanyInfoScreen = () => {
  const [companyName, setCompanyName] = useState('')
  const auth = useAuth()
  const setSplashInfo = useUserStore((state) => state.setSplashInfo)
  const [selectedLogo, setSelectedLogo] = useState<File | null>(null)
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles?.[0]) {
      setSelectedLogo(acceptedFiles[0])
    }
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const navigate = useNavigate()
  const [{ fetching: submitting, error }, setCompanyInfo] = useSetCompanyInfoMutation()
  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const response = await setCompanyInfo({
        companyInfo: {
          name: companyName.trim(),
          logo: selectedLogo,
        },
      })
      if (response.data) {
        const { customer, user } = response.data.setCompanyInfo
        LogRocket.identify(user.id)
        setSplashInfo({ user, customer })
        navigate('/invite-teammates')
      }
    },
    [auth, navigate, setCompanyInfo, selectedLogo, companyName],
  )

  return (
    <form onSubmit={onSubmit}>
      <PageTitle>Tell us about your company</PageTitle>
      <Space h={30} />
      <Instructions>
        Personalizing your workspace with your brand makes all of your teammates feel at home.
      </Instructions>
      <Space h={30} />
      <InputPrimary
        autoFocus
        placeholder="How your name will be displayed"
        label="Company Name"
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
      />
      <Space h={20} />
      <FieldLabel>Logo (optional)</FieldLabel>
      <div {...getRootProps()} className="rounded-lg border border-[rgba(0,0,0,0.1)] bg-[rgba(147,153,163,0.1)] flex justify-center items-center p-5 flex-col">
        <input {...getInputProps()} />
        <img src={uploadIcon} alt="upload cloud" />
        <p className="text-black text-sm font-medium max-w-full">
          {selectedLogo ? (
            <div className="flex items-baseline">
              <p className="whitespace-nowrap overflow-hidden text-ellipsis">{selectedLogo.name}</p>{' '}
              <span className="text-wove-blue text-xs underline">(Change)</span>
            </div>
          ) : (
            <>
              <span className="text-wove-blue text-xs underline">Add a logo</span> or just drop one here
            </>
          )}
        </p>
      </div>
      <Space h={35} />
      {error && (
        <>
          <Prompt message={getCombinedErrorMessage(error)} />
          <Space h={20} />
        </>
      )}
      <ButtonPrimary
        disabled={submitting || companyName.trim().length === 0}
        $matchWidth
        type="submit"
      >
        {submitting && <Spinner />}
        Continue
      </ButtonPrimary>
      <Space h={25} />
    </form>
  )
}

export default CompanyInfoScreen
