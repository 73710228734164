import { ShippingRate } from '@wove/api/types'

const SellRateTable = ({ rate, sellRate }: { rate: ShippingRate; sellRate: number }) => {
  return (
    <div className="rounded-xl border border-[#f1f1f1] overflow-hidden">
      <div className="bg-[#e0e0e0] min-h-[34px] flex items-stretch text-[rgba(20,20,20,0.7)] text-[10px] font-bold uppercase px-5">
        <div className="flex-[3_1_0px] flex items-center text-[0.625rem]">Sell Rate</div>
        <div className="flex-[3_1_0px] pl-2.5 flex items-center text-[0.625rem] relative before:absolute before:left-0 before:top-2 before:bottom-2 before:w-px before:h-5 before:bg-[rgba(20,20,20,0.1)]">Carrier</div>
        <div className="flex-[1_1_0px] pl-2.5 flex items-center text-[0.625rem] relative before:absolute before:left-0 before:top-2 before:bottom-2 before:w-px before:bg-[rgba(20,20,20,0.1)]">Commodity</div>
      </div>
      <div className="flex py-[29px] pb-[15px] px-5">
        <div className="flex-[3_1_0px] text-wove-blue text-base font-bold">${Math.round(sellRate)}</div>
        <div className="flex-[3_1_0px] pl-2.5 text-sm">{rate.carrier.name}</div>
        <div className="flex-[1_1_0px] pl-2.5 text-sm">{rate.commodityType}</div>
      </div>
    </div>
  )
}

export default SellRateTable
