import { Fragment } from 'react'
import { ContainerType, RateLineItem as RateLineItemType } from '@wove/api/types'
import Price from '@src/Pages/IntakeForms/Components/Price'
import { Space } from '@mantine/core'
import Preview from '@src/Pages/IntakeForms/RatesPage/RateLineItem/Preview'

interface RateLineItemProps {
  lineItem: RateLineItemType
}

const containerDescriptionMap: Record<ContainerType, string> = {
  [ContainerType.C_20ft]: '20ft Dry',
  [ContainerType.C_40ft]: '40ft Dry',
  [ContainerType.C_40ftHc]: '40ft Dry (High Cube)',
  [ContainerType.C_45ft]: '45ft Dry',
  [ContainerType.C_20ftReefer]: '20ft Reefer',
  [ContainerType.C_40ftReefer]: '40ft Reefer',
  [ContainerType.C_40ftHcReefer]: '40ft Reefer (High Cube)',
  [ContainerType.C_45ftReefer]: '45ft Reefer',
}

const RateLineItem = ({ lineItem }: RateLineItemProps) => {
  return (
    <div className="flex font-medium last:mb-0 mb-6">
      <div className="flex-[0_0_200px] px-4 flex-shrink-0 border-r border-[rgba(90,97,115,0.1)]">
        {lineItem.containers.map((container, index) => (
          <span className="text-[#9399A3]" key={index}>
            {containerDescriptionMap[container.type as unknown as ContainerType]} x{' '}
            {container.quantity} @ <Price price={container.pricePerContainer} />
            {container.quantity > 1 && (
              <>
                : <Price price={container.totalPrice} />
              </>
            )}
            <br />
          </span>
        ))}
        <span className="text-[#9399A3]">Total:</span> <Price price={lineItem.price} />
      </div>
      <div className="flex-[1_1_0px] pr-5 pl-4 min-w-0 break-words">
        <p>{lineItem.description}</p>
        {lineItem.notes && (
          <>
            <br />
            <p>
              <span className="text-[#9399A3]">Carrier Notes:</span>{' '}
              {lineItem.notes.split('\n').map((text, index) => (
                <Fragment key={index}>
                  <br />
                  {text}
                </Fragment>
              ))}
            </p>
          </>
        )}
        <br />
        {lineItem.source && (
          <>
            <p>
              <span className="text-[#9399A3]">Contract:&nbsp;</span>
              {lineItem.source.contract} &middot;{' '}
              <span className="text-[#9399A3]">
                {lineItem.source.location.tab} &middot; Row {lineItem.source.location.row}
              </span>
            </p>
            {lineItem.source.location.preview && (
              <>
                <Space h={8} />
                <Preview preview={lineItem.source.location.preview} />
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default RateLineItem
