import React from 'react'
import checkIcon from './assets/check.svg'

const Checkbox = React.forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>(
  ({ className = '', ...props }, ref) => {
    return (
      <div className="relative">
        <input
          type="checkbox"
          ref={ref}
          className={`
            peer opacity-0 z-[1] cursor-pointer w-[25px] h-[25px] border-none
            ${className}
          `}
          {...props}
        />
        <label
          className={`
            absolute top-[25%] left-4 w-6 h-6 rounded 
            bg-white border border-[#ccc]
            peer-checked:border-none
            peer-checked:after:content-[''] 
            peer-checked:after:absolute 
            peer-checked:after:inset-0
            peer-checked:after:bg-wove-blue
            peer-checked:after:bg-[url('${checkIcon}')] 
            peer-checked:after:bg-no-repeat 
            peer-checked:after:bg-center
            peer-checked:after:rounded
          `}
        />
      </div>
    )
  }
)

export default Checkbox
