import { Space, Grid } from '@mantine/core'
import { ReactComponent as AddIcon } from './assets/gallery-add.svg'
import InputPrimary from '@src/Components/InputPrimary'

const AccountSettings = () => {
  return (
    <div className="gap-3 py-8">
      <label className="text-base font-medium text-[#4c535f] pb-3">Your Profile Picture</label>
      <Space h={12} />
      <div className="cursor-pointer w-[132px] h-[132px] rounded-[18px] border border-dashed border-[#4c535f] bg-[#edf2f6] flex flex-col justify-center items-center gap-4 text-center text-[#4c535f] text-xs font-medium">
        <AddIcon />
        Upload your photo
      </div>
      <Space h={40} />
      <div className="h-0.5 bg-[rgba(224,228,236,0.5)]"></div>
      <Space h={32} />
      <Grid gutter={24}>
        <Grid.Col span={6}>
          <InputPrimary label="First Name" />
        </Grid.Col>
        <Grid.Col span={6}>
          <InputPrimary label="Last Name" />
        </Grid.Col>
        <Grid.Col span={6}>
          <InputPrimary label="Email" />
        </Grid.Col>
        <Grid.Col span={6}>
          <InputPrimary label="Job Title" />
        </Grid.Col>
      </Grid>
    </div>
  )
}

export default AccountSettings
