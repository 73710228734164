import React, { useState, useCallback, useMemo, useEffect } from 'react'
import BaseModal from '@src/Components/BaseModal'
import uniqBy from 'lodash/uniqBy'
import { displayFileSize } from '@src/util/helpers'
import FieldLabel from '@src/Components/FieldLabel'
import Select from '@src/Components/Select'
import { SingleValue, components, SingleValueProps } from 'react-select'
import { CarrierOption, Tag as TagType } from '@wove/api/types'
import { useCreateTagMutation, useGetTagsQuery, useGetUploadOptions } from '@wove/api'
import Tag from '@src/Components/Tag'
import DatePicker from '@src/Pages/IntakeForms/Components/DatePicker'
import Spinner from '@src/Components/Spinner'
import { getTimezoneIgnoredDate, stripTimezoneFromDate } from '@src/util'

const CustomSingleValue = ({ children, ...props }: SingleValueProps<TagType | AddNewTag>) => {
  return (
    <components.SingleValue {...props}>
      {props.selectProps.inputValue ? children : props.selectProps.placeholder}
    </components.SingleValue>
  )
}

type AddNewTag = {
  value: string
  label: string
}

function isAddNew(tag: TagType | AddNewTag): tag is AddNewTag {
  return (tag as AddNewTag).value === '__add_new__'
}

interface UpdateFillInfoModalProps {
  onClose: () => void
  file: File
  onBeginUpload: ({
    tags,
    description,
    effectiveDate,
    expiryDate,
    carrier,
  }: {
    tags: TagType[]
    description: string
    effectiveDate: Date | null
    expiryDate: Date | null
    carrier: CarrierOption | null
  }) => void
  uploading: boolean
}

const UpdateFillInfoModal = ({
  onClose,
  file,
  onBeginUpload,
  uploading,
}: UpdateFillInfoModalProps) => {
  const [{ data: uploadData, fetching: isLoadingUploadData }] = useGetUploadOptions(file.name)
  const [effectiveDate, setEffectiveDate] = useState<Date | null>(null)
  const [expiryDate, setExpiryDate] = useState<Date | null>(null)
  const [tagsText, setTagsText] = useState('')
  const [selectedCarrier, setSelectedCarrier] = useState<CarrierOption | null>(null)
  const [tags, setTags] = useState<TagType[]>([])
  const [{ fetching: fetchingTags, data: tagsData }, refetchTags] = useGetTagsQuery()
  const [_result, createTagMutation] = useCreateTagMutation()
  const [descriptionText, setDescriptionText] = useState('')
  const onUpload = useCallback(() => {
    onBeginUpload({
      tags,
      description: descriptionText,
      effectiveDate,
      expiryDate,
      carrier: selectedCarrier,
    })
  }, [onBeginUpload, tags, descriptionText, effectiveDate, expiryDate, selectedCarrier])
  const onChangeDescription = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescriptionText(e.target.value)
  }, [])

  const onAddTag = useCallback((tag: TagType) => {
    setTags((prev) => uniqBy([...prev, tag], 'id'))
  }, [])

  useEffect(() => {
    if (uploadData?.getUploadOptions) {
      const selectedCarrier = uploadData.getUploadOptions.carriers.find(
        (carr) => carr.code === uploadData.getUploadOptions.carrierCode,
      )
      selectedCarrier && setSelectedCarrier(selectedCarrier)
      uploadData.getUploadOptions.effectiveDate &&
        setEffectiveDate(getTimezoneIgnoredDate(uploadData.getUploadOptions.effectiveDate))
      uploadData.getUploadOptions.expiryDate &&
        setExpiryDate(getTimezoneIgnoredDate(uploadData.getUploadOptions.expiryDate))
    }
  }, [uploadData])

  const tagOptions = useMemo(() => {
    const trimmedText = tagsText.trim()
    const options: (TagType | AddNewTag)[] = (tagsData?.getTags || []).slice(0)
    if (trimmedText.length) {
      options.push({
        value: '__add_new__',
        label: `Create new tag: ${trimmedText}`,
      })
    }
    return options
  }, [tagsData?.getTags, tagsText])

  const onChooseTag = useCallback(
    async (option: SingleValue<TagType | AddNewTag>) => {
      if (option) {
        if (isAddNew(option)) {
          const response = await createTagMutation({ name: tagsText.trim() })
          if (!response.error) {
            refetchTags({ requestPolicy: 'network-only' })
            if (response.data?.createTag) {
              onAddTag(response.data!.createTag)
              setTagsText('')
            }
          }
        } else {
          onAddTag(option)
        }
      }
    },
    [createTagMutation, refetchTags, tagsText, onAddTag],
  )

  return (
    <BaseModal
      onCloseButton={onClose}
      title="Uploaded File Settings"
      fillButtons
      buttons={[
        { title: 'Cancel', type: 'neutral', onClick: onClose },
        {
          title: 'Upload',
          type: 'primary',
          onClick: onUpload,
          loading: uploading,
          disabled: isLoadingUploadData,
        },
      ]}
    >
      <div className="flex gap-[50px] p-4 px-3.5 rounded-lg border border-[rgba(70,70,70,0.1)] bg-[rgba(90,97,115,0.03)] text-black-text text-sm font-semibold leading-[18px] truncate mb-6">
        {file.name}
        <span className="text-[rgba(18,18,18,0.5)] text-xs">{displayFileSize(file.size)}</span>
      </div>
      <FieldLabel>Tags</FieldLabel>
      <Select<TagType | AddNewTag>
        options={tagOptions}
        className="mb-6"
        onInputChange={setTagsText}
        inputValue={tagsText}
        onChange={onChooseTag}
        isLoading={fetchingTags}
        components={{
          SingleValue: CustomSingleValue,
        }}
        getOptionLabel={(option) => (isAddNew(option) ? option.label : option.name)}
        getOptionValue={(option) => (isAddNew(option) ? option.value : option.id)}
      />
      {tags.length > 0 && (
        <div className="mb-6">
          <div className="flex flex-wrap gap-1.5">
            {tags.map((tag, index) => {
              return (
                <Tag
                  key={index}
                  tag={tag}
                  onRemove={() => {
                    setTags((prev) => prev.filter((_, i) => i !== index))
                  }}
                />
              )
            })}
          </div>
        </div>
      )}
      <FieldLabel>Text box</FieldLabel>
      <textarea
        className="w-full border border-[#e0e0e0] rounded-lg font-medium text-sm leading-[17px] text-[#141414] p-4 min-h-[90px placeholder:opacity-40 mb-8"
        placeholder="Additional information..."
        value={descriptionText}
        onChange={onChangeDescription}
      />
      {isLoadingUploadData ? (
        <Spinner className="mb-8 border-wove-blue" />
      ) : (
        <>
          <FieldLabel>Carrier</FieldLabel>
          <Select
            options={uploadData?.getUploadOptions?.carriers || []}
            className="mb-8"
            isLoading={isLoadingUploadData}
            getOptionLabel={(opt) => opt.name}
            getOptionValue={(opt) => opt.code}
            onChange={(opt) => setSelectedCarrier(opt)}
            value={selectedCarrier}
          />

          <div className="mb-8">
            <FieldLabel>Effective Date</FieldLabel>
            <DatePicker
              selected={effectiveDate}
              onChange={(date) => {
                const stripped = stripTimezoneFromDate(date)
                setEffectiveDate(stripped)
                if (expiryDate && stripped && expiryDate < stripped) {
                  setExpiryDate(null)
                }
              }}
            />
          </div>
          <div className="mb-8">
            <FieldLabel>Expiry Date</FieldLabel>
            <DatePicker
              selected={expiryDate}
              onChange={(date) => setExpiryDate(stripTimezoneFromDate(date))}
              minDate={effectiveDate}
            />
          </div>
        </>
      )}
    </BaseModal>
  )
}

export default UpdateFillInfoModal
