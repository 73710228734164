import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { STORE_MAIN } from "../Constants";

export type AuthState = {
  accessToken: string | null;
  setAccessToken: (token: string | null) => void;
  apiUrl: string | null;
  setApiUrl: (url: string | null) => void;
  logout: () => void;
};

export const useAuthStore = create(
  devtools<AuthState>(
    (set) => ({
      accessToken: null,
      setAccessToken: (token: string | null) => set({ accessToken: token }),
      apiUrl: null,
      setApiUrl: (url: string | null) => set({ apiUrl: url }),
      logout: () =>
        set({
          accessToken: null,
        }),
    }),
    {
      name: STORE_MAIN,
      store: "auth",
    }
  )
);
