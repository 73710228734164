import React from 'react'

const RadioButton = React.forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>(
  ({ className = '', ...props }, ref) => {
    return (
      <div className="relative">
        <input
          ref={ref}
          type="radio"
          className={`
            peer w-6 h-6 opacity-0 z-10 cursor-pointer
            ${className}
          `}
          {...props}
        />
        <label
          className={`
            absolute top-1/4 left-4 w-6 h-6 rounded-full
            bg-white border border-gray-300
            peer-hover:bg-gray-300 peer-hover:border-2 peer-hover:border-gray-300
            peer-hover:after:content-[''] peer-hover:after:block
            peer-hover:after:w-3 peer-hover:after:h-3 peer-hover:after:m-1
            peer-hover:after:rounded-full peer-hover:after:bg-wove-blue
            peer-checked:border-2 peer-checked:border-wove-blue
            peer-checked:after:content-[''] peer-checked:after:block
            peer-checked:after:w-3 peer-checked:after:h-3 peer-checked:after:m-1
            peer-checked:after:rounded-full peer-checked:after:bg-wove-blue
            transition-colors duration-200
          `}
        />
      </div>
    )
  },
)

export default RadioButton
