interface HeaderProps {
  title: string
  body?: string
  children?: React.ReactNode
}

const Header = ({ title, body, children }: HeaderProps) => {
  return (
    <div className="p-9 border-b border-[rgba(70,70,70,0.08)] bg-white">
      <div className="text-center container wove-container mx-auto">
        <h3 className="text-2xl font-semibold mb-2.5">{title}</h3>
        {body && <p className="text-sm text-[#9399a3] leading-[17px]">{body}</p>}
        {children}
      </div>
    </div>
  )
}

export default Header
