import React from 'react'
import FieldLabel from '@src/Components/FieldLabel'
import classNames from 'classnames'

export interface InputPrimaryProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: boolean | string
  customPrompt?: React.ReactNode
  iconRight?: React.ReactNode
  iconLeft?: React.ReactNode
  transparent?: boolean
  containerClassName?: string
}

const InputPrimary = React.forwardRef<HTMLInputElement, InputPrimaryProps>(
  ({ label, id, error, customPrompt, iconRight, iconLeft, transparent, className = '',  containerClassName, ...props }, ref) => {
    const showErrorMessage = error && typeof error === 'string'
    return (
      <>
        {(label || showErrorMessage || customPrompt) && (
          <div className="flex gap-2.5 justify-between">
            {label && <FieldLabel id={id}>{label}</FieldLabel>}
            {showErrorMessage && !customPrompt && (
              <FieldLabel className="text-wove-error">{error}</FieldLabel>
            )}
            {customPrompt}
          </div>
        )}
        <div className={classNames('relative', containerClassName)}>
          {iconLeft && (
            <div className="absolute inset-y-0 left-0 w-15 flex justify-center items-center">
              {iconLeft}
            </div>
          )}
          <input
            id={id}
            ref={ref}
            className={`
              ${transparent ? 'bg-transparent' : 'bg-white'}
              ${error ? 'border-2 border-wove-error' : 'border border-gray-200'}
              rounded-lg font-medium text-sm leading-[1.0625] text-wove-black
              h-[50px] w-full
              ${iconLeft ? 'pl-15' : 'pl-5'}
              ${iconRight ? 'pr-15' : 'pr-5'}
              placeholder:opacity-40
              ${className}
            `}
            {...props}
          />
          {iconRight && (
            <div className="absolute inset-y-0 right-4 w-15 flex justify-center items-center">
              {iconRight}
            </div>
          )}
        </div>
      </>
    )
  },
)

export default InputPrimary
