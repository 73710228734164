import { useMemo } from "react";
import axios from "axios";
import { useAuthStore } from "./state/auth";
import { Client, Provider, fetchExchange, mapExchange } from "urql";

export { Provider };

export const useClient = () => {
  const authStore = useAuthStore();
  const { accessToken, apiUrl } = authStore;
  const client = useMemo(() => {
    const headers: Record<string, string> = {};
    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return new Client({
      url: apiUrl ? `${apiUrl}/graphql` : "http://localhost:4000/graphql",
      fetchOptions: {
        headers: {
          "apollo-require-preflight": "true",
          ...headers,
        },
      },
      exchanges: [
        mapExchange({
          onError(error, operation) {
            console.log("Error:", error, operation);
          },
        }),
        fetchExchange,
      ],
    });
  }, [accessToken, apiUrl]);

  return client;
};

export const useRestApi = () => {
  const accessToken = useAuthStore((state) => state.accessToken);
  const apiUrl = useAuthStore((state) => state.apiUrl);
  const instance = useMemo(() => {
    const headers: any = {
      "Content-Type": "application/json",
    };
    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return axios.create({
      baseURL: apiUrl || "http://localhost:4000",
      headers,
    });
  }, [accessToken]);

  return instance;
};
