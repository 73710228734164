import { useCallback } from 'react'
import { UserWithRole } from '@wove/api/types'
import BaseButton from '@src/Components/Buttons/BaseButton'
import { ReactComponent as ThreeDotsWhite } from './assets/three-dots-white.svg'
import Menu from '@src/Components/Menu'
import { MenuItem } from '@szhsin/react-menu'
import { useMutation } from 'urql'

const deactivateUserMutation = `
    mutation DeactivateUser($userId: String!) {
      deactivateUser(userId: $userId) {
        user {
          id
          email
          firstName
          lastName
          isVerified
          role
          deactivatedAt
        }
      }
    }
`
interface RightActionCellProps {
  user: UserWithRole
}

const RightActionCell = ({ user }: RightActionCellProps) => {
  const [_result, executeDeactivate] = useMutation(deactivateUserMutation)
  const onDeactivate = useCallback(() => {
    executeDeactivate({ userId: user.id })
  }, [executeDeactivate])
  return (
    <div className="flex items-center justify-between">
      <p className="text-black/50 text-xs font-semibold leading-4">TODO</p>
      <Menu
        menuButton={
          <BaseButton className="h-7 w-7 bg-[#288bef] rounded-md flex justify-center items-center">
            <ThreeDotsWhite />
          </BaseButton>
        }
      >
        <MenuItem onClick={onDeactivate}>Deactivate</MenuItem>
      </Menu>
    </div>
  )
}

export default RightActionCell
