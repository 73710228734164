import ReactSelect, { mergeStyles, StylesConfig, Props } from 'react-select'
import AsyncRCSelect from 'react-select/async'

export type SelectProps<Option> = Props<Option, false> & { selectRef?: any }

const baseStyles = <Option,>(): StylesConfig<Option> => ({
  control: (provided) => ({
    ...provided,
    minHeight: '50px',
    height: '50px',
    boxShadow: 'none',
    backgroundColor: 'white',
    borderColor: 'rgb(90 97 115 / 0.1)',
    borderRadius: '0.5rem',
    fontSize: '0.875rem',
    color: 'rgb(20 20 20)', // wove-black
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '50px',
    padding: '0 1.125rem',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '50px',
  }),
})

const asyncBaseStyles = <Option,>(): StylesConfig<Option> => ({
  control: (provided, state) => ({
    ...provided,
    background: 'white',
    minHeight: '50px',
    height: '50px',
    boxShadow: 'none',
    borderColor: 'rgb(90 97 115 / 0.1)',
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
    borderBottomLeftRadius: state.menuIsOpen ? '0' : '0.5rem',
    borderBottomRightRadius: state.menuIsOpen ? '0' : '0.5rem',
    fontSize: '0.875rem',
    color: 'rgb(20 20 20)', // wove-black
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '50px',
    padding: '0 1.125rem',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '50px',
  }),
})

const Select = <Option,>({ styles, selectRef, className = '', ...props }: SelectProps<Option>) => {
  return (
    <ReactSelect<Option, false>
      ref={selectRef}
      menuPosition="fixed"
      className={className}
      classNamePrefix="wove-select"
      {...props}
      styles={styles ? mergeStyles(baseStyles<Option>(), styles) : baseStyles<Option>()}
    />
  )
}

export const AsyncSelect = <Option,>({ styles, selectRef, className = '', ...props }: SelectProps<Option>) => {
  return (
    <AsyncRCSelect<Option, false>
      ref={selectRef}
      menuPosition="fixed"
      className={className}
      classNamePrefix="wove-select"
      {...props}
      styles={styles ? mergeStyles(asyncBaseStyles<Option>(), styles) : asyncBaseStyles<Option>()}
    />
  )
}

export default Select
