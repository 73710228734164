import wanHai from './assets/carrierLogos/22AA.svg'
import aplu from './assets/carrierLogos/APLU.svg'
import cmdu from './assets/carrierLogos/CMDU.svg'
import cosu from './assets/carrierLogos/COSU.svg'
import eglv from './assets/carrierLogos/EGLV.svg'
import hdmu from './assets/carrierLogos/HDMU.svg'
import hlcu from './assets/carrierLogos/HLCU.svg'
import kklu from './assets/carrierLogos/KKLU.svg'
import maeu from './assets/carrierLogos/MAEU.svg'
import mscu from './assets/carrierLogos/MSCU.svg'
import oney from './assets/carrierLogos/ONEY.svg'
import oolu from './assets/carrierLogos/OOLU.svg'
import ymlu from './assets/carrierLogos/YMLU.png'
import zimu from './assets/carrierLogos/ZIMU.svg'

const CARRIER_MAP = {
  HCLU: hlcu,
  CMDU: cmdu,
  COSU: cosu,
  HDMU: hdmu,
  MSCU: mscu,
  ONEY: oney,
  OOLU: oolu,
  '22AA': wanHai,
  YMLU: ymlu,
  ZIMU: zimu,
  APLU: aplu,
  CHNJ: cosu,
  EGLV: eglv,
  KKLU: kklu,
  MAEU: maeu,
  UASC: hlcu,
}
const CarrierLogo = ({ code }: { code: string }) => {
  const source = CARRIER_MAP[code as keyof typeof CARRIER_MAP]
  return <img src={source || ''} alt={code} className="max-w-[50px] w-full" />
}

export default CarrierLogo
