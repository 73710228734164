import { graphql } from "../gql";
import { useQuery } from "urql";

const searchRatesQuery = graphql(`
  query SearchRates($input: SearchRatesInput!) {
    searchRates(input: $input) {
      allInRate {
        amount
        currency
      }
      origin {
        code
        name
        countryCode
        region
      }
      portOfLoading {
        code
        name
        countryCode
        region
      }
      portOfDischarge {
        code
        name
        countryCode
        region
      }
      destination {
        code
        name
        countryCode
        region
      }
      carrier {
        code
        name
        abbreviation
      }
      lineItems {
        description
        notes
        commodityType
        containers {
          type
          quantity
          totalPrice {
            amount
            currency
          }
          pricePerContainer {
            amount
            currency
          }
        }
        price {
          amount
          currency
        }
        source {
          contract
          location {
            tab
            row
            preview {
              data
              header
            }
          }
        }
      }
      commodityType
      duplicateRates {
        allInRate {
          amount
          currency
        }
        origin {
          code
          name
          countryCode
          region
        }
        portOfLoading {
          code
          name
          countryCode
          region
        }
        portOfDischarge {
          code
          name
          countryCode
          region
        }
        destination {
          code
          name
          countryCode
          region
        }
        carrier {
          code
          name
          abbreviation
        }
        lineItems {
          description
          notes
          commodityType
          containers {
            type
            quantity
            totalPrice {
              amount
              currency
            }
            pricePerContainer {
              amount
              currency
            }
          }
          price {
            amount
            currency
          }
          source {
            contract
            location {
              tab
              row
              preview {
                data
                header
              }
            }
          }
        }
      }
    }
  }
`);
export const useSearchRatesQuery = (variables: { input: any }) => 
  useQuery({ query: searchRatesQuery, variables });
