import { memo } from 'react'
import BaseModal from '@src/Components/BaseModal'
import { Space } from '@mantine/core'
import PasswordField from '@src/Components/PasswordField'
import ButtonPrimary from '@src/Components/Buttons/ButtonPrimary'

interface ChangePasswordModalProps {
  onClose: () => void
}
const ChangePasswordModal = ({ onClose }: ChangePasswordModalProps) => {
  return (
    <BaseModal alignItems="center" width="708px" onCloseButton={onClose}>
      <div>
        <h3 className="text-black text-xl font-bold">Change your account password</h3>
        <Space h={45} />
        <PasswordField label="Current Password" autoFocus />
        <Space h={20} />
        <PasswordField label="New Password" />
        <Space h={20} />
        <PasswordField label="New Password Confirmation" />
        <Space h={20} />
        <ButtonPrimary $matchWidth>Continue</ButtonPrimary>
        <Space h={36} />
      </div>
    </BaseModal>
  )
}

export default memo(ChangePasswordModal)
