import { TableRowPreview } from '@wove/api/types'
import { useState } from 'react'
import { Space } from '@mantine/core'

const Preview = ({ preview }: { preview: TableRowPreview }) => {
  const [visible, setVisible] = useState(false)
  const { data, header } = preview
  return (
    <div className="flex overflow-x-auto items-start">
      <button 
        className="bg-transparent border-0 border-b border-[#0685F1] text-[#0685F1] flex-shrink-0"
        onClick={() => setVisible((prev) => !prev)}
      >
        {visible ? 'Hide Row Preview' : 'Show Row Preview'}
      </button>
      {visible && (
        <>
          <Space w={82} />
          <table className="border border-black border-collapse table-fixed border-spacing-0">
            {header && (
              <thead>
                {header.map((row, index) => {
                  return (
                    <tr key={index}>
                      {row?.map((h, index) => {
                        return (
                          <th 
                            scope="col" 
                            key={index}
                            className="border border-black border-spacing-0 px-2 py-1 whitespace-nowrap normal-case font-semibold bg-[#c0c0c0] rounded-tl-none rounded-tr-none text-black"
                          >
                            {h}
                          </th>
                        )
                      })}
                    </tr>
                  )
                })}
              </thead>
            )}
            {data && (
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    {row?.map((cell, index) => (
                      <td key={index} className="whitespace-nowrap border border-black border-spacing-0 px-2 py-1">
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </>
      )}
    </div>
  )
}

export default Preview
