import { CombinedError } from '@urql/core'

export function displayFileSize(size: number): string {
  if (size < 1024) {
    return `${size}b`
  }
  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)}kb`
  }
  return `${(size / 1024 / 1024).toFixed(2)}mb`
}
export const getCombinedErrorMessage = (error: CombinedError) => {
  if (error.graphQLErrors.length > 0) {
    return error.graphQLErrors.map((e) => e.message).join(', ')
  }
  if (error.networkError) {
    return error.networkError.message
  }
  return 'An unknown error occurred'
}
