import { Source } from '@wove/api/types'
import SourceRow from '@src/Pages/UploadPage/SourceRow'
interface SourceTableProps {
  sources: Source[]
}

const SourceTable = ({ sources }: SourceTableProps) => {
  return (
    <div className="mt-[18px]">
      <div className="flex text-xs font-bold leading-[18px] uppercase h-[46px] items-stretch text-grey-text">
        <div className="w-[66px] flex-shrink-0" />
        <div className="flex-[2_1_0px] flex items-center cursor-pointer">Name</div>
        <div className="flex-[1_1_0px] flex items-center cursor-pointer">Rates</div>
        <div className="flex-[1_1_0px] flex items-center cursor-pointer">Type</div>
        <div className="flex-[1_1_0px] flex items-center cursor-pointer">Tags</div>
        <div className="flex-[1_1_0px] flex items-center cursor-pointer">Size</div>
        <div className="flex-[1_1_0px] flex items-center cursor-pointer">Uploaded</div>
        {/*<div className="flex-[1_1_0px] flex items-center cursor-pointer">Added By</div>*/}
        <div className="w-12 flex-shrink-0" />
      </div>
      {sources.map((source) => (
        <SourceRow source={source} key={source.id} />
      ))}
    </div>
  )
}

export default SourceTable
