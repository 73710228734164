import { Role } from '@wove/common/roles'
import { ContainerType } from '@wove/api/types'

// export enum ContainerTypes {
//   C_20_FT = 'c_20ft',
//   C_40_FT = 'c_40ft',
//   C_40_FT_HC = 'c_40ft_hc',
//   C_45_FT = 'c_45ft',
//   C_20FT_REEFER = 'c_20ft_reefer',
//   C_40FT_REEFER = 'c_40ft_reefer',
//   C_40FT_REEFER_HC = 'c_40ft_reefer_hc',
//   C_45FT_REEFER = 'c_45ft_reefer',
// }

// export enum ReeferContainerTypes {
//   C_20FT_REEFER = 'c_20ft_reefer',
//   C_40FT_REEFER = 'c_40ft_reefer',
//   C_40FT_REEFER_HC = 'c_40ft_reefer_hc',
//   C_45FT_REEFER = 'c_45ft_reefer',
// }

// export const ContainerTypeMap: Record<ContainerTypes, ReeferContainerTypes> = {
//   [ContainerTypes.C_20_FT]: ReeferContainerTypes.C_20FT_REEFER,
//   [ContainerTypes.C_40_FT]: ReeferContainerTypes.C_40FT_REEFER,
//   [ContainerTypes.C_40_FT_HC]: ReeferContainerTypes.C_40FT_REEFER_HC,
//   [ContainerTypes.C_45_FT]: ReeferContainerTypes.C_45FT_REEFER,
// }

export const CONTAINER_TITLES = {
  [ContainerType.C_20ft]: '20 foot general purpose',
  [ContainerType.C_40ft]: '40 foot general purpose',
  [ContainerType.C_40ftHc]: '40 foot high cube',
  [ContainerType.C_45ft]: '45 foot general purpose',
  [ContainerType.C_20ftReefer]: '20 foot reefer',
  [ContainerType.C_40ftReefer]: '40 foot reefer',
  [ContainerType.C_40ftHcReefer]: '40 foot high cube reefer',
  [ContainerType.C_45ftReefer]: '45 foot reefer',
}
// export const REEFER_CONTAINER_TITLES = {
//   [ContainerTypes.C_20_FT]: '20 foot reefer',
//   [ContainerTypes.C_40_FT]: '40 foot reefer',
//   [ContainerTypes.C_40_FT_HC]: '40 foot high cube reefer',
//   [ContainerTypes.C_45_FT]: '45 foot reefer',
// }

export const LOCAL_STORAGE_KEYS = {
  INTAKE_FORM: 'INTAKE_FORM',
}

export const RoleNames = {
  [Role.GENERAL]: 'General',
  [Role.CORE]: 'Core',
  [Role.ADMIN]: 'Admin',
}

export const ROLE_OPTIONS = [
  {
    value: Role.GENERAL,
    label: RoleNames[Role.GENERAL],
  },
  {
    value: Role.CORE,
    label: RoleNames[Role.CORE],
  },
  {
    value: Role.ADMIN,
    label: RoleNames[Role.ADMIN],
  },
]
