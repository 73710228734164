import { SourceUploadProgress } from '@wove/api/types'
import { useState, useRef } from 'react'
export default function useUploadPolling() {
  const [sourceStatuses, setSourceStatuses] = useState<
    Record<
      string,
      {
        progress: SourceUploadProgress
        fileName: string
      }
    >
  >({})
  const [completedSources, setCompletedSources] = useState<Record<string, true>>({})
  const pollTimeouts = useRef<Record<string, ReturnType<typeof setTimeout>>>({})

  const removeSourceStatus = (sourceId: string) => {
    setSourceStatuses((prev) => {
      const { [sourceId]: _, ...rest } = prev
      return rest
    })
  }

  const setSourceStatus = (
    sourceId: string,
    status: { progress: SourceUploadProgress; fileName: string },
  ) => {
    setSourceStatuses((prev) => {
      return {
        ...prev,
        [sourceId]: status,
      }
    })
  }

  const addPollingTimeout = (sourceId: string, timeout: ReturnType<typeof setTimeout>) => {
    pollTimeouts.current[sourceId] = timeout
  }

  const removePollingTimeout = (sourceId: string) => {
    if (pollTimeouts.current[sourceId]) {
      clearTimeout(pollTimeouts.current[sourceId])
      delete pollTimeouts.current[sourceId]
    }
  }

  const addCompletedSource = (sourceId: string) => {
    setCompletedSources((prev) => {
      return {
        ...prev,
        [sourceId]: true,
      }
    })
  }

  return {
    sourceStatuses,
    pollTimeouts,
    removeSourceStatus,
    addPollingTimeout,
    removePollingTimeout,
    setSourceStatus,
    completedSources,
    addCompletedSource,
  }
}
