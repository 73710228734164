import React from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import calendarIconBlue from './assets/calendar-blue.svg'
import calendarIconGrey from './assets/calendar-grey.svg'
import chevron from './assets/chevron.svg'

const DatePicker = React.forwardRef<ReactDatePicker, ReactDatePickerProps>(
  ({ selected, ...props }, ref) => {
    return (
      <div className="w-full relative [&>.react-datepicker-wrapper]:block">
        <ReactDatePicker
          {...props}
          ref={ref}
          // To prevent current day showing from every month
          disabledKeyboardNavigation
          selected={selected}
          className={`min-h-[50px] w-full px-4 text-left border border-[rgba(90,97,115,0.1)] rounded-lg bg-white bg-no-repeat bg-[14px_center] ${selected ? `bg-[url('${calendarIconBlue}')]` : `bg-[url('${calendarIconGrey}')]`}`}
          formatWeekDay={(name: string) => name.substr(0, 3)}
        />
        <img src={chevron} alt="grey down pointing chevron" className="absolute top-1/2 right-3.5 -translate-y-1/2 pointer-events-none" />
      </div>
    )
  },
)

export default DatePicker
