interface SpinnerProps {
  size?: string
  className?: string
}

const Spinner = ({ size = '1.5em', className = '' }: SpinnerProps) => {
  return (
    <div 
      className={`
        inline-block border-2 border-white border-l-transparent
        rounded-full leading-none animate-spin
        ${className}
      `}
      style={{ 
        width: size, 
        height: size,
        animationDirection: 'reverse' // To match the original animation
      }}
    />
  )
}

export default Spinner
