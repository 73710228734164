import { SourceUploadProgress } from '@wove/api/types'
import spinner from '../assets/spinner.svg'
import chevronDown from '@src/assets/images/chevron-down.svg'
import { useState, memo } from 'react'
import pickBy from 'lodash/pickBy'
import UploadItem from '@src/Pages/UploadPage/UploadStatusOverlay/UploadItem'

interface UploadStatusOverlayProps {
  sourceStatuses: Record<string, { fileName: string; progress: SourceUploadProgress }>
  onOpenSource: (sourceId: string) => void
  completedSources: Record<string, true>
}
const UploadStatusOverlay = ({
  sourceStatuses: unfilteredSourceStatuses,
  onOpenSource,
  completedSources,
}: UploadStatusOverlayProps) => {
  const sourceStatuses = pickBy(unfilteredSourceStatuses, (_source, key) => !completedSources[key])
  const length = Object.keys(sourceStatuses).length
  const [expanded, setExpanded] = useState(true)
  if (length === 0) {
    return null
  }
  return (
    <div className="absolute bottom-0 right-0 shadow-lg rounded-t-lg w-[320px]">
      <div className="flex items-center text-[#121212] text-xs font-semibold p-3 px-5 rounded-t-lg bg-background-light gap-2">
        <img src={spinner} className="animate-spin-slow" />
        Uploading {length} file{length === 1 ? '' : 's'}...
        <div className="flex items-center gap-2 flex-1 justify-end">
          <button onClick={() => setExpanded((prev) => !prev)} className="bg-none border-none p-2">
            <img
              src={chevronDown}
              alt={expanded ? 'grey chevron down' : 'grey chevron up'}
              className={`transform ${expanded ? 'rotate-180' : 'rotate-0'} transition-transform`}
            />
          </button>
        </div>
      </div>
      {expanded &&
        Object.keys(sourceStatuses).map((sourceId) => {
          return (
            <UploadItem
              item={sourceStatuses[sourceId]}
              key={sourceId}
              onOpen={() => onOpenSource(sourceId)}
            />
          )
        })}
    </div>
  )
}

export default memo(UploadStatusOverlay)
