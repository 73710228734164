import { addMinutes, parseISO } from 'date-fns'

export function getTimezoneIgnoredDate(date: string) {
  const timezoneOffset = new Date().getTimezoneOffset()

  // Parse ISO string and adjust for timezone
  const adjustedDate = addMinutes(parseISO(date), timezoneOffset)

  return adjustedDate
}

export function stripTimezoneFromDate(date: Date | null) {
  if (!date) {
    return date
  }

  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()

  const localMidnight = new Date(year, month, day, 12, 0, 0)

  const timezoneOffset = localMidnight.getTimezoneOffset() * 60000

  const utcDate = new Date(localMidnight.getTime() + timezoneOffset)

  return utcDate
}
